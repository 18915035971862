import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../firebase/firebase'

const useIsAdmin = (): boolean => {
  const [isAdmin, setIsAdmin] = useState(false)

  const updateIsAdmin = async (user: User | null): Promise<void> => {
    const token = await user?.getIdTokenResult()
    if (token != null) {
      if (typeof (token.claims.admin) === 'boolean') {
        setIsAdmin(token.claims.admin)
      }
    } else {
      setIsAdmin(false)
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      updateIsAdmin(user).catch(e => alert(e))
    })
  }, [])

  return isAdmin
}

export default useIsAdmin

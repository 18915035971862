import React from 'react'
import Base from '.'
import { VideoCameraOutlined } from '@ant-design/icons'
import { useDocument } from 'react-firebase-hooks/firestore'
import collections from '../../../lib/collections'
import { doc } from 'firebase/firestore'

const SessionsWidget: React.FC = () => {
  const today = new Date()
  const todayDateString = today.toISOString().split('T')[0]
  const [todayDoc, ,] = useDocument(doc(collections.statistics, 'sessions', 'count', todayDateString))
  const todayCount = todayDoc?.data()

  const loading = todayCount?.total == null

  return (
    <Base label='Sessions' value={todayCount} loading={loading} icon={<VideoCameraOutlined style={{ fontSize: 30 }} />} />
  )
}

export default SessionsWidget

import React, { useState } from 'react'
import AssistantsList from '../components/ai/AssistantsList'
import { VStack } from 'react-stacked'
import ThreadsList from '../components/ai/ThreadsList'
import OpenAI from 'openai'

const AiDashboard: React.FC = () => {
  const [assistants, setAssistants] = useState<OpenAI.Beta.Assistant[]>()

  return (
    <VStack grow={1} gap={10}>
      <AssistantsList onAssistants={setAssistants} />
      <ThreadsList assistants={assistants} />
    </VStack>
  )
}

export default AiDashboard

import React, { useEffect } from 'react'
import styled from 'styled-components'

const Input = styled.div`
  padding: 5px;
  width: 100%;
  text-align: center;
  cursor: text;
`

export interface HiddenInputProps {
  value: string
  placeholder: string
  onChange: (value: string) => void
}

const HiddenInput: React.FC<HiddenInputProps> = ({ value, onChange, placeholder }) => {
  const inputRef = React.useRef<HTMLDivElement>(null)
  const [selected, setSelected] = React.useState(false)

  const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>): void => {
    if (e.currentTarget.innerText === placeholder) e.currentTarget.innerText = ''
    setSelected(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>): void => {
    const newValue = e.currentTarget.textContent
    if (newValue === '' || newValue == null) {
      e.currentTarget.innerText = placeholder
    }
    onChange(newValue ?? '')
    setSelected(false)
  }

  useEffect(() => {
    if (inputRef.current != null) {
      if (value !== '') inputRef.current.innerText = value
      if (value === '') inputRef.current.innerText = placeholder
    }
  }, [value, placeholder])

  useEffect(() => {
    if (selected) {
      // select the input text content
      inputRef.current?.focus()
      if (inputRef.current == null) return
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(inputRef.current)
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }, [selected])

  return (
    <Input
      ref={inputRef}
      contentEditable
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={{
        color: !selected && value === '' ? 'grey' : 'black'
      }}
    />
  )
}

export default HiddenInput

import React, { useMemo } from 'react'
import Card from '../../layout/Card'
import Title from '../Title'
import { Timestamp } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import collections from '../../lib/collections'
import { ClipLoader } from 'react-spinners'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import Button from '../Button'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import { firebaseApp } from '../../firebase/firebase'
import { HStack, VStack } from 'react-stacked'
import { useNavigate } from 'react-router-dom'
import OpenAI from 'openai'
import Select from 'react-select'
import { Thread } from '../../admin-types'
import Avatar from '../Avatar'

const functions = getFunctions(firebaseApp)

interface ThreadsListProps {
  assistants?: OpenAI.Beta.Assistant[]
}

const ThreadsList: React.FC<ThreadsListProps> = ({ assistants }) => {
  const [data, loading, error] = useCollectionData(collections.threads)
  const [assistantID, setAssistantID] = React.useState<string>()
  const navigate = useNavigate()
  const [loadingDelete, setLoadingDelete] = React.useState<string | null>()
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc'
    }
  ])

  const createThread = async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    await httpsCallable(functions, 'createThread')({ assistantID })
  }

  const deleteThread = async (threadID: string): Promise<void> => {
    setLoadingDelete(threadID)
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    await httpsCallable(functions, 'deleteThread')({ threadID })
    setLoadingDelete(null)
  }

  const options: Array<{ value: string, label: string }> = useMemo(() => {
    return assistants?.filter(a => a.name != null).map((assistant) => ({ label: assistant.name ?? '', value: assistant.id })) ?? []
  }, [assistants])

  return (
    <Card style={{ flex: 1 }}>
      <VStack gap={10} grow={1}>
        <Title>Threads</Title>
        <HStack gap={10}>
          <Select
            placeholder='Select assistant'
            value={options.find((option) => option.value === assistantID)}
            styles={{
              control: (provided) => ({
                ...provided,
                minWidth: 200
              })
            }}
            options={options}
            onChange={(c) => setAssistantID(c?.value ?? '')}
          />
          <Button
            disabled={assistantID == null}
            onClick={() => {
              createThread().catch((error) => {
                console.error(error)
                alert(error.message)
              })
            }}
          >
            Create thread
          </Button>
        </HStack>
        {loading && <ClipLoader />}
        {error != null && <p>{error.message}</p>}
        <DataGrid
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          loading={loading}
          onRowClick={params => {
            const thread = params.row as Thread
            navigate(`/ai-thread/${thread.threadID}`)
          }}
          columns={[
            { field: 'assistantID', headerName: 'Assistant', flex: 1, valueFormatter: ({ value }) => assistants?.find((assistant) => assistant.id === value)?.name ?? value },
            {
              field: 'createdBy',
              headerName: 'Created by',
              width: 90,
              renderCell: (params) => {
                return (
                  <Avatar userID={params.value} />
                )
              }
            },
            { field: 'threadID', headerName: 'Thread ID', flex: 1 },
            { field: 'createdAt', headerName: 'Created at', flex: 1, valueFormatter: ({ value }) => (value as Timestamp).toDate().toLocaleString() },
            {
              field: 'delete',
              headerName: 'Delete',
              width: 110,
              renderCell: ({ row }) => {
                const thread = row as Thread
                return (
                  <Button
                    loading={loadingDelete === thread.threadID}
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteThread(thread.threadID).catch((error) => {
                        console.error(error)
                        alert(error.message)
                        setLoadingDelete(null)
                      })
                    }}
                  >
                    Delete
                  </Button>
                )
              }
            }
          ]}
          rows={data?.map((thread) => ({ ...thread, id: thread.threadID })) ?? []}
        />
      </VStack>
    </Card>
  )
}

export default ThreadsList

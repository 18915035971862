import { useCollectionData } from 'react-firebase-hooks/firestore'
import Card from '../layout/Card'
import collections from '../lib/collections'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import { ClipLoader } from 'react-spinners'
import { useMemo, useState } from 'react'
import Title from '../components/Title'
import Button from '../components/Button'
import Avatar from '../components/Avatar'
import Category from '../components/Category'
import { Session } from '../admin-types'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import ReviewCounter from '../components/reviews/Counter'
import { useNavigate } from 'react-router-dom'
import { query, where } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase/firebase'

const SessionsTemplates: React.FC<{ myTemplates?: boolean }> = ({ myTemplates = false }) => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  const templateRef = useMemo(() => {
    if (myTemplates) {
      return query(collections.sessionTemplates, where('authoredBy', '==', user?.uid ?? ''))
    } else {
      return collections.sessionTemplates
    }
  }, [myTemplates, user?.uid])
  const [sessionTemplates, loadingSessionTemplates, errorSessionTemplates] = useCollectionData(templateRef)
  const [users, loadingUsers, errorUsers] = useCollectionData(collections.users)
  const [categories, loadingCategories, errorCategories] = useCollectionData(collections.categories)
  const [sortModel, setSortModel] = useState<GridSortModel>([{
    field: 'createdAt',
    sort: 'desc'
  }])

  const columns: GridColDef[] = useMemo(() => [
    { field: 'title', headerName: 'Title', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created',
      valueGetter: (params) => {
        const date = new Date(params.value.seconds * 1000)
        return date.toISOString()
      },
      renderCell: (params: any) => {
        const date = new Date(params.value)
        return date.toLocaleDateString()
      }
    },
    {
      field: 'reviews',
      headerName: 'Reviews',
      width: 72,
      renderCell: (params) => {
        const session = params.row as Session
        return (
          <ReviewCounter templateID={session.id} />
        )
      }
    },
    {
      field: 'authoredBy',
      headerName: 'Author',
      width: 90,
      renderCell: (params) => {
        const user = users?.find((user) => user.id === params.value)
        if (user == null) return <></>
        return (
          <Avatar user={user} />
        )
      }
    },
    {
      field: 'categoryID',
      headerName: 'Quality',
      width: 180,
      renderCell: (params) => {
        const category = categories?.find((category) => category.id === params.value)
        if (category == null) return <></>
        return (
          <Category category={category} />
        )
      }
    },
    {
      field: 'visible',
      headerName: 'Visibility',
      width: 70,
      renderCell: (params) => {
        const visibility = params.value
        if (visibility === true) return <EyeOutlined />
        return <EyeInvisibleOutlined />
      }
    },
    {
      field: '',
      headerName: 'Edit',
      width: 100,
      renderCell: ({ row }: { row: Session }) => {
        return (
          <Button onClick={(e) => {
            e.stopPropagation()
            navigate(`/session-templates/${row.id}`)
          }}
          >Edit
          </Button>
        )
      }
    }
  ], [categories, users, navigate])

  if (loadingSessionTemplates || loadingUsers || loadingCategories) {
    return <Card center><ClipLoader /></Card>
  }

  if (errorUsers != null || users == null || errorSessionTemplates != null || sessionTemplates == null || errorCategories != null || categories == null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <Card style={{ gap: 10 }}>
      <Title>Templates</Title>
      <Button onClick={() => navigate('/session-templates/new')}>Create new template</Button>
      <DataGrid
        autoHeight
        rows={sessionTemplates}
        columns={columns}
        sortingOrder={['desc', 'asc']}
        onSortModelChange={setSortModel}
        sortModel={sortModel}
      />
    </Card>
  )
}

export default SessionsTemplates

import React from 'react'

interface LogoProps {
  width?: number
  height?: number
  style?: React.CSSProperties
}

const Logo: React.FC<LogoProps> = ({ style, width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      style={{
        ...style,
        width: width ?? '',
        height: height ?? '',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2
      }}
      viewBox='0 0 461 461'
    >
      <path
        d='M0 0h460.8v460.8H0z'
        style={{
          fill: 'none'
        }}
      />
      <path
        d='M0 0h460.8v460.8H0z'
        style={{
          fill: '#fff'
        }}
      />
      <path
        d='M315.533 97.019c0-25.961-21.077-47.038-47.039-47.038H152.265c-25.961 0-47.038 21.077-47.038 47.038v204.107c0 25.962 21.077 47.039 47.038 47.039h116.229c25.962 0 47.039-21.077 47.039-47.039V97.019Z'
        style={{
          fill: '#fff',
          fillOpacity: 0.31
        }}
      />
      <path d='M319.283 97.019v204.107c0 28.031-22.758 50.789-50.789 50.789H152.265c-28.031 0-50.788-22.758-50.788-50.789V97.019c0-28.031 22.757-50.788 50.788-50.788h116.229c28.031 0 50.789 22.757 50.789 50.788Zm-7.5 0c0-23.891-19.397-43.288-43.289-43.288H152.265c-23.891 0-43.288 19.397-43.288 43.288v204.107c0 23.892 19.397 43.289 43.288 43.289h116.229c23.892 0 43.289-19.397 43.289-43.289V97.019Z' />
      <path
        d='M339.419 121.26c0-25.961-21.077-47.039-47.039-47.039H176.151c-25.961 0-47.039 21.078-47.039 47.039v204.107c0 25.961 21.078 47.038 47.039 47.038H292.38c25.962 0 47.039-21.077 47.039-47.038V121.26Z'
        style={{
          fill: '#fff',
          fillOpacity: 0.31
        }}
      />
      <path d='M343.169 121.26v204.107c0 28.031-22.758 50.788-50.789 50.788H176.151c-28.031 0-50.789-22.757-50.789-50.788V121.26c0-28.031 22.758-50.789 50.789-50.789H292.38c28.031 0 50.789 22.758 50.789 50.789Zm-7.5 0c0-23.892-19.397-43.289-43.289-43.289H176.151c-23.892 0-43.289 19.397-43.289 43.289v204.107c0 23.892 19.397 43.288 43.289 43.288H292.38c23.892 0 43.289-19.396 43.289-43.288V121.26Z' />
      <path
        d='M363.672 145.513c0-25.961-21.077-47.038-47.038-47.038h-116.23c-25.961 0-47.038 21.077-47.038 47.038V349.62c0 25.962 21.077 47.039 47.038 47.039h116.23c25.961 0 47.038-21.077 47.038-47.039V145.513Z'
        style={{
          fill: '#fff',
          fillOpacity: 0.31
        }}
      />
      <path d='M367.422 145.513V349.62c0 28.031-22.757 50.789-50.788 50.789h-116.23c-28.031 0-50.788-22.758-50.788-50.789V145.513c0-28.031 22.757-50.788 50.788-50.788h116.23c28.031 0 50.788 22.757 50.788 50.788Zm-7.5 0c0-23.891-19.397-43.288-43.288-43.288h-116.23c-23.891 0-43.288 19.397-43.288 43.288V349.62c0 23.892 19.397 43.289 43.288 43.289h116.23c23.891 0 43.288-19.397 43.288-43.289V145.513Z' />
    </svg>
  )
}

export default Logo

import React from 'react'
import { useCount } from '../../firebase/firebase'
import collections from '../../lib/collections'
import { collection, doc } from 'firebase/firestore'
import { ClipLoader } from 'react-spinners'

export interface CounterProps {
  templateID: string
}

const ReviewCounter: React.FC<CounterProps> = ({ templateID }) => {
  const ref = collection(doc(collections.sessionTemplates, templateID), 'reviews')
  const [count, loading] = useCount(ref)

  return (
    <div>
      {count}
      {loading && <ClipLoader />}
    </div>
  )
}

export default ReviewCounter

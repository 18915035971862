import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../firebase/firebase'
import { getStorage, ref, listAll, ListResult } from 'firebase/storage'

const storage = getStorage()

const useDirectory = (directory: string): [ListResult | undefined, any, boolean] => {
  const [files, setFiles] = useState<ListResult>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any | null>(null)

  useEffect(() => {
    const updateFiles = async (user: User | null): Promise<void> => {
      listAll(ref(storage, directory))
        .then((res) => {
          setFiles(res)
        }).catch((error) => {
          // alert('Error loading videos')
          setError(error)
          console.warn(error)
        })
    }

    auth.onAuthStateChanged((user) => {
      setLoading(true)
      updateFiles(user).finally(() => setLoading(false))
    })
  }, [directory])

  return [files, error, loading]
}

export default useDirectory

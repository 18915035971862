import React from 'react'
import Label from './Label'
import Select from 'react-select'
import { StackProps, VStack } from 'react-stacked'

export interface BooleanInputProps extends StackProps {
  value: boolean
  label: string
  onValue: (value: boolean) => void
  labels: [string, string]
}

const BooleanInput: React.FC<BooleanInputProps> = (props) => {
  const { value, label, onValue, labels } = props
  return (
    <VStack gap={10} {...props}>
      <Label>{label}</Label>
      <Select
        value={{ value, label: value ? labels[0] : labels[1] }}
        onChange={(option) => {
          if (option == null) {
            return
          }
          onValue(option.value)
        }}
        options={[
          { value: true, label: labels[0] },
          { value: false, label: labels[1] }
        ]}
      />
    </VStack>
  )
}

export default BooleanInput

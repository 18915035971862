import React from 'react'
import styled from 'styled-components'
import { Review as ReviewType, Session } from '../../admin-types'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import collections from '../../lib/collections'
import { collection, query, orderBy } from 'firebase/firestore'
import Review from './Review'
import { ClipLoader } from 'react-spinners'
import Title from '../Title'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const List = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`

export interface ReviewsProps {
  session: Session
}

const Reviews: React.FC<ReviewsProps> = ({ session }) => {
  const ref = collection(collections.sessionTemplates, session.id, 'reviews')
  const [reviews, loading] = useCollectionData(query(ref, orderBy('createdAt', 'desc'))) as [ReviewType[] | undefined, boolean, Error | undefined, any]

  return (
    <Column>
      <Title>Reviews</Title>
      {loading && <ClipLoader />}

      {reviews?.length === 0 && <p>No reviews yet...</p>}

      <List>
        {reviews?.map(r => {
          return (
            <Review key={r.authorID} review={r} />
          )
        })}
      </List>
    </Column>
  )
}

export default Reviews

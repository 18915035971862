import React, { useEffect, useState } from 'react'
import { CourseTemplate as CourseTemplateType, CreateInput } from '../admin-types'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, useCount } from '../firebase/firebase'
import Card from '../layout/Card'
import { deleteDoc, doc, query, setDoc, where } from 'firebase/firestore'
import collections, { courseSessionTemplates } from '../lib/collections'
import { removeUndefinedKeys } from '../utils/removeUndefinedKeys'
import { createEmptyCourseTemplate } from '../lib/courses'
import EditCourse from '../components/EditCourse'
import { SubTitle } from '../components/Title'
import CourseSessionList from '../components/CourseSessionList'
import styled from 'styled-components'
import Button from '../components/Button'
import useIsAdmin from '../hooks/useIsAdmin'
import { useDocumentData } from 'react-firebase-hooks/firestore'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`

type LocalCourse = CourseTemplateType | CreateInput<CourseTemplateType>

const CourseTemplate: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const currentUser = auth.currentUser
  const userID = currentUser?.uid ?? ''

  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [liveCourse] = useDocumentData(doc(collections.courseTemplates, id ?? ''))
  const [currentCourse, setCurrentCourse] = useState<LocalCourse>(createEmptyCourseTemplate(userID))
  const [editingExisting, setEditingExisting] = useState(false)
  const isAdmin = useIsAdmin()
  const q = isAdmin ? courseSessionTemplates(id ?? '') : query(courseSessionTemplates(id ?? ''), where('authoredBy', '==', userID))
  const [templateCount, loadingCount] = useCount(q)

  useEffect(() => {
    if (id == null) return
    if (id === 'new') return
    if (liveCourse == null) return
    setCurrentCourse(liveCourse)
    setEditingExisting(true)
  }, [id, liveCourse])

  const saveCourse = (course: LocalCourse): void => {
    setLoadingSave(true)
    if (editingExisting) {
      course.id = currentCourse.id
    }
    setDoc(doc(collections.courseTemplates, course.id), removeUndefinedKeys(course)).catch((error) => {
      console.error('Error adding document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingSave(false)
      navigate(`/course-templates/${course.id}`)
    })
  }

  const deleteCourse = (course: LocalCourse): void => {
    if (templateCount !== 0 || loadingCount) {
      alert('Please delete all templates before deleting this course.')
      return
    }
    setLoadingDelete(true)

    deleteDoc(doc(collections.courseTemplates, course.id)).then(() => {
      navigate('/course-templates')
    }).catch((error) => {
      console.error('Error deleting document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingDelete(false)
    })
  }

  const exists = (id != null && id !== 'new')

  return (
    <>
      <Card>
        <EditCourse
          course={currentCourse}
          onCourse={
          (edits) => setCurrentCourse({ ...currentCourse, ...edits })
        }
          onSave={
          () => saveCourse(currentCourse)
        }
          loadingSave={loadingSave}
          loadingDelete={loadingDelete}
          onDelete={() => deleteCourse(currentCourse)}
          onCancel={() => {
            navigate('/course-templates')
          }}
          editingExisting={editingExisting}
        />
      </Card>
      <Card style={{ gap: 10 }}>
        <SubTitle>Course content</SubTitle>
        {!exists && <div>Please save the course before adding content.</div>}
        {exists && <CourseSessionList courseID={id} />}
        <Row>
          {exists && <Button onClick={() => navigate('template/new')}>Add template</Button>}
        </Row>
      </Card>
    </>
  )
}

export default CourseTemplate

import React from 'react'
import styled from 'styled-components'
import Label from './Label'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StyledInput = styled.input`
  padding: 0 10px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
`

export interface InputProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
  type?: React.HTMLInputTypeAttribute
  label?: string
}

const Input: React.FC<InputProps> = ({ value, placeholder, onChange, style, inputStyle, type, label }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value)
  }

  return (
    <Column style={style}>
      {label != null && <Label>{label}</Label>}
      <StyledInput onKeyDown={(event) => event.stopPropagation()} onFocus={e => e.stopPropagation()} type={type} style={inputStyle} value={value} placeholder={placeholder} onChange={handleChange} />
    </Column>
  )
}

export default Input

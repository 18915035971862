import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import OpenAI from 'openai'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import Card from '../layout/Card'
import Title from '../components/Title'
import { ClipLoader } from 'react-spinners'
import TextArea from '../components/TextArea'
import { HStack, VStack } from 'react-stacked'
import Button from '../components/Button'
import Input from '../components/Input'
import Label from '../components/Label'
import FilesSelector from '../components/ai/FilesSelector'
import ModelSelector from '../components/ai/ModelSelector'
import ToolsSelector from '../components/ai/ToolsSelector'
import { copyCardTypes, copyCategories } from '../lib/ai-clipboard-data'

const functions = getFunctions()

const AiAssistant: React.FC = () => {
  const { assistantID } = useParams()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loadingUpdate, setLoadingUpdate] = React.useState<boolean>(false)
  const [assistant, setAssistant] = React.useState<OpenAI.Beta.Assistant>()

  // User changable
  const [name, setName] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [instructions, setInstructions] = React.useState<string>('')
  const [files, setFiles] = React.useState<string[]>([])
  const [tools, setTools] = React.useState<OpenAI.Beta.Assistant['tools']>([])
  const [model, setModel] = React.useState<string>('')

  const getAssistant = useCallback(async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const res = await httpsCallable(functions, 'getAssistant')({ assistantID })
    const data = res.data as OpenAI.Beta.Assistant
    setAssistant(data)
    setName(data.name ?? '')
    setDescription(data.description ?? '')
    setInstructions(data.instructions ?? '')
    setFiles(data.file_ids ?? [])
    setTools(data.tools)
    setModel(data.model)
    setLoading(false)
  }, [assistantID])

  useEffect(() => {
    getAssistant().catch((error) => {
      console.error(error)
      alert(error.message)
      setLoading(false)
    })
  }, [getAssistant])

  const updateAssistant = async (): Promise<void> => {
    setLoadingUpdate(true)
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    await httpsCallable(functions, 'updateAssistant')({
      assistantID,
      name,
      description,
      instructions,
      file_ids: files,
      model,
      tools
    })
    await getAssistant()
    setLoadingUpdate(false)
  }

  if (loading) return <Card style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ClipLoader /></Card>

  if (assistant == null) return <Card style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>Assistant not found</Card>

  const retrievalEnabled = assistant.tools.map(t => t.type).includes('retrieval')

  return (
    <Card style={{ flex: 1 }}>
      <VStack gap={10}>
        <Title>{assistant.name}</Title>
        <p>Created {(new Date(assistant.created_at * 1000)).toDateString()}</p>
        <p>Model: {assistant.model}</p>
        <Label>Model</Label>
        <ModelSelector
          value={model} onChange={setModel}
        />

        <Input label='Name' value={name} onChange={setName} />
        <Input label='Description' value={description} onChange={setDescription} />
        <TextArea label='Instructions' value={instructions} onChange={setInstructions} inputStyle={{ minHeight: 400 }} />

        <Label>Tools</Label>
        <ToolsSelector value={tools} onChange={setTools} />

        <Label>Files</Label>
        <FilesSelector value={files} onChange={setFiles} disabled={!retrievalEnabled} />

        <HStack gap={10}>
          <Button
            onClick={() => {
              copyCategories().catch((error) => {
                console.error(error)
                alert(error.message)
              })
            }}
          >
            Copy categories to clipboard
          </Button>
          <Button
            onClick={() => {
              copyCardTypes().catch((error) => {
                console.error(error)
                alert(error.message)
              })
            }}
          >
            Copy card types to clipboard
          </Button>
          <Button
            onClick={() => {
              updateAssistant().catch((error) => {
                console.error(error)
                alert(error.message)
                setLoadingUpdate(false)
              })
            }}
            loading={loadingUpdate}
          >
            Update assistant
          </Button>
        </HStack>
      </VStack>
    </Card>
  )
}

export default AiAssistant

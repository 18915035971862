import { ReactNode, CSSProperties, FC } from 'react'

import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 10px;
  color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.onBackgroundMuted};
`

const Label: FC<{ children?: ReactNode, style?: CSSProperties }> = ({ children, style }) => {
  const colorScheme = useColorScheme()
  return (
    <StyledLabel colorScheme={colorScheme} style={style}>
      {children}
    </StyledLabel>
  )
}

export default Label

import React from 'react'
import { Session } from '../../admin-types'
import { EventFormat } from 'together-types'
import ContextMenu from '../context-menu'
import Menu from '../context-menu/Menu'
import { DeleteFilled, LinkOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import TimeInput from './TimeInput'
import CardBottomButtons, { CardBottomButton } from './CardBottomButtons'

export interface CardFooterTextProps {
  event: Session['agenda'][0]
  onEvent: (event: Partial<Session['agenda'][0]>) => void
  onDelete: () => void
  linkToNextAvailable?: boolean
}

const CardFooterText: React.FC<CardFooterTextProps> = ({ event, onDelete, linkToNextAvailable = true, onEvent }) => {
  const formats = [
    {
      value: 'one_at_the_time',
      label: 'One at the time',
      icon: <UserOutlined style={{ color: '#999' }} />
    },
    {
      value: 'all_at_once',
      label: 'All at once',
      icon: <UsergroupAddOutlined style={{ color: '#999' }} />
    }
  ]

  const currentFormat = formats.find((format) => format.value === event.format)

  return (
    <CardBottomButtons>
      <CardBottomButton onClick={onDelete}>
        <DeleteFilled style={{ color: '#999' }} />
      </CardBottomButton>
      <ContextMenu menu={
        <Menu
          value={currentFormat?.label}
          options={formats.map(f => f.label)}
          onOption={(o) => {
            const newVal = formats.find(f => f.label === o)
            if (newVal != null) {
              onEvent({ format: newVal.value as EventFormat })
            }
          }}
        />
    }
      >
        <CardBottomButton>
          {currentFormat?.icon}
        </CardBottomButton>
      </ContextMenu>
      <CardBottomButton onClick={() => {
        if (linkToNextAvailable) {
          onEvent({ linkedToNext: event.linkedToNext !== true })
        } else {
          window.alert('You can only link to the next event if this is not the last event in the agenda')
        }
      }}
      >
        <LinkOutlined style={{ color: event.linkedToNext === true ? '#1890ff' : '#999' }} />
      </CardBottomButton>
      <CardBottomButton>
        <TimeInput value={event.durationSeconds} onChange={((e) => onEvent({ durationSeconds: e }))} />
      </CardBottomButton>
    </CardBottomButtons>
  )
}

export default CardFooterText

import React from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'

const StyledTitle = styled.h1`
  margin: 0;
  margin-bottom: 10px;
  color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.onBackground};
`

export interface TitleProps {
  children?: React.ReactNode
  style?: React.CSSProperties
}

const Title: React.FC<TitleProps> = ({ children, style }) => {
  const colorScheme = useColorScheme()
  return (
    <StyledTitle colorScheme={colorScheme}>
      {children}
    </StyledTitle>
  )
}

const StyledSubtitle = styled.h2`
  margin: 0;
  margin-bottom: 10px;
  color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.onBackground};
`

export const SubTitle: React.FC<TitleProps> = ({ children, style }) => {
  const colorScheme = useColorScheme()
  return (
    <StyledSubtitle colorScheme={colorScheme} style={style}>
      {children}
    </StyledSubtitle>
  )
}

export default Title

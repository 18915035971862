import { doc, DocumentSnapshot, getDoc, getDocs } from 'firebase/firestore'
import React from 'react'
import Button from '../components/Button'
import Input from '../components/Input'
import { firebaseApp } from '../firebase/firebase'
import Card from '../layout/Card'
import collections from '../lib/collections'
import { getFunctions, httpsCallable } from 'firebase/functions'
import styled from 'styled-components'
import NotificationPreview from '../components/NotificationPreview'
import Title from '../components/Title'
import { RadioInput } from '../components/OptionInput'
import { useCollection } from 'react-firebase-hooks/firestore'
import UserSelect from '../components/UserSelect'
import { UserRecord } from '../admin-types'

const functions = getFunctions(firebaseApp)
functions.region = 'europe-west1'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

interface Option {
  value: string
  label: string
  alert: string
}

const options: Option[] = [
  {
    value: 'group',
    label: 'Send notification to a specific group',
    alert: 'Are you sure you want to send this notification to the group?'
  },
  {
    value: 'everyone',
    label: 'Send notification to everyone',
    alert: 'Are you sure you want to send this notification to everyone?'
  }
]

const Notifications: React.FC = () => {
  const [title, setTitle] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [sendTo, setSendTo] = React.useState<Option>(options[0])
  const [recipients, setRecipients] = React.useState<UserRecord[]>([])
  const [userDocs, , errorUsers] = useCollection(collections.users)
  const users = userDocs?.docs.map((doc) => doc.data()) ?? []

  const handleClick = (): void => {
    if (sendTo.value === 'group') {
      if (window.confirm(sendTo.alert)) {
        setLoading(true)
        sendNotificationsToGroup(recipients).catch((error) => {
          console.warn(error)
          alert(error)
        }).finally(() => {
          setLoading(false)
        })
      }
    } else {
      if (window.confirm(sendTo.alert)) {
        setLoading(true)
        sendNotificationsToAll().catch((error) => {
          console.warn(error)
          alert(error)
        }).finally(() => {
          setLoading(false)
        })
      }
    }
  }

  const sendNotificationsToGroup = async (users: UserRecord[]): Promise<void> => {
    const tokens = await Promise.all(users.map(async (user) => {
      const data = await getDoc(doc(collections.users, user.id, 'private', 'notifications')) as DocumentSnapshot
      return data.data()?.token
    }))

    const sendNotification = httpsCallable(functions, 'sendNotifications')
    await sendNotification({ title, message, tokens }).then((result) => {
      setTitle('')
      setMessage('')
      setRecipients([])
    })
  }

  const sendNotificationsToAll = async (): Promise<void> => {
    const tokens = await getAllTokens()
    const sendNotification = httpsCallable(functions, 'sendNotifications')
    await sendNotification({ title, message, tokens }).then((result) => {
      setTitle('')
      setMessage('')
    })
  }

  const getAllTokens = async (): Promise<string[]> => {
    const tokens: string[] = []
    const querySnapshot = await getDocs(collections.notificationTokens)
    querySnapshot.forEach((doc) => {
      if (doc.data().token != null) {
        tokens.push(doc.data().token)
      }
    })
    return tokens
  }

  return (
    <Card>
      <Row>
        <Column style={{ flex: 1, marginRight: 20 }}>
          <Title>{sendTo.label}</Title>
          <p>Send a notification to all users who have notifications enabled.</p>
          <Input value={title} type='text' onChange={setTitle} placeholder='Title...' />
          <Input value={message} type='text' onChange={setMessage} placeholder='Message...' />
          <RadioInput
            data={options} value={sendTo} onChange={e => setSendTo(e)} keyExtractor={o => o.value} renderItem={o => {
              return o.label
            }}
          />

          {errorUsers != null && <p>Error loading users: {errorUsers.message}</p>}

          {sendTo.value === 'group' && (
            <UserSelect isMulti label='Select recipients' values={recipients.map(u => u.id)} onUsers={(u) => setRecipients(u)} users={users} />
          )}
          <Button onClick={handleClick} disabled={title === ''} loading={loading}>Send message</Button>
        </Column>
        <Column>
          <Title>Preview</Title>
          <NotificationPreview title={title} message={message} />
        </Column>
      </Row>
    </Card>
  )
}

export default Notifications

import React from 'react'
import styled from 'styled-components'
import { VStack } from 'react-stacked'
import OpenAI from 'openai'

const Message = styled.div<{ role: string }>`
  white-space: pre-wrap;
  padding: 15px;
  border-radius: 10px;
  background-color: ${(props) => props.role === 'assistant' ? '#fff' : '#000'};
  color: ${(props) => props.role === 'assistant' ? '#000' : '#fff'};
  align-self: ${(props) => props.role === 'assistant' ? 'flex-start' : 'flex-end'};
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
`

export interface ThreadMessageProps {
  message: OpenAI.Beta.Threads.Messages.ThreadMessage
}

const ThreadMessage: React.FC<ThreadMessageProps> = ({ message }) => {
  const content = message.content.find((content) => content.type === 'text') as OpenAI.Beta.Threads.Messages.MessageContentText | undefined

  if (content == null) return null
  const textContent = content.text.value

  const role = message.role

  return (
    <Message role={role}>
      <VStack gap={10}>
        {textContent}
      </VStack>
    </Message>
  )
}

export default ThreadMessage

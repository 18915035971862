import React from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.backgroundAlt};
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
`

export interface WorkspaceProps {
  children?: React.ReactNode
}

const Workspace: React.FC<WorkspaceProps> = ({ children }) => {
  const colorScheme = useColorScheme()

  return (
    <Container colorScheme={colorScheme} id='workspace'>
      {children}
    </Container>
  )
}

export default Workspace

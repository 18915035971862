import Avatar from '../components/Avatar'
import Card from '../layout/Card'
import collections from '../lib/collections'
import { DataGrid, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid'
import { useState } from 'react'
import { deleteDoc, doc, getFirestore, query, where } from 'firebase/firestore'
import { firebaseApp, useCount } from '../firebase/firebase'
import Button from '../components/Button'
import { Session } from '../admin-types'
import { useNavigate } from 'react-router-dom'
import { usePaginatedCollection } from '../firebase/pagination'
import CategoryBadge from '../components/CategoryBadge'
import Link from '../components/Link'

const Sessions: React.FC = () => {
  const q = query(collections.sessions, where('archived', '==', false))
  const [count] = useCount(q)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
    sortField: 'title'
  })

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: paginationModel.sortField.replaceAll('_lowercase', ''),
        operatorValue: 'contains',
        value: ''
      }
    ]
  })

  const [sessionDocs, loadingSessions, errorSessions] = usePaginatedCollection(
    filterModel.items[0].value == null
      ? q
      : query(q, where(paginationModel.sortField, '>=', filterModel.items[0].value.toLowerCase())
      ),
    paginationModel.page,
    paginationModel.pageSize,
    paginationModel.sortField,
    'id'
  )

  const [loadingDelete, setLoadingDelete] = useState<string | null>(null)
  const navigate = useNavigate()

  const deleteSession = (session: Session): void => {
    setLoadingDelete(session.id)
    deleteDoc(doc(getFirestore(firebaseApp), 'sessions', session.id)).catch((error) => {
      console.error('Error deleting document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingDelete(null)
    })
  }

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      valueFormatter: ({ value }) => {
        if (value == null || typeof value !== 'string') return ''
        const withSpace = value.replaceAll('_', ' ')
        return withSpace.charAt(0).toUpperCase() + withSpace.slice(1)
      }
    },
    {
      field: 'categoryID',
      headerName: 'Quality',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const id = params.value
        if (id == null) return (<></>)
        return (<CategoryBadge key={id} categoryID={id} />)
      }
    },
    {
      field: 'startsAt',
      headerName: 'Starts at',
      flex: 1,
      valueFormatter: ({ value }) => value != null ? new Date(value).toLocaleString() : ' '
    },
    {
      field: 'acceptedBy',
      headerName: 'Accepted by',
      width: 200,
      renderCell: (params) => {
        const session = params.row as Session
        const isLocal = session.type === 'in_person'

        return (
          <>
            {params.value.map((participant: string) => (
              <Avatar key={participant} userID={participant} isLocal={isLocal && session.createdBy !== participant} />
            ))}
          </>
        )
      }
    },
    {
      field: 'rejectedBy',
      headerName: 'Rejected by',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.value.map((participant: string) => (
              <Avatar key={participant} userID={participant} />
            ))}
          </>
        )
      }
    },
    {
      field: 'participants',
      headerName: 'Participants',
      width: 200,
      renderCell: (params) => {
        const session = params.row as Session
        const isLocal = session.type === 'in_person'

        return (
          <>
            {params.value.map((participant: string) => (
              <Avatar key={participant} userID={participant} isLocal={isLocal} />
            ))}
          </>
        )
      }
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      width: 100,
      renderCell: (params) => {
        return (
          <Avatar userID={params.value} />
        )
      }
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 100,
      renderCell: (params) => {
        const session = params.row as Session
        return (
          <Link href={`https://get-together.app/SessionInfo/${session.id}`}>
            {`get-together.app/SessionInfo/${session.id}`}
          </Link>
        )
      }
    },
    {
      field: '',
      headerName: 'Delete',
      width: 100,
      renderCell: (params) => {
        const session = params.row as Session
        return (
          <Button
            loading={loadingDelete === session.id} onClick={(e) => {
              e.stopPropagation()
              deleteSession(session)
            }}
          >Delete
          </Button>
        )
      }
    }
  ]

  const sessions = sessionDocs?.docs.map((doc) => doc.data())

  const updateSortModel = (model: GridSortModel): void => {
    const sortModel = model[0]

    const fieldName = sortModel.field

    if (sortModel == null) return
    setPaginationModel({ ...paginationModel, sortField: fieldName })
    // Move filter to new field
    setFilterModel({
      ...filterModel,
      items: [
        {
          columnField: fieldName.replaceAll('_lowercase', ''),
          operatorValue: 'contains',
          value: filterModel.items[0].value
        }
      ]
    })
  }

  if (errorSessions != null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <Card>
      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={sessions ?? []}
          columns={columns}
          onRowClick={e => navigate(`/sessions/${e.id}`)}
          paginationMode='server'
          rowCount={count}
          loading={loadingSessions}
          pagination
          pageSize={paginationModel.pageSize}
          page={paginationModel.page}
          onPageChange={(page) => {
            setPaginationModel({ ...paginationModel, page })
          }}
          onPageSizeChange={(pageSize) => {
            setPaginationModel({ ...paginationModel, pageSize })
          }}
          onSortModelChange={updateSortModel}
          sortingMode='server'
          filterMode='server'
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
        />
      </div>
    </Card>
  )
}

export default Sessions

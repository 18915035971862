import React from 'react'
import styled from 'styled-components'
import { Review as ReviewType } from '../../admin-types'
import Card from '../../layout/Card'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { deleteDoc, doc } from 'firebase/firestore'
import collections from '../../lib/collections'
import Avatar from '../Avatar'
import { DeleteOutlined } from '@ant-design/icons'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export interface ReviewProps {
  review: ReviewType
}

const Review: React.FC<ReviewProps> = ({ review }) => {
  const [author] = useDocumentData(doc(collections.users, review.authorID))

  const dateString = new Date(review.createdAt.seconds * 1000).toLocaleDateString()

  const deleteReview = (): void => {
    if (window.confirm('Are you sure you want to delete this review?')) {
      deleteDoc(doc(collections.sessionTemplates, review.templateID, 'reviews', review.authorID)).catch(e => {
        console.error(e)
        alert('An error occurred while deleting this review. Please try again.')
      })
    }
  }

  return (
    <Card style={{ flex: 1, margin: 0, position: 'relative' }}>
      <p style={{
        margin: 0,
        position: 'absolute',
        bottom: 20,
        right: 20,
        color: '#888'
      }}
      >
        {dateString}
      </p>
      <p style={{ margin: 0, marginBottom: 15, marginTop: 0 }}>{review.comment}</p>
      {author != null && (
        <Row>
          <Avatar user={author} />
          <p style={{ marginLeft: 10, fontWeight: 'bold' }}>{author.firstName} {author.lastName}</p>
        </Row>
      )}
      <DeleteOutlined
        onClick={deleteReview} style={{
          position: 'absolute',
          top: 20,
          right: 20,
          cursor: 'pointer'
        }}
      />
    </Card>
  )
}

export default Review

import React, { useEffect } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'

const storage = getStorage()

export interface VideoPlayerProps {
  filepath: string
  style?: React.CSSProperties
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ filepath, style }) => {
  const [videoURL, setVideoURL] = React.useState<string>('')

  useEffect(() => {
    const getFirebaseVideo = async (id: string): Promise<void> => {
      try {
        const video = await getDownloadURL(ref(storage, id))
        console.log(video)
        setVideoURL(video)
      } catch (err) {
        console.log(err)
        alert('Video not found')
      }
    }

    getFirebaseVideo(filepath).catch(console.error)
  }, [filepath])

  return (
    <video controls style={style} autoPlay>
      <source src={videoURL} type='video/mp4' />
    </video>
  )
}

export default VideoPlayer

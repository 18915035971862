import React from 'react'
import Select, { MultiValue, SingleValue } from 'react-select'
import styled from 'styled-components'
import Label from './Label'
import { UserRecord } from '../admin-types'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

type VariantProps = {
  isMulti?: false
  onUser: (users: UserRecord | undefined) => void
  onUsers?: never
  value: string | undefined
  values?: never
} | {
  isMulti: true
  onUser?: never
  onUsers: (users: UserRecord[]) => void
  value?: never
  values: string[]
}

export interface UserSelectProps {
  users: UserRecord[]
  label?: string
  allowNone?: boolean
}

const UserSelect: React.FC<UserSelectProps & VariantProps> = ({ value, values, users, onUser, onUsers, label, isMulti = false, allowNone = false }) => {
  value = value ?? ''
  const options = users.map((user) => (
    {
      value: user.id,
      label: user.firstName + ' ' + user.lastName
    }
  )).sort((a, b) => a.label.localeCompare(b.label))

  const getOriginalUser = (id: string | undefined): UserRecord => {
    if (id === undefined) return users[0]
    return users.find((user) => user.id === id) as UserRecord
  }

  const handleMultiChange = (u: MultiValue<{
    value: string
    label: string
  }>): void => {
    if (u === null) {
      if (onUsers == null) return
      onUsers([] as UserRecord[])
    } else {
      const users = u.map((user) => getOriginalUser(user.value))
      if (onUsers == null) return
      onUsers(users)
    }
  }

  const handleSingleChange = (u: SingleValue<{
    value: string
    label: string
  }>): void => {
    if (onUser == null) return
    if (u?.value === '') return onUser(undefined)
    onUser(getOriginalUser(u?.value))
  }

  const selected = users.find((user) => user.id === value)
  const multiSelected = values?.map(value => users.find((user) => user.id === value)).filter(user => user !== undefined) as UserRecord[]

  if (!isMulti && selected === undefined && !allowNone) return <p>Incorrect value passed to select.</p>
  if (isMulti && multiSelected === undefined && !allowNone) return <p>Incorrect value passed to select.</p>

  const myvalue = isMulti
    ? multiSelected.map(user => {
      return { value: user.id, label: user.firstName + ' ' + user.lastName }
    })
    : ((selected != null) ? { value: selected.id, label: selected.firstName + ' ' + selected.lastName } : { value: '', label: 'None' })

  return (
    <Column>
      {label != null && <Label>{label}</Label>}
      <Select
        value={myvalue}
        styles={{
          control: (provided) => ({
            ...provided
          })
        }}
        options={[...options, ...(allowNone ? [{ value: '', label: 'None' }] : [])]}
        onChange={(e) => isMulti
          ? handleMultiChange(e as MultiValue<{
            value: string
            label: string
          }>)
          : handleSingleChange(e as SingleValue<{
            value: string
            label: string
          }>)}
        isMulti={isMulti}
      />
    </Column>
  )
}

export default UserSelect

import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'

interface TranscodeError {
  code: number
  message: string
  details: {
    '@type': string
    fieldViolations: Array<{
      field: string
      description: string
    }>
  }
}

export interface TranscodeJob {
  config: {
    inputs: Array<{
      key: string
      uri: string
      preprocessingConfig: null
    }>
    editList: any[]
    elementaryStreams: any[]
    muxStreams: any[]
    manifests: any[]
  }
  createTime: {
    seconds: string
    nanos: string
  }
  endTime: {
    seconds: string
    nanos: string
  }
  error: null | TranscodeError
  inputUri: string
  jobConfig: string
  labels: {}
  mode: string
  name: string
  outputUri: string
  startTime: {
    seconds: string
    nanos: string
  }
  state: 'SUCCEEDED' | 'FAILED' | 'RUNNING'
  ttlAfterCompletionDays: number
}

interface TranscodeStatusResponse {
  job: TranscodeJob | null
  error: Error | null
}

const functions = getFunctions()
const getTranscodeJob = httpsCallable(functions, 'getTranscodeJob')

export const useTranscodeStatus = (transcodeId: string, interval?: number): TranscodeStatusResponse => {
  const [job, setJob] = useState<TranscodeJob | null>(null)
  const [error, setError] = useState<Error | null>(null)
  if (interval == null) interval = 5000

  useEffect(() => {
    const intervalFunction = (): void => {
      console.log('fetching transcode job')
      getTranscodeJob({ jobID: transcodeId }).then((result) => {
        const data = result.data as TranscodeJob[]
        if (data.length === 0) return
        setJob(data[0])
      }).catch((error) => {
        console.warn(error)
        setError(error)
      })
    }

    if (error != null) return
    // fetch every 5 seconds
    const intervalID = setInterval(intervalFunction, interval)
    return () => {
      clearInterval(intervalID)
    }
  }, [transcodeId, interval, error])

  return {
    job,
    error
  }
}

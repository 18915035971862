import { useNavigate, useParams } from 'react-router-dom'
import Card from '../layout/Card'
import EditSession from '../components/EditSession'
import { useEffect, useMemo, useState } from 'react'
import { CreateInput, Session } from '../admin-types'
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import collections, { courseSessionTemplates } from '../lib/collections'
import { removeUndefinedKeys } from '../utils/removeUndefinedKeys'
import { createEmptySession } from '../lib/sessions'
import { auth } from '../firebase/firebase'
import Reviews from '../components/reviews/Reviews'
import { useAuthState } from 'react-firebase-hooks/auth'

type LocalSession = Session | CreateInput<Session>

const SessionTemplate: React.FC = () => {
  const { id, courseID } = useParams()
  const navigate = useNavigate()

  const [user] = useAuthState(auth)
  const userID = user?.uid ?? ''

  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState<LocalSession>(createEmptySession(userID, userID, '', true))
  const [editingExisting, setEditingExisting] = useState(false)

  const refID = id === 'new' ? currentTemplate.id : id
  const ref = useMemo(() => {
    return courseID != null
      ? doc(courseSessionTemplates(courseID), refID)
      : doc(collections.sessionTemplates, refID)
  }, [refID, courseID])

  useEffect(() => {
    if (id == null) return
    if (id === 'new') return
    getDoc(ref).then((doc) => {
      if (doc.exists()) {
        const data = doc.data()
        setCurrentTemplate(data)
        setEditingExisting(true)
      } else {
        console.error('No such document!')
      }
    }).catch((error) => {
      console.error('Error getting document:', error)
    })
  }, [id, ref])

  const saveSessionTemplate = (template: LocalSession): void => {
    if (courseID != null) {
      template.courseID = courseID
    }
    setLoadingSave(true)
    if (editingExisting) {
      template.id = currentTemplate.id
    }
    setDoc(ref, removeUndefinedKeys(template)).catch((error) => {
      console.error('Error adding document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingSave(false)
      if (courseID == null) {
        navigate(`/session-templates/${template.id}`)
      } else {
        navigate(`/course-templates/${courseID}/template/${template.id}`)
      }
    })
  }

  const navigateBack = (): void => {
    if (courseID == null) {
      navigate('/session-templates')
    } else {
      navigate(`/course-templates/${courseID}`)
    }
  }

  const deleteSessionTemplate = (template: LocalSession): void => {
    if (id === 'new') {
      navigateBack()
      return
    }
    setLoadingDelete(true)

    deleteDoc(ref).then(() => {
      setCurrentTemplate(createEmptySession(userID, userID, '', true))
    }).then(() => {
      navigateBack()
    }).catch((error) => {
      console.error('Error deleting document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingDelete(false)
    })
  }

  return (
    <>
      <Card>
        <EditSession
          session={currentTemplate}
          onSession={
            (edits) => setCurrentTemplate({ ...currentTemplate, ...edits })
          }
          onSave={
            () => saveSessionTemplate(currentTemplate)
          }
          loadingSave={loadingSave}
          loadingDelete={loadingDelete}
          onDelete={() => deleteSessionTemplate(currentTemplate)}
          onCancel={() => {
            navigateBack()
          }}
          editingExisting={editingExisting}
          courseID={courseID}
        />
      </Card>
      <Card>
        {currentTemplate != null && <Reviews session={currentTemplate as Session} />}
      </Card>
    </>
  )
}

export default SessionTemplate

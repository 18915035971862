import React from 'react'
import styled from 'styled-components'
import Card from '../layout/Card'
import { firebaseApp } from '../firebase/firebase'
import { getFunctions } from 'firebase/functions'
import UsersWidget from '../components/statistics/widgets/Users'
import SessionsWidget from '../components/statistics/widgets/Sessions'
import CategoriesWidget from '../components/statistics/widgets/Categories'

const functions = getFunctions(firebaseApp)
functions.region = 'europe-west1'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Statistics: React.FC = () => {
  return (
    <Column>
      <Row>
        <UsersWidget />
        <SessionsWidget />
        <CategoriesWidget />
      </Row>
      <Card>
        <h1>Statistics</h1>

        {/* <Button>Verify date</Button> */}
      </Card>
    </Column>
  )
}

export default Statistics

import React from 'react'
import styled from 'styled-components'
import { TitleDescription } from 'together-types'
import Label from './Label'
import Input from './Input'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import Button from './Button'
import TextArea from './TextArea'

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`

export interface TitleDescriptionArrayInputProps {
  value: TitleDescription[]
  onChange: (value: TitleDescription[]) => void
  label?: string
  titlePlaceholder?: string
  descriptionPlaceholder?: string
}

const TitleDescriptionArrayInput: React.FC<TitleDescriptionArrayInputProps> = ({ value, onChange, label, titlePlaceholder, descriptionPlaceholder }) => {
  const remove = (index: number): void => {
    onChange(value.filter((_, i) => i !== index))
  }

  const add = (): void => {
    onChange([...value, {
      title: '',
      description: ''
    }])
  }

  return (
    <List>
      {label != null && <Label>{label}</Label>}

      {value.map((item, index) => (
        <Item key={index}>
          <Column>
            <Input
              placeholder={titlePlaceholder}
              value={item.title}
              onChange={(e) => onChange(value.map((v, i) => i === index ? { ...v, title: e } : v))}
            />
            <TextArea
              placeholder={descriptionPlaceholder}
              style={{ flex: 1 }}
              inputStyle={{ minHeight: 100 }}
              value={item.description}
              onChange={(e) => onChange(value.map((v, i) => i === index ? { ...v, description: e } : v))}
              maxLength={340}
            />
          </Column>
          <Button onClick={() => remove(index)}><DeleteOutlined /></Button>
        </Item>
      ))}

      <Button onClick={add}><PlusOutlined /></Button>
    </List>
  )
}

export default TitleDescriptionArrayInput

import styled from 'styled-components'

const Card = styled.div<{ center?: boolean }>`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${props => props.center === true ? 'align-items: center;' : ''}
  ${props => props.center === true ? 'justify-content: center;' : ''}
`

export default Card

import { CameraOutlined } from '@ant-design/icons'
import React, { ChangeEvent, useEffect, useRef } from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { getFileHash } from '../lib/hash'
import { ClipLoader } from 'react-spinners'
import { Asset } from 'together-types'
import { UserRecord } from '../admin-types'

const storage = getStorage()

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 300px;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${(props: { colorScheme: ColorScheme, allowUpload: boolean }) => props.colorScheme.backgroundAlt}};
  cursor: ${props => props.allowUpload ? 'pointer' : 'default'};
`

const Placeholder = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 100%;
`

export interface ImageSelectProps {
  image?: Asset | UserRecord['avatar'] | null // show placeholder if null
  onUploadComplete?: (image: Asset) => void // called when upload is complete and image has been compressed on server
  onUploadAvatarComplete?: (iamge: UserRecord['avatar']) => void // called when upload is complete and image has been compressed on server
  allowUpload?: boolean // if false, only show image
  avatarID?: string
}

async function sleep (ms: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

const ImageSelect: React.FC<ImageSelectProps> = ({ image, onUploadComplete, onUploadAvatarComplete, allowUpload = true, avatarID }) => {
  // check if image ends with sizexsize otherwise add 1080x1080 as default
  // if (image != null && (image.match(/_\d+x\d+$/) == null)) {
  //   image = `${image}_1080x1080`
  // }

  const [file, setFile] = React.useState<File | null>(null)
  const [progress, setProgress] = React.useState<number | null | 'compressing'>(null)

  const [imageURL, setImageURL] = React.useState<string | null>(null)

  const colorScheme = useColorScheme()
  const inputRef = useRef<HTMLInputElement>(null)

  const pickImage = (): void => {
    inputRef.current?.click()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files == null) return
    setImageURL(null)
    setFile(event.target.files[0])
  }

  useEffect(() => {
    const waitForCompressing = async (filename: string): Promise<void> => {
      const getURL = async (path: string): Promise<string | null> => {
        // console.log('checking if file exists')
        const storageRef = ref(storage, path)
        try {
          const url = await getDownloadURL(storageRef)
          return url
        } catch (error) {
          return null
        }
      }
      // wait for file to exist
      const asset: Asset = {
        downloadURL1080x1080: '',
        downloadURL96x96: '',
        id: filename
      }

      const avatar: UserRecord['avatar'] = {
        downloadURL1080x1080: '',
        downloadURL200x200: '',
        id: avatarID ?? ''
      }

      while (asset.downloadURL1080x1080 === '') {
        await sleep(1000)
        const url = avatarID == null ? `images/${filename}_1080x1080` : `avatars/${filename}_1080x1080`
        const downloadURL1080x1080 = await getURL(url).then((result) => result)
        if (downloadURL1080x1080 != null) {
          asset.downloadURL1080x1080 = downloadURL1080x1080
          avatar.downloadURL1080x1080 = downloadURL1080x1080
        }
      }

      if (avatarID == null) {
        while (asset.downloadURL96x96 === '') {
          await sleep(1000)
          const downloadURL96x96 = await getURL(`images/${filename}_96x96`).then((result) => result)
          if (downloadURL96x96 != null) {
            asset.downloadURL96x96 = downloadURL96x96
          }
        }
      }

      if (avatarID != null) {
        while (avatar.downloadURL200x200 === '') {
          await sleep(1000)
          const downloadURL200x200 = await getURL(`avatars/${filename}_200x200`).then((result) => result)
          if (downloadURL200x200 != null) {
            avatar.downloadURL200x200 = downloadURL200x200
          }
        }
      }
      if (avatarID != null) onUploadAvatarComplete?.(avatar)
      if (avatarID == null) onUploadComplete?.(asset)
      setProgress(null)
    }

    const upload = async (): Promise<void> => {
      // send file to /upload
      if (file == null) return
      const formData = new FormData()

      const filename = avatarID == null ? await getFileHash(file) : avatarID
      // change file name to id
      const newFile = new File([file], filename, { type: file.type })

      formData.append('file', newFile)

      const storageRef = avatarID == null ? ref(storage, `upload/${newFile.name}`) : ref(storage, `avatars/${filename}`)
      const task = uploadBytesResumable(storageRef, file)

      task.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          if (progress === 100) {
            setProgress('compressing')
            setFile(null)
            waitForCompressing(filename).catch((error) => {
              console.warn(error)
            })
          } else {
            setProgress(progress)
          }
        }
      )
    }

    if (file == null) return
    upload().catch((error) => {
      console.warn(error)
    })
  }, [file, onUploadComplete, avatarID, onUploadAvatarComplete])

  useEffect(() => {
    if (image == null) {
      setImageURL(null)
      setProgress(null)
      // clear file input
      if (inputRef.current != null) {
        inputRef.current.value = ''
      }
      return
    }
    // get image from /images in firebase
    setImageURL(image.downloadURL1080x1080)
  }, [image])

  return (
    <Container
      allowUpload={allowUpload}
      colorScheme={colorScheme} onClick={() => {
        if (allowUpload) pickImage()
      }}
    >
      {imageURL == null && (
        <Placeholder>

          {progress == null && (
            <>
              <CameraOutlined style={{ fontSize: 40, color: colorScheme.onBackgroundMuted }} />
              <p>Upload image</p>
            </>
          )}
          {typeof progress === 'number' && (
            <>
              <ClipLoader />
              <p>Uploading {Math.round(progress * 10) / 10}%</p>
            </>
          )}
          {progress === 'compressing' && (
            <>
              <ClipLoader />
              <p>Compressing...</p>
            </>
          )}
        </Placeholder>
      )}

      {imageURL != null && (
        <Image src={imageURL} alt='uploaded' />
      )}

      <input ref={inputRef} style={{ display: 'none' }} type='file' accept='image/*' onChange={handleChange} />

    </Container>
  )
}

export default ImageSelect

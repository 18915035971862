import React from 'react'
import OpenAI from 'openai'
import { HStack, VStack } from 'react-stacked'
import { aiFunctionDescriptions } from '../../lib/ai-function-descriptions'
import { CheckBoxInput } from '../OptionInput'

type Tool = (OpenAI.Beta.Assistants.Assistant.CodeInterpreter | OpenAI.Beta.Assistants.Assistant.Retrieval | OpenAI.Beta.Assistants.Assistant.Function)

const keyExtractor = (tool: Tool): string => {
  switch (tool.type) {
    case 'retrieval':
      return 'retrieval'
    case 'function':
      return tool.function.name
    case 'code_interpreter':
      return 'code_interpreter'
  }
}

const titleExtractor = (tool: Tool): string => {
  switch (tool.type) {
    case 'retrieval':
      return 'Retrieval (enable to access external files)'
    case 'function':
      switch (tool.function.name) {
        case 'addReplySuggestions':
          return 'Reply suggestions'
        case 'createSession':
          return 'Create session'
        default:
          return tool.function.name
      }
    case 'code_interpreter':
      return 'Code Interpreter'
  }
}

const allTools: Tool[] = [
  ...aiFunctionDescriptions.map(fn => {
    const tool: OpenAI.Beta.Assistant['tools'][0] = {
      type: 'function',
      function: fn
    }
    return tool
  }),
  {
    type: 'retrieval'
  }
  // {
  //   type: 'code_interpreter'
  // }
]

export interface ToolsSelectorProps {
  value: Tool[]
  onChange: (tools: Tool[]) => void
}

const ToolsSelector: React.FC<ToolsSelectorProps> = ({ value, onChange }) => {
  return (
    <VStack gap={10}>
      <HStack gap={10} alignItems='center'>
        <CheckBoxInput
          value={value}
          data={allTools}
          onChange={onChange}
          keyExtractor={keyExtractor}
          renderItem={t => {
            return titleExtractor(t)
          }}
        />
      </HStack>
    </VStack>

  )
}

export default ToolsSelector

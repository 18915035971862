import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { Session, Video } from '../admin-types'
import Label from './Label'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export interface VideoSelectProps {
  value: Session['videoID']
  videos: Video[]
  onVideo: (value: Video | undefined) => void
  label?: string
}

const VideoSelect: React.FC<VideoSelectProps> = ({ value, videos, onVideo, label }) => {
  if (value === undefined) {
    value = 'None'
  }

  const handleChange = (value: string | undefined): void => {
    if (value === '') {
      onVideo(undefined)
    } else {
      onVideo(videos.find(v => v.id === value))
    }
  }

  return (
    <Column>
      {label != null && <Label>{label}</Label>}
      <Select
        value={{ value, label: videos.find((video) => video.id === value)?.name ?? 'None' }}
        styles={{
          control: (provided) => ({
            ...provided
          })
        }}
        options={[{ value: '', label: 'None' }, ...videos.map((video) => ({ value: video.id, label: video.name }))]}
        onChange={(c) => handleChange(c?.value)}
      />
    </Column>
  )
}

export default VideoSelect

import { Unsubscribe, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../firebase/firebase'
import { UserRecord } from '../admin-types'
import { doc, onSnapshot } from 'firebase/firestore'
import collections from '../lib/collections'

interface UseUser {
  user: UserRecord | null
  loading: boolean
  error: Error | null
}

const useUser = (): UseUser => {
  const [user, setUser] = useState<UserRecord | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const updateListener = (user: User): Unsubscribe => {
    const ref = doc(collections.users, user.uid)
    const unsubscribe = onSnapshot(ref, (doc) => {
      setLoading(false)
      if (doc.exists()) {
        setUser(doc.data())
      } else {
        setUser(null)
      }
    }, (e) => {
      setLoading(false)
      setError(e)
    })

    return unsubscribe
  }

  useEffect(() => {
    const authUnsubscribe = auth.onAuthStateChanged((user) => {
      if (user != null) {
        const fbUnsubscibe = updateListener(user)
        return (): void => {
          fbUnsubscibe()
        }
      }
    })

    return (): void => {
      authUnsubscribe()
    }
  }, [])

  return {
    user,
    loading,
    error
  }
}

export default useUser

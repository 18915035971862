import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  z-index: 10000;
`

const Option = styled.div`
  cursor: pointer;
  padding: 20px;
  white-space: nowrap;
  text-align: left;

  :hover {
    background-color: #f5f5f5;
  }
`

export interface MenuProps {
  options: string[]
  value?: string
  onOption: (option: string) => void
  highlightColor?: string
  color?: string
}

const Menu: React.FC<MenuProps> = ({ options, value, onOption, highlightColor = '#1890ff', color = '#000' }) => {
  return (
    <Container>
      {options.map((option) => (
        <Option
          key={option} onClick={() => onOption(option)} style={{
            color: option === value ? highlightColor : color
          }}
        >
          {option}
        </Option>
      ))}
    </Container>
  )
}

export default Menu

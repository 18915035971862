import React from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { ClipLoader } from 'react-spinners'
import ReactTooltip from 'react-tooltip'
import { UserRecord } from '../admin-types'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import { doc } from 'firebase/firestore'

const Container = styled.div<{ url?: string, colorScheme: ColorScheme, size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.url});
  border: 2px solid ${props => props.colorScheme.background};
`

const Placeholder = styled.div`
  display: flex;
  width: ${(props: { color: string, size: number }) => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  background-color: ${props => props.color};
  justify-content: center;
  align-items: center;
`

const Letters = styled.div`
  display: flex;
  fontSize: 20px;
  user-select: none;
  cursor: default;
  text-transform: uppercase;
`

interface AvatarData {
  user: UserRecord
  userID?: string
  size?: number
  isLocal?: boolean
}

interface AvatarID {
  user?: UserRecord
  userID: string
  size?: number
  isLocal?: boolean
}

export type AvatarProps = AvatarData | AvatarID

const Avatar: React.FC<AvatarData> = ({ user, userID, size = 40 }) => {
  const colorScheme = useColorScheme()
  const url = user.avatar?.downloadURL200x200 ?? ''

  if (user.avatar == null || user.avatar.downloadURL200x200 === '') {
    return (
      <Placeholder color={colorScheme.backgroundAlt} size={size} data-tip={user.firstName + ' ' + user.lastName}>
        <Letters>{user.firstName.charAt(0)}{user.lastName.charAt(0)}</Letters>
        <ReactTooltip place='bottom' type='light' />
      </Placeholder>
    )
  }

  return (
    <Container url={url} colorScheme={colorScheme} size={size} data-tip={user.firstName + ' ' + user.lastName}>
      <ReactTooltip place='bottom' type='light' />
    </Container>
  )
}

const UserLoader: React.FC<AvatarID> = ({ user, userID, size = 40 }) => {
  const [data, loading, error] = useDocumentData<UserRecord>(doc(collections.users, userID))
  const colorScheme = useColorScheme()

  if (error != null) {
    return (
      <Placeholder color={colorScheme.backgroundAlt} size={size}>
        <Letters>??</Letters>
      </Placeholder>
    )
  }

  if (loading || data == null) {
    return (
      <Container colorScheme={colorScheme} size={size}>
        <ClipLoader />
      </Container>
    )
  }

  return <Avatar user={data} size={size} />
}

const Switch: React.FC<AvatarProps> = ({ user, userID, size = 40, isLocal }) => {
  const colorScheme = useColorScheme()

  if (isLocal === true) {
    return (
      <Placeholder color={colorScheme.backgroundAlt} size={size} data-tip={userID}>
        <Letters>{userID?.slice(0, 2)}</Letters>
        <ReactTooltip place='bottom' type='light' />
      </Placeholder>
    )
  }

  if (user != null) return <Avatar user={user} size={size} />
  if (userID != null) return <UserLoader userID={userID} size={size} />
  return <></>
}

export default Switch

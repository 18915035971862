import React from 'react'
import styled from 'styled-components'
import { Session } from '../../admin-types'
import { PlusCircleFilled } from '@ant-design/icons'
import HiddenInput from '../HiddenInput'
import CardHeader from './CardHeader'
import CardFooterText from './CardFooterText'
import ContextMenu from '../context-menu'
import Menu from '../context-menu/Menu'
import VideoPreview from './VideoPreview'
import CardFooterVideo from './CardFooterVideo'
export const cardAspectRatio = 2.5 / 3.8

export const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  aspect-ratio: ${cardAspectRatio};
  border-radius: 20px;
  justify-content: center;
  align-items: stretch;
  background-color: white;
  position: relative;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.13);
  p {
    text-align: center;
  }
`

export const Placeholder: React.FC<{ onClick: ((type: 'text' | 'video') => void) }> = ({ onClick }) => {
  return (
    <ContextMenu
      menu={<Menu
        options={['Text', 'Video']}
        onOption={(o) => {
          onClick(o === 'Text' ? 'text' : 'video')
        }}
        value=''
            />}
    >
      <Thumbnail style={{ cursor: 'pointer' }}>
        <PlusCircleFilled style={{ fontSize: 40 }} />
        <p>Add event</p>
      </Thumbnail>
    </ContextMenu>
  )
}

export interface EventCardProps {
  event: Session['agenda'][0]
  onEvent: (event: Session['agenda'][0]) => void
  onDelete: () => void
  style?: React.CSSProperties
  linkToNextAvailable?: boolean
  onDuration?: (duration: number) => void
}

const EventCard: React.FC<EventCardProps> = ({ event, style, onEvent, onDelete, linkToNextAvailable }) => {
  const isVideoEvent = event.videoID != null

  if (isVideoEvent) {
    return (
      <Thumbnail style={style}>
        <div style={{ padding: 10, flex: 1, display: 'flex' }}>
          <VideoPreview
            videoID={event.videoID} onDuration={d => {
              if (event.durationSeconds == null || event.durationSeconds === 0) {
                onEvent({ ...event, durationSeconds: Math.ceil(d) })
              }
            }}
          />
        </div>
        <CardFooterVideo
          event={event}
          onEvent={(e) => onEvent({ ...event, ...e })}
          onDelete={onDelete}
        />
      </Thumbnail>
    )
  }

  return (
    <Thumbnail style={style}>
      <CardHeader event={event} onCardType={(type) => onEvent({ ...event, cardTypeID: type })} />
      {!isVideoEvent && (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <HiddenInput value={event.description} onChange={(e) => onEvent({ ...event, description: e })} placeholder='Prompt...' />
        </div>
      )}
      <CardFooterText
        event={event}
        onEvent={(e) => onEvent({ ...event, ...e })}
        onDelete={onDelete}
        linkToNextAvailable={linkToNextAvailable}
      />
    </Thumbnail>
  )
}

export default EventCard

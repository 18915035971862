import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import Input from '../components/Input'
import Logo from '../components/Logo'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { signInWithEmailAndPassword, signInWithPopup, OAuthProvider } from 'firebase/auth'
import { auth } from '../firebase/firebase'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.backgroundAlt};
  min-height: 100vh;
`

const Card = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.background};
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  text-align: center;
`

const Login: React.FC = () => {
  const colorScheme = useColorScheme()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const handleLogin = useCallback(() => {
    setLoading(true)
    signInWithEmailAndPassword(auth, email, password).catch(alert).finally(() => setLoading(false))
  }, [email, password])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        handleLogin()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return (): void => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleLogin])

  const signInWithApple = (): void => {
    const provider = new OAuthProvider('apple.com')
    signInWithPopup(auth, provider).then((result) => {
      console.log(result.user) // logged-in Apple user
    }).catch(console.error)
  }

  return (
    <Container colorScheme={colorScheme}>
      <Card colorScheme={colorScheme} style={{ gap: 10 }}>
        <Logo width={160} style={{ alignSelf: 'center' }} />
        <h1 style={{ marginBottom: 0 }}>Welcome to Together</h1>
        <h3 style={{ marginBottom: 50 }}>Please login below</h3>
        <Input placeholder='email' value={email} onChange={setEmail} />
        <Input type='password' placeholder='password' value={password} onChange={setPassword} />
        <Button loading={loading} onClick={handleLogin}>Login</Button>
        <Button onClick={signInWithApple}>Sign in with Apple</Button>
      </Card>
    </Container>
  )
}

export default Login

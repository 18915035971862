import React, { useCallback, useEffect, useRef } from 'react'

export interface CountDownProps {
  start: number
  duration: number
}

const CountDown: React.FC<CountDownProps> = ({ start, duration }) => {
  const timeLeftStart = duration - (Date.now() - start) / 1000
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [timeLeft, setTimeLeft] = React.useState(timeLeftStart)

  const updateTimeLeft = useCallback((): void => {
    setTimeLeft(duration - (Date.now() - start) / 1000)
    setTimeout(() => {
      buttonRef.current?.click()
    }, 1000)
  }, [duration, start])

  useEffect(() => {
    updateTimeLeft()
  }, [updateTimeLeft])

  return (
    <div>
      <p>{new Date(start).toISOString()}</p>
      <p>Time left: {Math.round(timeLeft)}s</p>
      <button ref={buttonRef} onClick={updateTimeLeft} style={{ display: 'none' }}>Refresh</button>
    </div>
  )
}

export default CountDown

import React from 'react'
import Base from '.'
import { AppstoreOutlined } from '@ant-design/icons'
import collections from '../../../lib/collections'
import { useCount } from '../../../firebase/firebase'

const CategoriesWidget: React.FC = () => {
  const [count, loading] = useCount(collections.categories)

  return (
    <Base label='Categories' value={{ total: count }} loading={loading} icon={<AppstoreOutlined style={{ fontSize: 30 }} />} />
  )
}

export default CategoriesWidget

import React from 'react'
import { useParams } from 'react-router-dom'
import Card from '../layout/Card'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import collections from '../lib/collections'
import Loading from './Loading'
import EditDiscoverCategory from '../components/EditDiscoverCategory'

const DiscoverEntry: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [category, loading, error] = useDocumentData(doc(collections.discoverCategories, id))

  if (error != null) {
    return (
      <Card>
        <p>{error.message}</p>
      </Card>
    )
  }

  if (category == null || loading) {
    return (
      <Card>
        <Loading />
      </Card>
    )
  }

  return (
    <Card>
      <EditDiscoverCategory value={category} />
    </Card>
  )
}

export default DiscoverEntry

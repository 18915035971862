import React, { useState } from 'react'
import Card from '../layout/Card'
import { useCollection } from 'react-firebase-hooks/firestore'
import Avatar from '../components/Avatar'
import collections from '../lib/collections'
import { DataGrid, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid'
import { deleteDoc, doc, getFirestore, query, where } from 'firebase/firestore'
import { firebaseApp, useCount } from '../firebase/firebase'
import Button from '../components/Button'
import { Session, UserRecord } from '../admin-types'
import { usePaginatedCollection } from '../firebase/pagination'
import CategoryBadge from '../components/CategoryBadge'

const SessionArchive: React.FC = () => {
  const q = query(collections.sessions, where('archived', '==', true))
  const [count] = useCount(q)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
    sortField: 'title'
  })

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: paginationModel.sortField.replaceAll('_lowercase', ''),
        operatorValue: 'contains',
        value: ''
      }
    ]
  })

  const [sessionDocs, loadingSessions, errorSessions] = usePaginatedCollection(
    filterModel.items[0].value == null
      ? q
      : query(q, where(paginationModel.sortField, '>=', filterModel.items[0].value.toLowerCase())
      ),
    paginationModel.page,
    paginationModel.pageSize,
    paginationModel.sortField,
    'id'
  )

  const usersOnThisPageIds = sessionDocs?.docs.map((doc) => doc.data()).map(s => [...s.participants, s.createdBy]).flat()
    .filter((value, index, self) => self.indexOf(value) === index)
  const uniqueUsersOnThisPageIds = Array.from(new Set(usersOnThisPageIds))

  const [userDocs, loadingUsers, errorUsers] = useCollection(
    uniqueUsersOnThisPageIds == null ||
      uniqueUsersOnThisPageIds.length === 0
      ? null
      : query(collections.users, where('id', 'in', uniqueUsersOnThisPageIds.slice(0, 10)))) // TODO: implement better way to do this as you can use max 10 args using in
  const [loadingDelete, setLoadingDelete] = useState<string | null>(null)

  const users = userDocs?.docs.map((doc) => doc.data())
  const sessions = sessionDocs?.docs.map((doc) => doc.data()).map(s => {
    return {
      ...s,
      participants: s.participants.map(p => users?.find(u => u.id === p)),
      createdBy: users?.find(u => u.id === s.createdBy)
    }
  })

  const deleteSession = (session: Session): void => {
    setLoadingDelete(session.id)
    deleteDoc(doc(getFirestore(firebaseApp), 'sessions', session.id)).catch((error) => {
      console.error('Error deleting document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingDelete(null)
    })
  }

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'id', headerName: 'ID', sortable: false, flex: 1 },
    {
      field: 'categoryID',
      headerName: 'Quality',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const id = params.value
        if (id == null) return (<></>)
        return (<CategoryBadge key={id} categoryID={id} />)
      }
    },
    {
      field: 'startsAt',
      headerName: 'Starts at',
      flex: 1,
      valueFormatter: ({ value }) => value != null ? new Date(value).toLocaleString() : ' '
    },
    {
      field: 'participants',
      headerName: 'Participants',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const participants: UserRecord[] = params.value.filter((p: UserRecord | undefined) => p != null)
        return (
          <>
            {participants.map((participant) => (
              <Avatar key={participant.id} user={participant} />
            ))}
          </>
        )
      }
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const user: UserRecord = params.value
        if (user == null) return (<></>)
        return (
          <Avatar user={user} />
        )
      }
    },
    {
      field: '',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const session = params.row as Session
        return (
          <Button loading={loadingDelete === session.id} onClick={() => deleteSession(session)}>Delete</Button>
        )
      }
    }
  ]

  const updateSortModel = (model: GridSortModel): void => {
    const sortModel = model[0]

    const fieldName = sortModel.field

    if (sortModel == null) return
    setPaginationModel({ ...paginationModel, sortField: fieldName })
    // Move filter to new field
    setFilterModel({
      ...filterModel,
      items: [
        {
          columnField: fieldName.replaceAll('_lowercase', ''),
          operatorValue: 'contains',
          value: filterModel.items[0].value
        }
      ]
    })
  }

  if (errorSessions != null || errorUsers != null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <Card>
      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={sessions ?? []}
          columns={columns}
          paginationMode='server'
          rowCount={count}
          loading={loadingSessions || loadingUsers}
          pagination
          pageSize={paginationModel.pageSize}
          page={paginationModel.page}
          onPageChange={(page) => {
            setPaginationModel({ ...paginationModel, page })
          }}
          onPageSizeChange={(pageSize) => {
            setPaginationModel({ ...paginationModel, pageSize })
          }}
          onSortModelChange={updateSortModel}
          sortingMode='server'
          filterMode='server'
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
        />
      </div>
    </Card>
  )
}

export default SessionArchive

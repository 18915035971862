import React, { ReactNode } from 'react'
import Card from '../../../layout/Card'
import styled from 'styled-components'
import { ClipLoader } from 'react-spinners'
import { Statistic } from 'together-types'
import useColorScheme from '../../../constants/Colors'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-grow: 1;
  position: relative;
  background-color: ${props => props.theme.background};
  padding-top: 30px;
  padding-bottom: 30px;

  > * {
    color: ${props => props.theme.onBackgroundMuted};
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.onBackground};
`

const Absolute = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export interface BaseProps {
  icon: ReactNode
  value?: Statistic
  label: string
  loading?: boolean
}

const Base: React.FC<BaseProps> = ({ icon, value, label, loading = false }) => {
  const total = value?.total == null ? 0 : value.total
  const created = value?.created == null ? 0 : value.created
  const deleted = value?.deleted == null ? 0 : value.deleted
  const colorScheme = useColorScheme()

  const displayDelete = value?.deleted != null
  const displayCreate = value?.created != null

  return (
    <Content as={Card}>
      <div style={{ color: colorScheme.primary }}>
        {icon}
      </div>
      {loading && <Absolute> <ClipLoader color={colorScheme.primary} /> </Absolute>}
      <Row style={{ opacity: loading ? 0 : 1 }}>
        {displayDelete && <h3 style={{ opacity: deleted > 0 ? 1 : 0.2 }}>-{deleted}</h3>}
        <h1 style={{ marginLeft: 10, marginRight: 10 }}>{total}</h1>
        {displayCreate && <h3 style={{ opacity: created > 0 ? 1 : 0.2 }}>+{created}</h3>}
      </Row>
      <p style={{ margin: 0 }}>{label}</p>
    </Content>
  )
}

export default Base

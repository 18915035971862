import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { Category } from 'together-types'
import Label from './Label'
import { ClipLoader } from 'react-spinners'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export interface CategorySelectProps {
  value: string
  categories: Category[]
  onCategory: (user: Category | undefined) => void
  label?: string
}

const CategorySelect: React.FC<CategorySelectProps> = ({ value, categories, onCategory, label }) => {
  const options = categories.map((category) => (
    {
      value: category.id,
      label: category.title
    }
  )).sort((a, b) => a.label.localeCompare(b.label))

  options.push({
    value: '',
    label: 'None'
  })

  const getOriginalCategory = (id: string | undefined): Category => {
    if (id === undefined) return categories[0]
    return categories.find((category) => category.id === id) as Category
  }

  const validValue = ((value == null || value === '') && options.length > 0) ? options[options.length - 1].value : value
  const selected = options.find((category) => category.value === (validValue))

  if (categories.length === 0) return <ClipLoader />

  if (selected === undefined) {
    alert('The category for this session has been deleted. It has been replaced with the first category in the list.')
    onCategory(categories[0])
    return <p>Incorrect value passed to select.</p>
  }

  return (
    <Column>
      {label != null && <Label>{label}</Label>}
      <Select
        value={selected}
        styles={{
          control: (provided) => ({
            ...provided
          })
        }}
        options={options}
        onChange={(c) => onCategory(getOriginalCategory(c?.value))}
      />
    </Column>
  )
}

export default CategorySelect

import { serverTimestamp } from 'firebase/firestore'
import { CreateInput, UserRecord } from '../admin-types'
import { v4 as uuid } from 'uuid'

export const createEmpyUserRecord = (): CreateInput<UserRecord> => {
  return {
    agoraUid: 0,
    bio: '',
    createdAt: serverTimestamp(),
    email: '',
    firstName: '',
    id: uuid(),
    lastName: '',
    lastSeen: serverTimestamp(),
    notificationsEnabled: false,
    queryName: '',
    role: 'user',
    status: 'offline'
  }
}

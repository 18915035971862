import React from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { ClipLoader } from 'react-spinners'

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.discrete ? '0 10px' : '0 20px'};
  height: ${props => props.discrete ? '30px' : '38px'};
  border-radius: ${props => props.discrete ? '20px' : '5px'};
  cursor: pointer;
  transition: 0.2s;
  font-weight: ${props => props.discrete ? 'normal' : 'bold'};
  font-size: ${props => props.discrete ? '10px' : '16px'};
  color: ${(props: { colorScheme: ColorScheme, disabled: boolean, discrete: boolean }) => props.colorScheme.onPrimaryContainer};
  background-color: ${props => props.colorScheme.primaryContainer};
  border-color: ${props => props.colorScheme.primaryContainer};
  

  ${props => props.disabled ? 'cursor: default; opacity: 0.6;' : ''}

  :hover {
    opacity: 0.6;
  }

`

export interface ButtonProps {
  children: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
  loading?: boolean
  disabled?: boolean
  discrete?: boolean
  showPlus?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, onClick, style, loading = false, disabled = false, discrete = false, showPlus = false }) => {
  const colorScheme = useColorScheme()
  return (
    <StyledButton disabled={disabled} onClick={(e) => !disabled && onClick(e)} colorScheme={colorScheme} style={style} discrete={discrete}>
      {!loading && children}
      {!loading && showPlus && <span style={{ marginLeft: 5 }}>+</span>}
      {loading && <ClipLoader size={20} color={colorScheme.onPrimaryContainer} />}
    </StyledButton>
  )
}

export default Button

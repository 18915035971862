import React, { useRef } from 'react'
import styled from 'styled-components'
import Label from './Label'
import Button from './Button'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledInput = styled.textarea`
  padding: 10px;
  height: 76px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
  resize: vertical;
`

const keywords = [
  {
    label: 'Speaker',
    value: '$SPEAKER'
  },
  {
    label: 'Self',
    value: '$SELF'
  },
  {
    label: 'Listeners',
    value: '$LISTENERS'
  }
]

const Counter = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: grey;
  font-size: 12px;
`

export interface InputProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
  label?: string
  showKeywordButtons?: boolean
  maxLength?: number
}

const TextArea: React.FC<InputProps> = ({ value, placeholder, onChange, style, inputStyle, label, showKeywordButtons = false, maxLength }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    onChange(event.target.value)
  }

  const typeText = (text: string): void => {
    const cursorPosition = inputRef.current?.selectionStart ?? 0
    onChange(value.slice(0, cursorPosition) + text + value.slice(cursorPosition))
  }

  return (
    <Column style={style}>
      {label != null && <Label>{label}</Label>}
      <StyledInput
        ref={inputRef}
        onKeyDown={(event) => event.stopPropagation()}
        onFocus={e => e.stopPropagation()}
        style={inputStyle} value={value}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
      />

      {maxLength != null && (
        <Counter>{value.length}/{maxLength}</Counter>
      )}

      {showKeywordButtons && (
        <Row>
          {keywords.map((keyword, index) => (
            <Button style={{ marginRight: 5 }} key={index} onClick={() => typeText(keyword.value)} discrete showPlus>{keyword.label}</Button>
          ))}
        </Row>
      )}

    </Column>
  )
}

export default TextArea

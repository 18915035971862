import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import styled from 'styled-components'
import Rearrangeable from './Rearrangeable'
import CardThumbnail from './CardThumbnail'
import { ClipLoader } from 'react-spinners'
import FusePicker from './FusePicker'
import { query, where } from 'firebase/firestore'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`

export interface CreatorPickerProps {
  value: string[]
  onChange: (value: string[]) => void
}

const CreatorPicker: React.FC<CreatorPickerProps> = ({ value, onChange }) => {
  const [profiles, loading] = useCollectionData(query(collections.users, where('role', '==', 'creator')))

  const fuseData = React.useMemo(() => {
    return profiles?.map((profile) => ({
      title: profile.firstName + ' ' + profile.lastName,
      id: profile.id
    }))
  }, [profiles])

  return (
    <Container>
      {loading && <ClipLoader />}

      <Grid>
        {!loading && value.length > 0 && (
          <Rearrangeable onRearrange={onChange} keys={value}>
            {value.map((id) => {
              const profile = profiles?.find((doc) => doc.id === id)
              if (profile == null) return null
              const imageURL = profile.avatar?.downloadURL1080x1080 ?? ''
              return (
                <div key={id}>
                  <CardThumbnail
                    style={{
                      backgroundImage: `url(${imageURL})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                    onRemove={() => {
                      onChange(value.filter((sessionID) => sessionID !== id))
                    }}
                  >
                    <p>
                      {profile?.firstName} {profile?.lastName}
                    </p>
                  </CardThumbnail>
                </div>
              )
            }).filter((x) => x != null)}
          </Rearrangeable>
        )}
      </Grid>
      <FusePicker
        data={fuseData}
        onChange={onChange}
        value={value}
        placeholder='Search creators'
      />
    </Container>
  )
}

export default CreatorPicker

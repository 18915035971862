import React, { useEffect } from 'react'
import Card from '../layout/Card'
import Title from '../components/Title'
import { VStack } from 'react-stacked'
import OpenAI from 'openai'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import { DataGrid } from '@mui/x-data-grid'
import Label from '../components/Label'
import Button from '../components/Button'
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import prettyBytes from 'pretty-bytes'

const functions = getFunctions()
const storage = getStorage()

const AiFiles: React.FC = () => {
  const [files, setFiles] = React.useState<OpenAI.Files.FileObject[]>([])
  const [loading, setLoading] = React.useState(true)
  const [loadingDelete, setLoadingDelete] = React.useState<string | null>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [loadingUpload, setLoadingUpload] = React.useState(false)

  const getFiles = async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const files = await httpsCallable(functions, 'getAiFiles')()
    setFiles(files.data as OpenAI.Files.FileObject[])
    setLoading(false)
  }

  useEffect(() => {
    getFiles().catch(e => {
      console.error(e)
      alert(e.message)
      setLoading(false)
    })
  }, [])

  const uploadToTemp = async (): Promise<void> => {
    return await new Promise((resolve, reject) => {
      // upload to firebase storage ./temp/
      const file = inputRef.current?.files?.[0]
      if (file == null) return
      const formData = new FormData()
      formData.append('file', file)
      const storageRef = ref(storage, 'temp/' + file.name)
      const task = uploadBytesResumable(storageRef, file)

      task.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + String(progress) + '% done')
          if (snapshot.bytesTransferred === snapshot.totalBytes) {
            resolve()
          }
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  const runMoveFile = async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const fileName = inputRef.current?.files?.[0]?.name
    await httpsCallable(functions, 'uploadAiFile')({ fileName, purpose: 'assistants' })
    await getFiles()
  }

  const uploadFile = async (): Promise<void> => {
    setLoadingUpload(true)
    await uploadToTemp()
    await runMoveFile()
    setLoadingUpload(false)
    if (inputRef.current != null) {
      inputRef.current.value = ''
    }
  }

  const deleteFile = async (fileID: string): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    setLoadingDelete(fileID)
    await httpsCallable(functions, 'deleteAiFile')({ fileID })
    await getFiles()
    setLoadingDelete(null)
  }

  return (
    <Card>
      <VStack gap={10}>
        <Title>Ai Files</Title>
        <Label>Upload a file to use in your AI models</Label>
        <input ref={inputRef} type='file' />
        <Button
          onClick={() => {
            if (inputRef.current == null || inputRef.current.files == null || inputRef.current.files.length === 0) return
            uploadFile().catch(e => {
              console.error(e)
              alert(e.message)
            })
          }}
          loading={loadingUpload}
        >
          Upload file
        </Button>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'filename', headerName: 'Filename', flex: 1 },
            { field: 'purpose', headerName: 'Purpose', flex: 1 },
            { field: 'created_at', headerName: 'Created at', flex: 1, valueFormatter: (e) => new Date((e.value as number) * 1000).toLocaleString() },
            { field: 'bytes', headerName: 'Bytes', flex: 1, valueFormatter: (e) => prettyBytes(e.value as number) },
            {
              field: 'delete',
              headerName: 'Delete',
              width: 110,
              renderCell: (e) => (
                <Button
                  loading={loadingDelete === e.row.id}
                  onClick={() => {
                    deleteFile(e.row.id).catch(e => {
                      console.error(e)
                      alert(e.message)
                      setLoadingUpload(false)
                    })
                  }}
                >Delete
                </Button>
              )
            }
          ]}
          rows={files}
          loading={loading}
          autoHeight
        />
      </VStack>
    </Card>
  )
}

export default AiFiles

import React from 'react'
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore'
import styled from 'styled-components'
import collections from '../lib/collections'
import Button from './Button'
import CategorySelect from './CategorySelect'
import Input from './Input'
import UserSelect from './UserSelect'

import VideoSelect from './VideoSelect'
import Title from './Title'
import TextArea from './TextArea'
import { CreateInput, Session } from '../admin-types'
import ArrayInput from './ArrayInput'
import BooleanInput from './BooleanInput'
import Label from './Label'
import ImageSelect from './ImageSelect'
import Agenda from './agenda'
import TitleDescriptionArrayInput from './TitleDescriptionArrayInput'
import CourseTag from './CourseTag'
import useIsAdmin from '../hooks/useIsAdmin'
import DuplicateCourseTemplateButton from './DuplicateCourseTemplateButton'
import SessionToCourseSession from './migration/SessionToCourseSession'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Inputs = styled.div`
  flex: 1;
  margin-right: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`

type SessionType = Session | CreateInput<Session>

const validateAgenda = (agenda: SessionType['agenda']): boolean => {
  let isValid = true
  agenda.forEach((event) => {
    if (event.description === '' && event.videoID == null) isValid = false
    if (event.durationType === 'duration' && event.durationSeconds < 1) isValid = false
  })
  return isValid
}

export interface EditSessionProps {
  session: SessionType
  onSession: (session: Partial<SessionType>) => void
  onSave: () => void
  onDelete?: () => void
  onCancel: () => void
  loadingSave?: boolean
  loadingDelete?: boolean
  editingExisting?: boolean
  courseID?: string
}

const EditSession: React.FC<EditSessionProps> = ({ session, onSession, onSave, loadingSave, loadingDelete, editingExisting, onDelete, onCancel, courseID }) => {
  const [userDocs, , errorUsers] = useCollection(collections.users)
  const [categoryDocs, , errorCategories] = useCollection(collections.categories)
  const users = userDocs?.docs.map((doc) => doc.data()) ?? []
  const categories = categoryDocs?.docs.map((doc) => doc.data()) ?? []
  const [videos, , errorVideos] = useCollectionData(collections.videos)
  const isAdmin = useIsAdmin()
  const author = users.find((u) => u.id === session.authoredBy)

  if (errorUsers != null) return <p>Error loading users.</p>
  if (errorVideos != null) return <p>Error loading videos.</p>
  if (errorCategories != null) return <p>Error loading categories.</p>

  const validate = (): boolean => {
    if (session.title === '') return false
    if (session.subtitle === '') return false
    if (session.description === '') return false
    if (session.categoryID === '' || session.categoryID == null) return false
    if (session.agenda.length === 0) return false
    if (session.maxNumberOfParticipants < 1) return false

    return validateAgenda(session.agenda)
  }

  return (
    <Container>
      <Row>
        <Row>
          <Inputs>
            <Title>{editingExisting === true ? 'Edit ' + session.title : 'Create new template'}</Title>
            {courseID != null && <CourseTag courseID={courseID} />}
            {window.location.hostname === 'localhost' && <p>Session ID: {session.id}</p>}
            <a target='_blank' href={`https://get-together.app/SessionTemplate/${session.id}`} rel='noreferrer'>
              {`get-together.app/SessionInfo/${session.id}`}
            </a>
            <Input label='Title' onChange={(text) => onSession({ title: text })} placeholder='Title...' value={session.title} />
            <Input label='Pitch' onChange={(text) => onSession({ subtitle: text })} placeholder='Pitch...' value={session.subtitle} />
            <TextArea label='Description' onChange={(text) => onSession({ description: text })} placeholder='Description...' value={session.description} />
            <ArrayInput label='Intro' onChange={(texts) => onSession({ intro: texts })} placeholder='Intro text bubble...' value={session.intro} />
            <VideoSelect label='Video' value={session.videoID} videos={videos ?? []} onVideo={(v) => onSession({ videoID: v?.id })} />
            {isAdmin && <UserSelect label='Authored by' value={session.authoredBy} onUser={(user) => onSession({ authoredBy: user?.id })} users={users} isMulti={false} allowNone />}
            {!isAdmin && (
              <>
                <Label>Authored by</Label>
                <p>{author?.firstName} {author?.lastName}</p>
              </>
            )}
            <CategorySelect label='Category' value={session.categoryID} onCategory={(category => onSession({ categoryID: category == null ? undefined : category.id }))} categories={categories} />
            <BooleanInput label='Visibility' value={session.visible === true} onValue={(visibilty) => onSession({ visible: visibilty })} labels={['Visible', 'Hidden']} />
            <Input label='Max number of participants' value={session.maxNumberOfParticipants.toString()} onChange={(n) => onSession({ maxNumberOfParticipants: parseInt(n) })} type='number' />
            <TitleDescriptionArrayInput value={session.additionalInfo ?? []} onChange={(e) => onSession({ additionalInfo: e })} label='Additional info' descriptionPlaceholder='Description...' titlePlaceholder='Title...' />
          </Inputs>
        </Row>
        <Row style={{ flex: 0 }}>
          <Inputs>
            <Label>Image</Label>
            <ImageSelect image={session.image} onUploadComplete={(image) => onSession({ image })} />
            {courseID != null && <DuplicateCourseTemplateButton courseTemplateID={courseID} sessionTemplateID={session.id} />}
            {courseID == null && <SessionToCourseSession sessionTemplateID={session.id} />}
            <div style={{ flex: 1, display: 'flex', flexGrow: 1 }} />

          </Inputs>
        </Row>
      </Row>
      <Agenda agenda={session.agenda} onAgenda={(agenda) => onSession({ agenda })} />
      <Row>
        <Button onClick={onSave} loading={loadingSave} disabled={!validate()}>Save</Button>
        <Button onClick={onCancel} style={{ marginLeft: 10 }}>Cancel</Button>
        {(onDelete != null) && editingExisting === true && <Button onClick={onDelete} style={{ marginLeft: 10 }} loading={loadingDelete}>Delete</Button>}
      </Row>
    </Container>
  )
}

export default EditSession

import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import Button from '../components/Button'
import Card from '../layout/Card'
import VideoInfo from '../components/VideoInfo'
import Popup from 'react-animated-popup'
import { useCollection } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import { getFunctions } from 'firebase/functions'
import { firebaseApp } from '../firebase/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { Video } from '../admin-types'
import TranscodeStatus from '../components/TranscodeStatus'
import { CheckCircleFilled } from '@ant-design/icons'

const functions = getFunctions(firebaseApp)
functions.region = 'europe-west1'

const Videos: React.FC = () => {
  const [videoDocs, loading, error] = useCollection(collections.videos)
  const videos = videoDocs?.docs.map((doc) => doc.data()) ?? []

  const [currentVideo, setCurrentVideo] = useState<string | null>(null)

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1, editable: true },
    { field: 'originalSize', headerName: 'Original Size', flex: 1, valueFormatter: (p) => `${Math.round(p.value / 100000) / 10} mb` },
    { field: 'hdSize', headerName: 'HD Size', flex: 1, valueFormatter: (p) => `${Math.round(p.value / 100000) / 10} mb` },
    { field: 'sdSize', headerName: 'SD Size', flex: 1, valueFormatter: (p) => `${Math.round(p.value / 100000) / 10} mb` },
    { field: 'duration', headerName: 'Duration', flex: 1, valueFormatter: (p) => `${Number(p.value)}s` },
    {
      field: 'bPerSecond',
      headerName: 'mb/s',
      flex: 1,
      valueFormatter: (p) => {
        return `${Math.round(p.value / 10000) / 100} mb/s`
      }
    },
    { field: 'createdAt', headerName: 'Created At', flex: 1, valueFormatter: (p) => new Date(p.value.seconds * 1000).toLocaleString() },
    {
      field: 'processed',
      headerName: 'Processed',
      flex: 1,
      renderCell: (params) => {
        const processed: boolean = params.row.processed
        return (
          <div>
            {!processed && <ClipLoader />}
            {processed && 'Yes'}
          </div>
        )
      }
    },
    {
      field: 'includedInTemplates',
      headerName: 'Included in templates',
      flex: 1,
      renderCell: (params) => {
        const includedInTemplates: string[] = params.row.includedInTemplates
        return (
          <div>
            {includedInTemplates.length}
          </div>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Transcode status',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const video = params.row as Video
        if (video.jobID == null) return (<></>)
        if (video.processed) return (<CheckCircleFilled />)
        return (
          <TranscodeStatus jobID={video.jobID} />
        )
      }
    },
    {
      field: '',
      headerName: 'Info',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const video: string = params.row.id
        if (params.row.processed === false) return (<></>)
        return (
          <Button onClick={() => {
            setCurrentVideo(video)
          }}
          >Info
          </Button>
        )
      }
    }
  ]

  if (error != null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  if (loading) {
    return <Card center><ClipLoader /></Card>
  }

  return (
    <>
      <Popup visible={currentVideo != null} onClose={() => setCurrentVideo(null)} style={{ maxWidth: '90vw' }}>
        {currentVideo != null && <VideoInfo filepath={'videos/' + currentVideo} onDone={() => setCurrentVideo(null)} />}
      </Popup>

      <Card>
        <DataGrid
          loading={loading}
          autoHeight
          rows={videos.map(v => ({ ...v, bPerSecond: (v.hdSize == null ? 0 : v.hdSize) / v.duration }))}
          columns={columns}
          onCellEditCommit={(params) => {
            if (params.field === 'name' && params.value != null) {
              const id = params.id as string
              if (id == null) return console.error('id is null')
              const ref = doc(collections.videos, id)
              updateDoc(ref, { name: params.value }).catch(console.error)
            }
          }}
          disableSelectionOnClick
        />
      </Card>
    </>
  )
}

export default Videos

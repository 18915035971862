import React from 'react'
import styled from 'styled-components'
import { CourseTemplate, CreateInput } from '../admin-types'
import Title from './Title'
import Input from './Input'
import TitleDescriptionArrayInput from './TitleDescriptionArrayInput'
import Label from './Label'
import ImageSelect from './ImageSelect'
import UserSelect from './UserSelect'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import Button from './Button'
import BooleanInput from './BooleanInput'
import useIsAdmin from '../hooks/useIsAdmin'
import { doc } from 'firebase/firestore'
import ColorInput from './ColorInput'
import CourseSessionsPreview from './CourseSessionsPreview'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Inputs = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`

type CourseTemplateType = CourseTemplate | CreateInput<CourseTemplate>

export interface EditCourseProps {
  course: CourseTemplateType
  onCourse: (course: Partial<CourseTemplateType>) => void
  onSave: () => void
  onDelete?: () => void
  onCancel: () => void
  loadingSave?: boolean
  loadingDelete?: boolean
  editingExisting?: boolean
}

const EditCourse: React.FC<EditCourseProps> = (
  {
    course,
    onCourse,
    onSave,
    loadingSave,
    loadingDelete,
    editingExisting,
    onDelete,
    onCancel
  }) => {
  const [users, , errorUsers] = useCollectionData(collections.users)
  const isAdmin = useIsAdmin()

  const [author] = useDocumentData(doc(collections.users, course.authoredBy ?? ''))

  if (errorUsers != null) {
    console.error(errorUsers)
    alert(errorUsers)
  }

  const validate = (): boolean => {
    if (course.title === '') return false
    if (course.subtitle === '') return false
    if (course.authoredBy == null) return false
    return true
  }

  return (
    <Container>
      <Row>
        <Inputs>
          <Title>{editingExisting === true ? `Edit ${course.title}` : 'Create new course'}</Title>
          {window.location.hostname === 'localhost' && <p>Session ID: {course.id}</p>}
          <a target='_blank' href={`https://get-together.app/CourseTemplate/${course.id}`} rel='noreferrer'>
            {`get-together.app/CourseTemplate/${course.id}`}
          </a>
          <Input label='Title' onChange={(text) => onCourse({ title: text })} placeholder='Title...' value={course.title} />
          <Input label='Pitch' onChange={(text) => onCourse({ subtitle: text })} placeholder='Pitch...' value={course.subtitle} />
          {isAdmin && <UserSelect label='Authored by' value={course.authoredBy} onUser={(user) => onCourse({ authoredBy: user?.id })} users={users ?? []} isMulti={false} allowNone />}
          {!isAdmin && (
            <>
              <Label>Authored by</Label>
              <p>{author?.firstName} {author?.lastName}</p>
            </>
          )}
          <TitleDescriptionArrayInput value={course.additionalInfo ?? []} onChange={(e) => onCourse({ additionalInfo: e })} label='Additional info' descriptionPlaceholder='Description...' titlePlaceholder='Title...' />
          <BooleanInput label='Visibility' value={course.visible} onValue={(visibilty) => onCourse({ visible: visibilty })} labels={['Visible', 'Hidden']} />
          <ColorInput value={course.color} onChange={(c) => onCourse({ color: c })} />
          <ColorInput value={course.accentColor} onChange={(c) => onCourse({ accentColor: c })} label='Accent color' />
        </Inputs>
        <Row style={{ flex: 0 }}>
          <Inputs>
            <Label>Image</Label>
            <ImageSelect image={course.image} onUploadComplete={(image) => onCourse({ image })} />
            {editingExisting === true && <CourseSessionsPreview courseTemplateID={course.id} />}
            <div style={{ flex: 1, display: 'flex', flexGrow: 1 }} />
          </Inputs>
        </Row>
      </Row>
      <Row>
        <Button onClick={onSave} loading={loadingSave} disabled={!validate()}>Save</Button>
        <Button onClick={onCancel} style={{ marginLeft: 10 }}>Cancel</Button>
        {(onDelete != null) && editingExisting === true && <Button onClick={onDelete} style={{ marginLeft: 10 }} loading={loadingDelete}>Delete</Button>}
      </Row>
    </Container>
  )
}

export default EditCourse

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DiscoverCategory } from 'together-types'
import Input from './Input'
import Button from './Button'
import { deleteDoc, doc, setDoc } from 'firebase/firestore'
import collections from '../lib/collections'
import Select from 'react-select'
import Label from './Label'
import SessionTemplatePicker from './SessionTemplatePicker'
import BooleanInput from './BooleanInput'
import { HStack, VStack } from 'react-stacked'
import { useNavigate } from 'react-router-dom'
import CreatorPicker from './CreatorPicker'
import CourseTemplatePicker from './CourseTemplatePicker'

const EventContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const keyToLabel = (key: string): string => {
  return key.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

export interface EditDiscoverCategoryProps {
  value: DiscoverCategory
}

const EditDiscoverCategory: React.FC<EditDiscoverCategoryProps> = ({ value }) => {
  const [discoverCategory, setDiscoverCategory] = useState<DiscoverCategory>(value)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const save = (): void => {
    setLoading(true)
    setDoc(doc(collections.discoverCategories, discoverCategory.id), discoverCategory).catch((error) => {
      console.error('Error writing document: ', error)
      alert('Error saving discover category')
    }).finally(() => setLoading(false))
  }

  const remove = (): void => {
    // confirm
    if (!window.confirm(`Are you sure you want to delete ${discoverCategory.title}?`)) {
      return
    }

    setLoading(true)
    deleteDoc(doc(collections.discoverCategories, discoverCategory.id)).then(() => {
      navigate('/discover')
    }).catch((error) => {
      console.error('Error writing document: ', error)
      alert('Error deleting discover category')
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    if (discoverCategory.type === 'curated') {
      return
    }
    if (discoverCategory.sessions?.length === 0) return
    setDiscoverCategory({ ...discoverCategory, sessions: [] })
  }, [discoverCategory])

  const toggleContentType = (type: DiscoverCategory['contentType']): void => {
    switch (type) {
      case 'sessions':
        setDiscoverCategory({
          ...discoverCategory,
          contentType: 'sessions',
          courses: [],
          profiles: []
        })
        break
      case 'profiles':
        setDiscoverCategory({
          ...discoverCategory,
          contentType: 'profiles',
          courses: [],
          sessions: [],
          thumbnailType: 'default',
          type: 'curated'
        })
        break
      case 'courses':
        setDiscoverCategory({
          ...discoverCategory,
          contentType: 'courses',
          profiles: [],
          sessions: [],
          thumbnailType: 'default',
          type: 'curated'
        })
        break
    }
  }

  return (
    <EventContainer>
      <Label>Title</Label>
      <Input value={discoverCategory.title} onChange={(title) => setDiscoverCategory({ ...discoverCategory, title })} placeholder='Title...' />

      {discoverCategory.type === 'curated' && discoverCategory.contentType === 'sessions' && (
        <>
          <Label>Sessions</Label>
          <SessionTemplatePicker
            value={discoverCategory.sessions ?? []}
            onChange={(sessions) => setDiscoverCategory({
              ...discoverCategory,
              sessions
            })}
          />
        </>
      )}

      {discoverCategory.type === 'curated' && discoverCategory.contentType === 'profiles' && (
        <>
          <Label>Profiles</Label>
          <CreatorPicker
            value={discoverCategory.profiles ?? []}
            onChange={(profiles) => setDiscoverCategory({
              ...discoverCategory,
              profiles
            })}
          />
        </>
      )}

      {discoverCategory.type === 'curated' && discoverCategory.contentType === 'courses' && (
        <>
          <Label>Courses</Label>
          <CourseTemplatePicker
            value={discoverCategory.courses ?? []}
            onChange={(courses) => setDiscoverCategory({
              ...discoverCategory,
              courses
            })}
          />
        </>
      )}

      <HStack gap={10}>
        <BooleanInput grow={1} label='Visibility' value={discoverCategory.visible} onValue={(visibilty) => setDiscoverCategory({ ...discoverCategory, visible: visibilty })} labels={['Visible', 'Hidden']} />

        {/* Select for thumbnail type 'video' or 'default' */}
        <VStack gap={10} grow={1}>
          <Label>Thumbnail type</Label>
          <Select
            value={{ value: discoverCategory.thumbnailType, label: keyToLabel(discoverCategory.thumbnailType) }}
            onChange={(option) => {
              if (option == null) {
                return
              }
              setDiscoverCategory({ ...discoverCategory, thumbnailType: option.value })
            }}
            options={[
              { value: 'video', label: 'Video' },
              { value: 'default', label: 'Default' }
            ]}
          />
        </VStack>

        <VStack gap={10} grow={1}>
          <Label>Selection type</Label>
          <Select
            value={{ value: discoverCategory.type, label: keyToLabel(discoverCategory.type) }}
            onChange={(option) => {
              if (option == null) {
                return
              }
              console.log(option.value)
              setDiscoverCategory({ ...discoverCategory, type: option.value })
            }}
            options={[
              { value: 'curated', label: 'Curated' },
              { value: 'latest', label: 'Latest' }
            ]}
          />
        </VStack>

        <VStack gap={10} grow={1}>
          <Label>Content type</Label>
          <Select
            value={{ value: discoverCategory.contentType, label: keyToLabel(discoverCategory.contentType) }}
            onChange={(option) => {
              if (option == null) {
                return
              }
              toggleContentType(option.value)
            }}
            options={[
              { value: 'sessions', label: 'Sessions' },
              { value: 'profiles', label: 'Profiles' },
              { value: 'courses', label: 'Courses' }
            ]}
          />
        </VStack>
      </HStack>

      <Row>
        <div style={{ flex: 1 }} />
        <Button onClick={remove} style={{ marginRight: 10 }}>Delete</Button>
        <Button loading={loading} onClick={save}>Save</Button>
      </Row>
    </EventContainer>
  )
}

export default EditDiscoverCategory

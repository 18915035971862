import React from 'react'
import styled from 'styled-components'
import ImageSelect from '../ImageSelect'
import { UserRecord } from '../../admin-types'
import { doc, updateDoc } from 'firebase/firestore'
import collections from '../../lib/collections'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import Label from '../Label'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export interface AvatarInputProps {
  userID: string
}

const AvatarInput: React.FC<AvatarInputProps> = ({ userID }) => {
  const [user] = useDocumentData<UserRecord>(doc(collections.users, userID))

  const onUploadAvatarComplete = (image: UserRecord['avatar']): void => {
    if (image != null) {
      const ref = doc(collections.users, userID)
      updateDoc(ref, { avatar: image }).catch((error) => {
        console.error(error)
        alert('Error updating user')
      })
    }
  }

  return (
    <Container>
      <Label>Avatar</Label>
      <ImageSelect onUploadAvatarComplete={onUploadAvatarComplete} avatarID={userID} image={user?.avatar} />

      {user?.avatar != null && (
        <>
          <a href={user?.avatar?.downloadURL200x200} target='_blank' rel='noreferrer'>200x200</a>
          <a href={user?.avatar?.downloadURL1080x1080} target='_blank' rel='noreferrer'>1080x1080</a>
        </>
      )}
    </Container>
  )
}

export default AvatarInput

import React, { useMemo, useState } from 'react'
import Card from '../layout/Card'
import Title from '../components/Title'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import collections from '../lib/collections'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Course } from '../admin-types'
import Avatar from '../components/Avatar'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { query, where } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase/firebase'
import { format } from 'date-fns'

const CourseTemplates: React.FC<{ myCourses?: boolean }> = ({ myCourses = false }) => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const coursesRef = myCourses ? query(collections.courseTemplates, where('authoredBy', '==', user?.uid ?? '')) : collections.courseTemplates
  const [courses, loadingCourses, errorCourses] = useCollectionData(coursesRef)
  const [sortModel, setSortModel] = useState<GridSortModel>([{
    field: 'createdAt',
    sort: 'desc'
  }])

  const columns: GridColDef[] = useMemo(() => [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'numberOfTemplates', headerName: 'Template count (visible)', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 140,
      valueGetter: (params) => {
        const seconds = params.value.seconds
        if (seconds == null) return null
        return seconds * 1000
      },
      renderCell: (params: any) => {
        if (params.value == null) return null
        const date = new Date(params.value)
        return format(date, 'dd / MM / yyyy HH:mm')
      }
    },
    {
      field: 'authoredBy',
      headerName: 'Author',
      width: 90,
      renderCell: (params) => {
        // const user = users?.find((user) => user.id === params.value)
        return (
          <Avatar userID={params.value} />
        )
      }
    },
    {
      field: 'visible',
      headerName: 'Visibility',
      width: 70,
      renderCell: (params) => {
        const visibility = params.value
        if (visibility === true) return <EyeOutlined />
        return <EyeInvisibleOutlined />
      }
    },
    {
      field: '',
      headerName: 'Edit',
      width: 100,
      renderCell: ({ row }: { row: Course }) => {
        return (
          <Button onClick={(e) => {
            e.stopPropagation()
            navigate(`/course-templates/${row.id}`)
          }}
          >Edit
          </Button>
        )
      }
    }
  ], [navigate])

  if (errorCourses != null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <Card style={{ gap: 10 }}>
      <Title>Templates</Title>
      <Button onClick={() => navigate('/course-templates/new')}>Create Course</Button>
      <DataGrid
        autoHeight
        rows={courses ?? []}
        columns={columns}
        sortingOrder={['desc', 'asc']}
        onSortModelChange={setSortModel}
        sortModel={sortModel}
        loading={loadingCourses}
      />
    </Card>
  )
}

export default CourseTemplates

import React from 'react'
import Base from '.'
import { TeamOutlined } from '@ant-design/icons'
import { useDocument } from 'react-firebase-hooks/firestore'
import collections from '../../../lib/collections'
import { doc } from 'firebase/firestore'

const UsersWidget: React.FC = () => {
  const today = new Date()
  const todayDateString = today.toISOString().split('T')[0]
  const [todayDoc, ,] = useDocument(doc(collections.statistics, 'users', 'count', todayDateString))
  const todayCount = todayDoc?.data()

  const loading = todayCount?.total == null

  return (
    <Base label='Users' value={todayCount} loading={loading} icon={<TeamOutlined style={{ fontSize: 30 }} />} />
  )
}

export default UsersWidget

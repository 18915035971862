import React from 'react'
import styled from 'styled-components'
import { UserRecord } from '../../admin-types'
import Input from '../Input'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export interface LinksInputProps {
  links: UserRecord['links']
  onLinks: (links: UserRecord['links']) => void
}

const LinksInput: React.FC<LinksInputProps> = ({ links, onLinks }) => {
  return (
    <Container>
      <Input
        label='Website'
        value={links?.website ?? ''}
        onChange={(val) => onLinks({ ...links, website: val })}
      />
      <Input
        label='X'
        value={links?.x ?? ''}
        onChange={(val) => onLinks({ ...links, x: val })}
      />
      <Input
        label='LinkedIn'
        value={links?.linkedIn ?? ''}
        onChange={(val) => onLinks({ ...links, linkedIn: val })}
      />
      <Input
        label='Instagram'
        value={links?.instagram ?? ''}
        onChange={(val) => onLinks({ ...links, instagram: val })}
      />
    </Container>
  )
}

export default LinksInput

import { getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { collection, CollectionReference, doc, DocumentReference, getCountFromServer, getDoc, getFirestore, Query } from 'firebase/firestore'
import { useEffect, useState } from 'react'

const firebaseConfig = {
  apiKey: 'AIzaSyDynFMmNWq_noiNiYJNwEZK5CvV0SlLZyE',
  authDomain: 'presence-4a3f8.firebaseapp.com',
  projectId: 'presence-4a3f8',
  storageBucket: 'presence-4a3f8.appspot.com',
  messagingSenderId: '105132526292',
  appId: '1:105132526292:web:bdac043fca7ec61a55c941'
}

export const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth(firebaseApp)

export const userHasNotificationsEnabled = async (userID: string): Promise<boolean> => {
  const notificationRef = doc(collection(doc(collection(getFirestore(firebaseApp), 'users'), userID), 'private'), 'notifications') as DocumentReference<{ token: string }>
  const user = await (await getDoc(notificationRef)).data()

  if (user?.token != null) {
    return true
  }
  return false
}

export const useCount = (collectionRef: CollectionReference | Query): [number, boolean] => {
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCountFromServer(collectionRef).then((count) => {
      setCount(count.data().count)
      setLoading(false)
    }).catch((err) => console.error(err))
  }, [collectionRef])

  return [count, loading]
}

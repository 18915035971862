import React from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import styled from 'styled-components'
import collections from '../lib/collections'
import { doc } from 'firebase/firestore'
import { ClipLoader } from 'react-spinners'
import { Color } from 'together-types'

const Badge = styled.div<{ color: Color }>`
  border-radius: 50px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  background-color: white;
`

export interface CategoryBadgeProps {
  categoryID: string
}

const CategoryBadge: React.FC<CategoryBadgeProps> = ({ categoryID }) => {
  const [category, loading, error] = useDocumentData(doc(collections.categories, categoryID))

  if (error != null) {
    return (
      <div>
        Error
      </div>
    )
  }

  if (loading || category == null) {
    return (
      <ClipLoader />
    )
  }

  return (
    <Badge color={category.color}>
      {category.title}
    </Badge>
  )
}

export default CategoryBadge

import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../layout/Card'
import Label from '../components/Label'
import Loading from './Loading'
import BooleanInput from '../components/BooleanInput'
import { CreateInput, UserRecord } from '../admin-types'
import LinksInput from '../components/profile/LinksInput'
import Button from '../components/Button'
import Input from '../components/Input'
import AvatarInput from '../components/profile/AvatarInput'
import { createEmpyUserRecord } from '../lib/users'

const User: React.FC = () => {
  const { id } = useParams()
  const [user, loading, error] = useDocumentData(doc(collections.users, id))
  const [lastInvited, loadingLastInvited, errorLastInvited] = useCollectionData(collection(doc(collections.users, id), 'lastInvited'))
  const [saving, setSaving] = React.useState(false)
  const [editedProfile, setEditedProfile] = React.useState<UserRecord | CreateInput<UserRecord> | undefined>(createEmpyUserRecord())
  const navigate = useNavigate()

  useEffect(() => {
    if (user == null) return
    if (id === 'new') return
    setEditedProfile(user)
  }, [user, id])

  const save = (): void => {
    if (editedProfile == null) return
    if (user == null) return
    setSaving(true)
    const ref = doc(collections.users, user.id)
    updateDoc(ref, editedProfile).catch((error) => {
      console.error(error)
      alert('Error updating user')
    }).finally(() => {
      setSaving(false)
    })
  }

  const create = (): void => {
    if (editedProfile == null) return
    setSaving(true)
    const ref = doc(collections.users, editedProfile.id)
    setDoc(ref, editedProfile).then(() => {
      navigate(`/users/${editedProfile.id}`)
    }).catch((error) => {
      console.error(error)
      alert('Error creating user')
    }).finally(() => {
      setSaving(false)
    })
  }

  if (error != null) {
    alert('Error loading user')
  }
  if (errorLastInvited != null) {
    alert('Error loading last invited')
  }

  const exists = (id != null && id !== 'new')

  if (editedProfile == null) {
    return (
      <Card>
        <Loading />
      </Card>
    )
  }

  return (
    <Card>
      <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
        {!loading && (
          <>
            <h1>{editedProfile?.firstName} {editedProfile?.lastName}</h1>

            <a href={`https://get-together.app/User/${editedProfile.id ?? ''}`} target='_blank' rel='noreferrer'>{`https://get-together.app/User/${editedProfile.id ?? ''}`}</a>

            <Label>Firstname</Label>
            <Input value={editedProfile.firstName} onChange={(val) => setEditedProfile({ ...editedProfile, firstName: val })} />

            <Label>Lastname</Label>
            <Input value={editedProfile.lastName} onChange={(val) => setEditedProfile({ ...editedProfile, lastName: val })} />

            <Label>{editedProfile.id}</Label>
            {exists && <AvatarInput userID={editedProfile.id} />}
            {!exists && (
              <>
                <Label>Avatar</Label>
                <p>Create the user to upload an avatar.</p>
              </>
            )}

            <Label>Bio</Label>
            <Input value={editedProfile.bio} onChange={(val) => setEditedProfile({ ...editedProfile, bio: val })} />

            <BooleanInput
              value={editedProfile?.role === 'creator'}
              onValue={(val) => setEditedProfile({ ...editedProfile, role: val ? 'creator' : 'user' })}
              labels={['Creator', 'User']}
              label='Role'
            />

            <LinksInput links={editedProfile.links} onLinks={(val) => setEditedProfile({ ...editedProfile, links: val })} />

            {exists && <Button onClick={save} loading={saving}>Save</Button>}
            {!exists && <Button onClick={create} loading={saving}>Create</Button>}

            <Label>Raw</Label>
            <pre>{JSON.stringify(user, null, 2)}</pre>
          </>
        )}

        {!loadingLastInvited && (
          <>
            <Label>Last invited</Label>
            <pre>{JSON.stringify(lastInvited, null, 2)}</pre>
          </>
        )}

        {(loading || loadingLastInvited) && (
          <Loading />
        )}
      </div>
    </Card>
  )
}

export default User

import React, { useCallback, useEffect } from 'react'
import Card from '../layout/Card'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import { doc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { HStack, VStack } from 'react-stacked'
import Input from '../components/Input'
import Button from '../components/Button'
import { HttpsCallableResult, connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import Title, { SubTitle } from '../components/Title'
import OpenAI from 'openai'
import { ClipLoader } from 'react-spinners'
import Select from 'react-select'
import ThreadMessage from '../components/ai/ThreadMessage'

const functions = getFunctions()

const AiThread: React.FC = () => {
  const { threadID } = useParams()
  const [thread] = useDocumentData(doc(collections.threads, threadID))
  const [loadingSend, setLoadingSend] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [assistants, setAssistants] = React.useState<OpenAI.Beta.Assistant[]>([])

  const [assistantID, setAssistantID] = React.useState<string>('')

  const [input, setInput] = React.useState<string>('')
  const [messages, setMessages] = React.useState<OpenAI.Beta.Threads.Messages.ThreadMessagesPage>()

  const sendMessage = async (message: string): Promise<void> => {
    if (thread == null) return
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    setLoadingSend(true)
    const runStatus = await httpsCallable(functions, 'askThreadQuestion')({
      threadID,
      assistantID,
      question: message
    })
    console.log(runStatus, 'RUN STATUS')
    setInput('')
    await getMessages()
    setLoadingSend(false)
  }

  const getMessages = useCallback(async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const res = await httpsCallable(functions, 'getThreadMessages')({ threadID })
    const data = res.data as OpenAI.Beta.Threads.Messages.ThreadMessagesPage
    data.data = data.data.reverse()
    setMessages(data)
    setLoading(false)
  }, [threadID])

  const getAssistants = useCallback(async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const assistants = await httpsCallable(functions, 'listAssistants')() as HttpsCallableResult<OpenAI.Beta.Assistant[]>
    if (assistants == null) throw new Error('assistants is null')
    setAssistants(assistants.data)
  }, [])

  useEffect(() => {
    if (thread?.assistantID == null) return
    setAssistantID(thread.assistantID)
  }, [thread?.assistantID])

  useEffect(() => {
    getMessages().catch((error) => {
      console.error(error)
      alert(error.message)
    })
    getAssistants().catch((error) => {
      console.error(error)
      alert(error.message)
    })
  }, [getMessages, getAssistants])

  const options: Array<{ value: string, label: string }> = React.useMemo(() => {
    return assistants?.filter(a => a.name != null).map((assistant) => ({ label: assistant.name ?? '', value: assistant.id })) ?? []
  }, [assistants])

  return (
    <Card>
      <VStack gap={10}>
        <Title>AI Thread</Title>
        <p>Thread ID: {threadID}</p>
        <p>Assistant ID: {thread?.assistantID}</p>

        <SubTitle>Messages</SubTitle>
        <VStack gap={10} justifyContent='start' alignItems='start' backgroundColor='#eee' padding={20} borderRadius={10}>
          {loading && (
            <HStack justifyContent='center'>
              <ClipLoader />
            </HStack>
          )}
          {messages?.data.map((message, i) => <ThreadMessage key={message.id} message={message} />)}
        </VStack>

        <HStack gap={10} wrap>
          {thread?.replySuggestions?.suggestions.map((suggestion, i) => {
            return (
              <Button
                key={suggestion}
                onClick={() => {
                  setInput(suggestion)
                  sendMessage(suggestion).catch((error) => {
                    console.error(error)
                    alert(error.message)
                    setLoadingSend(false)
                  })
                }}
              >
                {suggestion}
              </Button>
            )
          })}
        </HStack>

        <HStack gap={10}>
          <Input style={{ flex: 1 }} placeholder='Ask me a question' value={input} onChange={setInput} />
          <Select
            placeholder='Select assistant'
            value={options.find((option) => option.value === assistantID)}
            styles={{
              control: (provided) => ({
                ...provided,
                minWidth: 200
              })
            }}
            options={options}
            onChange={(c) => setAssistantID(c?.value ?? '')}
          />
          <Button
            onClick={() => {
              sendMessage(input).catch((error) => {
                console.error(error)
                alert(error.message)
                setLoadingSend(false)
              })
            }}
            loading={loadingSend}
          >
            Send
          </Button>
        </HStack>
      </VStack>
    </Card>
  )
}

export default AiThread

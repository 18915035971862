import React, { useEffect } from 'react'
import styled from 'styled-components'
import useDirectory from '../hooks/useDirectory'
import { getStorage, ref, getMetadata, FullMetadata } from 'firebase/storage'
import File from './File'
import VideoPlayer from './VideoPlayer'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import Button from './Button'
import { ClipLoader } from 'react-spinners'
import Title from './Title'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { firebaseApp } from '../firebase/firebase'

const functions = getFunctions(firebaseApp)
functions.region = 'europe-west1'

const storage = getStorage()

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const VideoContainer = styled.div`
  display: flex;
  aspect-ratio: 9/16;
  width: 250px;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.backgroundAlt};
  border-radius: 5px;
  overflow: hidden;
`

export interface VideoInfoProps {
  filepath: string
  onDone: () => void
}

const VideoInfo: React.FC<VideoInfoProps> = ({ filepath, onDone }) => {
  const colorScheme = useColorScheme()
  const [files, error, loading] = useDirectory(filepath)
  const [metadata, setMetadata] = React.useState<FullMetadata[]>()
  const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false)

  const fileName = filepath.split('/').pop()

  const [currentVideo, setCurrentVideo] = React.useState<FullMetadata | null>(null)

  const getMetaData = async (id: string): Promise<FullMetadata> => {
    const metadata = await getMetadata(ref(storage, id))
    return metadata
  }

  useEffect(() => {
    if (files != null) {
      const promises = files.items.map(async (item) => await getMetaData(item.fullPath))

      Promise.all(promises)
        .then((results) => {
          setMetadata(results)
          setCurrentVideo(results[0])
        }).catch((error) => {
          console.error(error)
        })
    }
  }, [files])

  const onDelete = (): void => {
    const ans = window.confirm('Are you sure you want to delete this video?')
    if (!ans) return
    if (currentVideo == null) return
    deleteVideo()
  }

  const deleteVideo = (): void => {
    setLoadingDelete(true)
    httpsCallable(functions, 'deleteVideo')({ videoID: fileName }).catch(console.error).finally(() => setLoadingDelete(false))
  }

  if (error != null) {
    return (
      <div>An error occured, please try again later.</div>
    )
  }

  if (loading || metadata == null) {
    return <ClipLoader />
  }

  if (loadingDelete) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ClipLoader />
        <p>Deleting video...</p>
      </div>
    )
  }

  return (
    <Row>
      <Column style={{ flex: 1, marginRight: 20 }}>
        <Title>{fileName} video Info</Title>
        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>Files</p>
        {metadata?.map((data) => {
          return (
            <File
              key={data.fullPath}
              file={data}
              selected={currentVideo?.fullPath === data.fullPath}
              onClick={() => setCurrentVideo(data)}
            />
          )
        })}

        <div style={{ flex: 1 }} />
        <Button onClick={onDone}>Done</Button>
        <Button style={{ marginBottom: 0, backgroundColor: colorScheme.error }} onClick={onDelete}>Delete</Button>
      </Column>
      <Column style={{ flexGrow: 0 }}>
        <VideoContainer colorScheme={colorScheme}>
          {currentVideo != null &&
            <VideoPlayer
              filepath={currentVideo.fullPath}
              style={{
                aspectRatio: '9/16',
                width: 250
              }}
            />}
        </VideoContainer>
      </Column>
    </Row>
  )
}

export default VideoInfo

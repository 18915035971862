import React from 'react'

export interface LinkProps extends React.HTMLAttributes<HTMLLinkElement> {

}

const Link: React.FC<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>> = (props) => {
  return (
    <a
      {...props}
      target='_blank'
      onClick={e => e.stopPropagation()}
      rel='noreferrer'
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >{props.children}
    </a>
  )
}

export default Link

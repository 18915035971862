import { useEffect, useState } from 'react'
import { Color } from 'together-types'

export default function useColorScheme (): ColorScheme {
  const [colorScheme, setColorScheme] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? 'dark' : 'light'
        setColorScheme(colorScheme)
      })
  }, [])

  return colorScheme === 'dark' ? darkColorScheme : lightColorScheme
}

export interface ColorScheme {
  brightness: string
  primary: Color
  onPrimary: Color
  primaryContainer: Color
  onPrimaryContainer: Color
  secondary: Color
  onSecondary: Color
  secondaryContainer: Color
  onSecondaryContainer: Color
  tertiary: Color
  onTertiary: Color
  tertiaryContainer: Color
  onTertiaryContainer: Color
  error: Color
  errorContainer: Color
  onError: Color
  onErrorContainer: Color
  background: Color
  onBackground: Color
  onBackgroundMuted: Color
  backgroundAlt: Color
  onBackgroundAlt: Color
  surface: Color
  disabled: Color
  onSurface: Color
  surfaceVariant: Color
  onSurfaceVariant: Color
  outline: Color
  onInverseSurface: Color
  inverseSurface: Color
  inversePrimary: Color
  shadow: Color
}

export const fonts = {
  light: 'Lato_300Light',
  lightItalic: 'Lato_300Light_Italic',
  regular: 'Lato_400Regular',
  regularItalic: 'Lato_400Regular_Italic',
  medium: 'Lato_500Medium',
  bold: 'Lato_700Bold',
  boldItalic: 'Lato_700Bold_Italic',
  black: 'Lato_900Black'
}

const lightColorScheme: ColorScheme = {
  brightness: 'light',
  primary: '#261900',
  onPrimary: '#FFFFFF',
  primaryContainer: '#092007',
  onPrimaryContainer: '#FFFFFF',
  secondary: '#6C5C3F',
  onSecondary: '#FFFFFF',
  secondaryContainer: '#F6E0BB',
  onSecondaryContainer: '#251A04',
  tertiary: '#71805A',
  onTertiary: '#FFFFFF',
  tertiaryContainer: '#CDEBC3',
  onTertiaryContainer: '#092007',
  error: '#BA1A1A',
  errorContainer: '#FFDAD6',
  onError: '#FFFFFF',
  onErrorContainer: '#410002',
  background: '#FFFFFF',
  onBackground: '#3E434F',
  onBackgroundMuted: '#777',
  backgroundAlt: '#F1F1F1',
  onBackgroundAlt: '#1E1B16',
  surface: '#f5f5f5',
  disabled: '#E0E0E0',
  onSurface: '#1E1B16',
  surfaceVariant: '#EDE1CF',
  onSurfaceVariant: '#4E4639',
  outline: '#7F7667',
  onInverseSurface: '#F8EFE7',
  inverseSurface: '#34302A',
  inversePrimary: '#FEBB08',
  shadow: '#000000'
}

const darkColorScheme: ColorScheme = {
  brightness: 'dark',
  primary: '#FEBB08',
  onPrimary: '#402D00',
  primaryContainer: '#5D4200',
  onPrimaryContainer: '#FFDEA3',
  secondary: '#D9C4A0',
  onSecondary: '#3B2F15',
  secondaryContainer: '#53452A',
  onSecondaryContainer: '#F6E0BB',
  tertiary: '#71805A',
  onTertiary: '#1E361B',
  tertiaryContainer: '#344D2F',
  onTertiaryContainer: '#CDEBC3',
  error: '#FFB4AB',
  errorContainer: '#93000A',
  onError: '#690005',
  onErrorContainer: '#FFDAD6',
  background: '#1E1B16',
  onBackground: '#E9E1D9',
  onBackgroundMuted: '#6C6C6C',
  backgroundAlt: '#2B271E',
  onBackgroundAlt: '#E9E1D9',
  surface: '#26221c',
  disabled: '#48443e',
  onSurface: '#E9E1D9',
  surfaceVariant: '#4E4639',
  onSurfaceVariant: '#D1C5B4',
  outline: '#9A8F80',
  onInverseSurface: '#1E1B16',
  inverseSurface: '#E9E1D9',
  inversePrimary: '#7A5900',
  shadow: '#000000'
}

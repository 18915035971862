import React, { useState } from 'react'
import { Video } from '../../admin-types'
import { useTranscodeStatus } from '../../hooks/useTranscodeStatus'
import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()
const retryTranscodeJob = httpsCallable(functions, 'retryTranscodeJob')

export interface TranscodeStatusCatcherProps {
  video: Video
}

// This component is used to listen to the transcode status and if it fails, retry it without audio
const TranscodeStatusCatcher: React.FC<TranscodeStatusCatcherProps> = ({ video }) => {
  const { job } = useTranscodeStatus(video.jobID)
  const [hasTriedWithoutAudio, setHasTriedWithoutAudio] = useState(false)
  const hasFailedAudio = job?.state === 'FAILED' && job.error?.code === 3

  if (hasFailedAudio) {
    if (hasTriedWithoutAudio) return null
    setHasTriedWithoutAudio(true)
    console.log('Retrying without audio')
    retryTranscodeJob({ jobID: video.jobID }).catch(() => {
      alert('Error retrying transcode job')
    })
  }

  return (
    <></>
  )
}

export default TranscodeStatusCatcher

import React, { useState, Children, cloneElement, ReactElement, useRef, useEffect } from 'react'

export interface Position {
  x: number
  y: number
}

const nearestTo = (value: Position, positions: Position[]): number => {
  const distances = positions.map((position) => {
    const dx = position.x - value.x
    const dy = position.y - value.y
    return Math.sqrt(dx * dx + dy * dy)
  })
  const min = Math.min(...distances)
  return distances.indexOf(min)
}

const diff = (a: Position, b: Position): Position => {
  return {
    x: a.x - b.x,
    y: a.y - b.y
  }
}

export interface RearrangeableProps {
  children: React.ReactNode
  onRearrange?: (order: string[]) => void
  keys: string[]
}

const Rearrangeable: React.FC<RearrangeableProps> = ({ children, onRearrange, keys }) => {
  const [ready, setReady] = useState<boolean>(false)
  const centerPositionRef = useRef<Position[]>([])
  const [moving, setMoving] = useState<{ index: number, start: Position } | null>(null)
  const [offset, setOffset] = useState<Position>({ x: 0, y: 0 })
  const keyString = keys.join('')

  const movingNewIndex = nearestTo({
    x: centerPositionRef.current[moving?.index ?? 0]?.x + offset.x ?? 0,
    y: centerPositionRef.current[moving?.index ?? 0]?.y + offset.y ?? 0
  }, centerPositionRef.current)

  useEffect(() => {
    const handleMove = (e: globalThis.MouseEvent): void => {
      if (moving == null) return
      const dx = e.clientX - moving.start.x
      const dy = e.clientY - moving.start.y
      setOffset({ x: dx, y: dy })
    }

    const handleUp = (): void => {
      if (moving == null) return
      if (movingNewIndex === moving.index) {
        setOffset({ x: 0, y: 0 })
        setMoving(null)
      } else {
        const nearest = centerPositionRef.current[movingNewIndex]
        setOffset(diff(nearest, centerPositionRef.current[moving.index]))

        const newOrder = [...keys].filter((_, index) => index !== moving.index)
        newOrder.splice(movingNewIndex, 0, keys[moving.index])
        onRearrange?.(newOrder)
      }
    }

    window.addEventListener('mousemove', handleMove)
    window.addEventListener('mouseup', handleUp)

    return () => {
      window.removeEventListener('mousemove', handleMove)
      window.removeEventListener('mouseup', handleUp)
    }
  }, [moving, movingNewIndex, onRearrange, keyString, keys])

  const clones = Children.map(children, (child, index) => {
    const translationMoving = `translate(${moving?.index === index ? offset.x : 0}px, ${moving?.index === index ? offset.y : 0}px)`

    const hasBeenPassedForward = moving == null ? false : (movingNewIndex >= index && moving.index < index)
    const hasBeenPassedBackward = moving == null ? false : (movingNewIndex <= index && moving.index > index)

    const movement = ready
      ? hasBeenPassedForward
        ? diff(centerPositionRef.current[index - 1], centerPositionRef.current[index])
        : (hasBeenPassedBackward
            ? diff(centerPositionRef.current[index + 1], centerPositionRef.current[index])
            : { x: 0, y: 0 })
      : { x: 0, y: 0 }
    const translationOther = `translate(${movement.x}px, ${movement.y}px)`

    const style = ready
      ? {
          transform: moving?.index === index ? translationMoving : translationOther,
          zIndex: moving?.index === index ? 100 : 1,
          transition: moving?.index === index ? '0ms' : '200ms',
          cursor: 'grab'
        }
      : {}
    const imovable: true | null = (child as ReactElement).props.className?.includes('imovable')

    if (imovable) {
      return child
    }

    return cloneElement(child as ReactElement, {
      onMouseDown: (e: any) => setMoving({ index, start: { x: e.clientX, y: e.clientY } }),
      style,
      ref: (el: HTMLDivElement) => {
        if (el != null) {
          if (centerPositionRef.current[index] != null) return
          const rect = el.getBoundingClientRect()
          centerPositionRef.current.push({
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2
          })
        }
      }
    })
  })

  useEffect(() => {
    setReady(false)
    setMoving(null)
    setOffset({ x: 0, y: 0 })
    centerPositionRef.current = []
  }, [keyString])

  const numberOfChildrenWithoutMovable = Children.toArray(children).filter((child) => {
    return (child as ReactElement).props.className?.includes('imovable') !== true
  }).length

  useEffect(() => {
    if (centerPositionRef.current.length === numberOfChildrenWithoutMovable) {
      setReady(true)
    }
  }, [centerPositionRef.current.length, numberOfChildrenWithoutMovable])

  return (
    <>
      {clones}
    </>
  )
}

export default Rearrangeable

import Avatar from '../components/Avatar'
import Card from '../layout/Card'
import collections from '../lib/collections'
import { DataGrid, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid'
import { CheckCircleOutlined } from '@ant-design/icons'
import { UserRecord } from '../admin-types'
import { useCount } from '../firebase/firebase'
import { useState } from 'react'
import { usePaginatedCollection } from '../firebase/pagination'
import { query, where } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import CategoryBadge from '../components/CategoryBadge'
import Button from '../components/Button'
import { VStack } from 'react-stacked'

const columns: GridColDef[] = [
  {
    field: 'avatar',
    headerName: 'Avatar',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      const user: UserRecord = params.row
      return (
        <>
          <Avatar key={user.id} user={user} />
        </>
      )
    }
  },
  { field: 'firstName', headerName: 'First name', width: 120 },
  { field: 'lastName', headerName: 'Last name', width: 170 },
  {
    field: 'role',
    headerName: 'Role',
    width: 80,
    renderCell: (params) => {
      const status = params.value as UserRecord['role']
      switch (status) {
        case 'creator':
          return <p>Creator</p>
        default:
          return <></>
      }
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 80,
    renderCell: (params) => {
      const status = params.value
      switch (status) {
        case 'online':
          return <p style={{ color: 'green' }}>Online</p>
        case 'offline':
          return <p style={{ color: 'red' }}>Offline</p>
        default:
          return <></>
      }
    }
  },
  {
    field: 'categoryInterests',
    headerName: 'Interests',
    sortable: false,
    width: 330,
    renderCell: (params) => {
      const interests = params.value
      if (interests == null) return (<></>)
      return (
        <>
          {interests.map((interest: string) => (
            <CategoryBadge key={interest} categoryID={interest} />
          ))}
        </>
      )
    }
  },
  {
    field: 'lastSeen',
    headerName: 'Last seen',
    width: 120,
    renderCell: (params) => {
      if (params.value == null) return <></>
      const date = new Date(params.value.seconds * 1000)
      return (
        <>
          {date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
        </>
      )
    }
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 120,
    renderCell: (params) => {
      if (params.value == null) return <></>
      const date = new Date(params.value.seconds * 1000)
      return (
        <>
          {date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
        </>
      )
    }
  },
  { field: 'email', headerName: 'Email', width: 300 },
  {
    field: 'notificationsEnabled',
    headerName: 'Notifications',
    sortable: false,
    width: 100,
    type: 'boolean',
    renderCell: (params) => {
      return params.value === true ? <CheckCircleOutlined /> : ''
    }

  },
  { field: 'id', headerName: 'ID', sortable: false, width: 150 },
  { field: 'bio', headerName: 'Bio', sortable: false, flex: 1 }
]

const Users: React.FC = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
    sortField: 'firstName_lowercase'
  })

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: paginationModel.sortField.replaceAll('_lowercase', ''),
        operatorValue: 'contains',
        value: ''
      }
    ]
  })

  const [userDocs, loadingUsers, errorUsers] = usePaginatedCollection(
    filterModel.items[0].value == null || filterModel.items[0].value === ''
      ? collections.users
      : query(collections.users, where(paginationModel.sortField, '>=', filterModel.items[0].value.toLowerCase())
      ),
    paginationModel.page,
    paginationModel.pageSize,
    paginationModel.sortField,
    'id'
  )

  const users = userDocs?.docs.map((doc) => doc.data())
  const [count] = useCount(collections.users)
  const navigate = useNavigate()

  const updateSortModel = (model: GridSortModel): void => {
    const sortModel = model[0]

    let fieldName = sortModel.field

    switch (fieldName) {
      case 'firstName':
        fieldName = 'firstName_lowercase'
        break
      case 'lastName':
        fieldName = 'lastName_lowercase'
        break
      default:
        break
    }

    if (sortModel == null) return
    setPaginationModel({ ...paginationModel, sortField: fieldName })
    // Move filter to new field
    setFilterModel({
      ...filterModel,
      items: [
        {
          columnField: fieldName.replaceAll('_lowercase', ''),
          operatorValue: 'contains',
          value: filterModel.items[0].value
        }
      ]
    })
  }

  if (errorUsers != null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <Card>
      <VStack width='100%' gap={10}>
        <h1>Users</h1>
        <Button onClick={() => navigate('/users/new')}>Create new user</Button>
        <DataGrid
          autoHeight
          rows={users ?? []}
          columns={columns}
          paginationMode='server'
          rowCount={count}
          loading={loadingUsers}
          pagination
          pageSize={paginationModel.pageSize}
          page={paginationModel.page}
          onPageChange={(page) => {
            setPaginationModel({ ...paginationModel, page })
          }}
          onPageSizeChange={(pageSize) => {
            setPaginationModel({ ...paginationModel, pageSize })
          }}
          onSortModelChange={updateSortModel}
          sortingMode='server'
          filterMode='server'
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={e => navigate(`/users/${e.id}`)}
        />
      </VStack>
    </Card>
  )
}

export default Users

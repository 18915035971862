import copy from 'copy-to-clipboard'
import { getDocs } from 'firebase/firestore'
import collections from './collections'

export const copyCategories = async (): Promise<void> => {
  const categories = await getDocs(collections.categories)
  const text = categories.docs.map((doc) => `${doc.id}: ${doc.data().title} - ${doc.data().description}`).join('\n')
  copy(text)
}

export const copyCardTypes = async (): Promise<void> => {
  const cardTypes = await getDocs(collections.cardTypes)
  const text = cardTypes.docs.map((doc) => `${doc.id}: ${doc.data().title} - ${doc.data().description}`).join('\n')
  copy(text)
}

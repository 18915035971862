import React from 'react'
import Label from './Label'
import { HStack, VStack } from 'react-stacked'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import { doc } from 'firebase/firestore'

export interface CourseSessionsPreviewProps {
  courseTemplateID: string
}

const CourseSessionsPreview: React.FC<CourseSessionsPreviewProps> = ({ courseTemplateID }) => {
  const [course, loading, error] = useDocumentData(doc(collections.courseTemplates, courseTemplateID))
  const [categories] = useCollectionData(collections.categories)

  if (error != null) return <p>Error: {error.message}</p>
  if (loading || course == null) return <p>Loading...</p>

  return (
    <VStack gap={10}>
      <Label>Sessions preview data</Label>
      {course.sessionsPreview?.map((session, i) => {
        const color = categories?.find(c => c.id === session.categoryID)?.color
        return (
          <HStack key={session.title} gap={10}>
            <img alt='thumbnail' src={session.image?.downloadURL96x96} style={{ width: 50, height: 50, borderRadius: 10 }} />
            <div style={{ width: 50, height: 50, backgroundColor: color, borderRadius: 10 }} />
            <p>{session.title}</p>
          </HStack>
        )
      })}
      {window.location.host.includes('local') && <pre>{JSON.stringify(course.order, null, 2)}</pre>}
    </VStack>
  )
}

export default CourseSessionsPreview

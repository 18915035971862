import { HttpsCallableResult, connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import React, { useCallback, useEffect, useState } from 'react'
import { firebaseApp } from '../../firebase/firebase'
import Card from '../../layout/Card'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import Button from '../Button'
import Title from '../Title'
import { useNavigate } from 'react-router-dom'
import { HStack, VStack } from 'react-stacked'
import Input from '../Input'
import OpenAI from 'openai'

const functions = getFunctions(firebaseApp)

interface AssistantsListProps {
  onAssistants?: (assistants: OpenAI.Beta.Assistant[]) => void
}

const AssistantsList: React.FC<AssistantsListProps> = ({ onAssistants }) => {
  const [assistants, setAssistants] = useState<OpenAI.Beta.Assistant[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const navigate = useNavigate()
  const [assistantName, setAssistantName] = useState<string>('')
  const [loadingDelete, setLoadingDelete] = useState<string | null>(null)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc'
    }
  ])

  const refresh = useCallback(async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const assistants = await httpsCallable(functions, 'listAssistants')() as HttpsCallableResult<OpenAI.Beta.Assistant[]>
    if (assistants == null) throw new Error('assistants is null')
    setAssistants(assistants.data)
    if (onAssistants != null) onAssistants(assistants.data)
    setLoading(false)
  }, [onAssistants])

  const deleteAssistant = async (assistantID: string): Promise<void> => {
    setLoadingDelete(assistantID)
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    await httpsCallable(functions, 'deleteAssistant')({ assistantID })
    await refresh()
    setLoadingDelete(null)
  }

  const createAssistant = async (): Promise<void> => {
    setLoadingCreate(true)
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    await httpsCallable(functions, 'createAssistant')({ name: assistantName })
    await refresh()
    setLoadingCreate(false)
  }

  useEffect(() => {
    refresh().catch((error) => {
      console.error(error)
      alert(error.message)
      setLoading(false)
    })
  }, [refresh])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'instructions', headerName: 'Instructions', flex: 1 },
    { field: 'model', headerName: 'Model', flex: 1 },
    { field: 'object', headerName: 'Object', flex: 1 },
    { field: 'created_at', headerName: 'Created at', flex: 1, valueFormatter: ({ value }) => new Date((value as number) * 1000).toLocaleString() },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 110,
      renderCell: ({ row }) => (
        <Button
          loading={loadingDelete === row.id}
          onClick={(e) => {
            e.stopPropagation()
            deleteAssistant(row.id as string).catch((error) => {
              console.error(error)
              alert(error.message)
              setLoadingDelete(null)
            })
          }}
        >
          Delete
        </Button>
      )
    }
  ]

  return (
    <Card style={{ flex: 1 }}>
      <VStack grow={1} gap={10}>
        <Title>Assistants</Title>
        <HStack gap={10}>
          <Input placeholder='Assistant name' value={assistantName} onChange={setAssistantName} />
          <Button
            loading={loadingCreate}
            disabled={assistantName.length === 0}
            onClick={() => {
              createAssistant().catch((error) => {
                console.error(error)
                alert(error.message)
                setLoadingCreate(false)
              })
            }}
          >
            Create assistant
          </Button>
        </HStack>
        <DataGrid
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          rows={assistants ?? []}
          columns={columns}
          loading={loading}
          onRowClick={(params) => {
            const id = params.row.id as string
            navigate(`/ai-assistant/${id}`)
          }}
        />
      </VStack>
    </Card>
  )
}

export default AssistantsList

import React from 'react'
import Button from './Button'
import { useTranscodeStatus } from '../hooks/useTranscodeStatus'
import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()
const retryTranscodeJob = httpsCallable(functions, 'retryTranscodeJob')

export interface TranscodeStatusProps {
  jobID: string
}

const TranscodeStatus: React.FC<TranscodeStatusProps> = ({ jobID }) => {
  const { job } = useTranscodeStatus(jobID)

  const retry = (): void => {
    console.log(jobID)
    retryTranscodeJob({ jobID }).catch((error) => {
      console.warn(error)
      alert('Error retrying transcode job')
    })
  }

  if (job?.state === 'FAILED') {
    if (job.error?.code === 3) {
      console.log(job)
      return <Button onClick={retry}>Retry</Button>
    }
  }

  return (
    <div>
      {job?.state}
    </div>
  )
}

export default TranscodeStatus

import { sha256 } from 'crypto-hash'

export const getFileHash = async (file: File): Promise<string> => {
  const reader = new FileReader()
  reader.readAsDataURL(file as Blob)

  const src: FileReader['result'] = await new Promise((resolve, reject) => {
    reader.addEventListener('load', function () {
      resolve(reader.result)
    }, false)
  })

  if (typeof src !== 'string') throw new Error('src is not string')
  return await sha256(src)
}

import React, { useState } from 'react'
import Card from '../layout/Card'
import Title from '../components/Title'
import ImageSelect from '../components/ImageSelect'
import useDirectory from '../hooks/useDirectory'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { ClipLoader } from 'react-spinners'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

const storage = getStorage()

const Item = styled.div`
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.backgroundAlt}};
  cursor: pointer;

  :last-child {
    margin-bottom: 0;
  }

  > p {
    margin: 0;
  }
`

const Images: React.FC = () => {
  const colorScheme = useColorScheme()
  const [files, error, loading] = useDirectory('images')
  const [imageURL, setImageURL] = useState<string | null>(null)

  const loadImage = (name: string): void => {
    const storageRef = ref(storage, `images/${name}`)
    getDownloadURL(storageRef).then((url) => {
      setImageURL(url)
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <>
      {imageURL != null && (
        <Card>
          <Title>Image preview</Title>
          <ImageSelect
            image={{
              id: 'preview',
              downloadURL1080x1080: imageURL,
              downloadURL96x96: imageURL
            }} allowUpload={false}
          />
        </Card>
      )}

      <Card>
        {loading && <ClipLoader />}
        {error != null && <p>{error}</p>}
        {files?.items.map((file) => (
          <Item key={file.name} colorScheme={colorScheme} onClick={() => loadImage(file.name)}>
            <p>{file.name}</p>
          </Item>
        ))}
        {files?.items.length === 0 && <p>No images uploaded</p>}
      </Card>
    </>
  )
}

export default Images

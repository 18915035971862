import { DocumentData, Query, limit, orderBy as fbOrderBy, query, startAfter } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { CollectionHook, Options } from 'react-firebase-hooks/firestore/dist/firestore/types'

type response = <T = DocumentData>(
  query: Query<T> | null | undefined,
  page: number,
  pageSize: number,
  orderBy: string,
  compoundOrderBy?: string,
  options?: Options | undefined
) => CollectionHook<T>

export const usePaginatedCollection: response = (q, page, pageSize, orderBy, compoundOrderBy, options) => {
  const [currentPage, setCurrentPage] = useState(0)

  const [startAfterQueries, setStartAfterQueries] = useState<Array<{ order: string | null, id: string }>>([{ order: null, id: '' }])
  if (q == null) throw new Error('Query is null')
  const currentLastID = startAfterQueries[currentPage]
  const first = compoundOrderBy != null
    ? query(
      q,
      fbOrderBy(orderBy),
      fbOrderBy(compoundOrderBy),
      limit(pageSize),
      startAfter(currentLastID.order, currentLastID.id)
    )
    : query(
      q,
      fbOrderBy(orderBy),
      limit(pageSize),
      startAfter(currentLastID.order)
    )

  const data = useCollection(first, options)

  useEffect(() => {
    if (page === currentPage) return
    if (page > currentPage) {
      // next load another page
      const docs = data[0]?.docs.map((doc) => doc.data())
      if (docs == null) return
      const lastItem: any = docs[docs.length - 1]
      const lastItemID = {
        order: lastItem[orderBy],
        id: compoundOrderBy != null ? lastItem[compoundOrderBy] : ''
      }

      const newState = [...startAfterQueries]
      if (newState.length < page) {
        newState.push(lastItemID)
      } else {
        newState[page] = lastItemID
      }
      setStartAfterQueries(newState)
      setCurrentPage(currentPage + 1)
    } else {
      // previous
      setCurrentPage(currentPage - 1)
    }
  }, [page, currentPage, data, orderBy, compoundOrderBy, startAfterQueries])

  return data
}

import React from 'react'
import { Session } from '../../admin-types'
import { DeleteFilled } from '@ant-design/icons'
import TimeInput from './TimeInput'
import CardBottomButtons, { CardBottomButton } from './CardBottomButtons'
import { UploadUI, useUploadVideo } from '../../hooks/useUploadVideo'

export interface CardFooterVideoProps {
  event: Session['agenda'][0]
  onEvent: (event: Partial<Session['agenda'][0]>) => void
  onDelete: () => void
}

const CardFooterVideo: React.FC<CardFooterVideoProps> = ({ event, onDelete, onEvent }) => {
  const [file, setFile] = React.useState<File | null>(null)

  const onVideoID = (id: string): void => {
    onEvent({ videoID: id })
  }
  const { status, progress } = useUploadVideo(file, onVideoID)

  return (
    <CardBottomButtons>
      <CardBottomButton onClick={onDelete}>
        <DeleteFilled style={{ color: '#999' }} />
      </CardBottomButton>
      <CardBottomButton>
        <UploadUI status={status} progress={progress} onFileSelect={setFile} />
      </CardBottomButton>
      <CardBottomButton disabled>
        <TimeInput value={event.durationSeconds} onChange={((e) => onEvent({ durationSeconds: e }))} locked />
      </CardBottomButton>
    </CardBottomButtons>
  )
}

export default CardFooterVideo

import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import React, { useMemo, useState } from 'react'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import collections, { courseSessionTemplates } from '../lib/collections'
import Avatar from './Avatar'
import Category from './Category'
import { EyeInvisibleOutlined, EyeOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { Session } from '../admin-types'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase/firebase'
import { doc, query, updateDoc, where } from 'firebase/firestore'
import useIsAdmin from '../hooks/useIsAdmin'
import { HStack } from 'react-stacked'

export interface CourseSessionListProps {
  courseID: string
}

const CourseSessionList: React.FC<CourseSessionListProps> = ({ courseID }) => {
  const [course] = useDocumentData(doc(collections.courseTemplates, courseID))
  const order = useMemo(() => course?.order ?? [], [course])
  const isAdmin = useIsAdmin()
  const [user] = useAuthState(auth)
  const q = isAdmin ? courseSessionTemplates(courseID) : query(courseSessionTemplates(courseID), where('authoredBy', '==', user?.uid ?? ''))
  const [templates, loading, error] = useCollectionData(q)
  const [categories] = useCollectionData(collections.categories)
  const [sortModel, setSortModel] = useState<GridSortModel>([{
    field: 'order',
    sort: 'asc'
  }])
  const navigate = useNavigate()

  const columns: GridColDef[] = useMemo(() => {
    const handleOrderButtonPress = (id: string, action: 'increase' | 'decrease'): void => {
      const index = order.findIndex((orderID) => orderID === id)
      const newOrder = [...order]
      switch (action) {
        case 'increase':
          if (index === 0) return
          // swap with previous
          newOrder[index] = order[index - 1]
          newOrder[index - 1] = order[index]
          changeOrder(newOrder)
          break
        case 'decrease':
          if (index === order.length - 1) return
          // swap with next
          newOrder[index] = order[index + 1]
          newOrder[index + 1] = order[index]
          changeOrder(newOrder)
          break
      }
    }

    const changeOrder = (newOrder: string[]): void => {
      const ref = doc(collections.courseTemplates, courseID)
      updateDoc(ref, { order: newOrder }).catch((error) => {
        console.error(error)
        alert('Error updating order')
      })
    }

    return [
      { field: 'title', headerName: 'Title', flex: 1 },
      {
        field: 'createdAt',
        headerName: 'Created',
        valueGetter: (params) => {
          const date = new Date(params.value.seconds * 1000)
          return date.toISOString()
        },
        renderCell: (params: any) => {
          const date = new Date(params.value)
          return date.toLocaleDateString()
        }
      },
      {
        field: 'authoredBy',
        headerName: 'Author',
        width: 90,
        renderCell: (params) => {
          return (
            <Avatar userID={params.value} />
          )
        }
      },
      {
        field: 'categoryID',
        headerName: 'Quality',
        width: 180,
        renderCell: (params) => {
          const category = categories?.find((category) => category.id === params.value)
          if (category == null) return <></>
          return (
            <Category category={category} />
          )
        }
      },
      {
        field: 'visible',
        headerName: 'Visibility',
        width: 70,
        renderCell: (params) => {
          const visibility = params.value
          if (visibility === true) return <EyeOutlined />
          return <EyeInvisibleOutlined />
        }
      },
      {
        field: 'order',
        headerName: 'Order',
        width: 150,
        valueGetter: (params) => {
          const index = order.findIndex((orderID) => orderID === params.row.id)
          return index
        },
        renderCell: ({ row }: { row: Session }) => {
          const index = order.findIndex((orderID) => orderID === row.id)
          return (
            <HStack gap={10}>
              <Button
                disabled={index === 0}
                onClick={(e) => {
                  e.stopPropagation()
                  handleOrderButtonPress(row.id, 'increase')
                }}
              >
                <ArrowUpOutlined />
              </Button>
              <Button
                disabled={index === order.length - 1}
                onClick={(e) => {
                  e.stopPropagation()
                  handleOrderButtonPress(row.id, 'decrease')
                }}
              >
                <ArrowDownOutlined />
              </Button>
            </HStack>
          )
        }
      },
      {
        field: '',
        headerName: 'Edit',
        width: 100,
        renderCell: ({ row }: { row: Session }) => {
          return (
            <Button onClick={(e) => {
              e.stopPropagation()
              navigate(`template/${row.id}`)
            }}
            >Edit
            </Button>
          )
        }
      }
    ]
  }, [navigate, categories, order, courseID])

  if (error != null) {
    console.error(error)
    return <div>Error loading sessions</div>
  }

  const orderedTemplates = order.map((id) => templates?.find((template) => template.id === id)).filter((template) => template != null) as Session[]

  return (
    <DataGrid
      autoHeight
      rows={orderedTemplates ?? []}
      columns={columns}
      sortingOrder={['desc', 'asc']}
      onSortModelChange={setSortModel}
      sortModel={sortModel}
      loading={loading}
    />
  )
}

export default CourseSessionList

export function removeUndefinedKeys (obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    // If the object is an array, remove undefined elements
    return obj.filter((item) => item !== undefined).map(removeUndefinedKeys)
  }

  // If the object is an object, remove undefined properties recursively
  const cleanedObj: any = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = removeUndefinedKeys(obj[key])
      if (value !== undefined) {
        cleanedObj[key] = value
      }
    }
  }
  return cleanedObj
}

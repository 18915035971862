import React from 'react'
import styled from 'styled-components'
import { Session } from '../../admin-types'
import EventCard, { Placeholder } from '../event-card'
import { v4 as uuid } from 'uuid'
import { Event, eventFormats } from 'together-types'
import Rearrangeable from '../Rearrangeable'

const createEmptyEvent = (): Event => {
  return {
    description: '',
    durationSeconds: 0,
    durationType: 'duration',
    format: eventFormats.one_at_the_time.value,
    id: uuid(),
    title: ''
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ddd;
  padding: 20px;
  border-radius: 20px;
  gap: 20px;
  flex-wrap: wrap;
`

export interface AgendaProps {
  agenda: Session['agenda']
  onAgenda: (agenda: Session['agenda']) => void
}

const Agenda: React.FC<AgendaProps> = ({ agenda, onAgenda }) => {
  const onRearrange = (order: string[]): void => {
    const newAgenda = order.map(id => agenda.find(a => a.id === id)).filter(a => a != null) as Session['agenda']
    onAgenda(newAgenda)
  }

  const onEvent = (event: Event): void => {
    const newAgenda = [...agenda]
    const index = newAgenda.findIndex(a => a.id === event.id)
    newAgenda[index] = event
    onAgenda(newAgenda)
  }

  const onDelete = (id: string): void => {
    if (agenda.length === 1) {
      onAgenda([])
      return
    }
    const newAgenda = [...agenda]
    const index = newAgenda.findIndex(a => a.id === id)
    newAgenda.splice(index, 1)
    newAgenda[newAgenda.length - 1].linkedToNext = false
    onAgenda(newAgenda)
  }

  return (
    <Container>
      <Rearrangeable keys={[...agenda.map(a => a.id), 'add']} onRearrange={onRearrange}>
        {agenda.map((event, index) => {
          const isLast = index === agenda.length - 1
          return (
            <div key={event.id}>
              <EventCard event={event} onEvent={onEvent} onDelete={() => onDelete(event.id)} linkToNextAvailable={!isLast} />
            </div>
          )
        })}
        <div className='imovable'>
          <Placeholder onClick={(type) => {
            const newEvent = createEmptyEvent()
            if (type === 'video') {
              newEvent.videoID = ''
              newEvent.format = eventFormats.all_at_once.value
            }
            onAgenda([...agenda, newEvent])
          }}
          />
        </div>
      </Rearrangeable>
    </Container>
  )
}

export default Agenda

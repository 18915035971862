import React from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { ClipLoader } from 'react-spinners'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100vw;
  height: 100vh;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.backgroundAlt};
`

const Loading: React.FC = () => {
  const colorScheme = useColorScheme()
  return (
    <Container colorScheme={colorScheme}>
      <ClipLoader />
    </Container>
  )
}

export default Loading

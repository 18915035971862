import { FullMetadata, getDownloadURL, getStorage, ref } from 'firebase/storage'
import React, { MouseEvent } from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import Button from './Button'
import { DownloadOutlined } from '@ant-design/icons'

const storage = getStorage()

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  background-color: ${(props: { colorScheme: ColorScheme, selected: boolean }) => props.selected ? props.colorScheme.primaryContainer : props.colorScheme.backgroundAlt};
  transition: 0.15s;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }

  > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`

export interface FileProps {
  file: FullMetadata
  selected?: boolean
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

const File: React.FC<FileProps> = ({ file, selected = false, onClick }) => {
  const colorScheme = useColorScheme()

  const readableDate = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
  }

  const download = (file: FullMetadata): void => {
    // window.open(file.downloadURLs[0])
    const fileRef = ref(storage, file.fullPath)
    getDownloadURL(fileRef).then((url) => {
      window.open(url)
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <Row>
      <Container colorScheme={colorScheme} selected={selected} onClick={onClick}>
        <p>{file.name}</p>
        {/* <p>{file.fullPath}</p> */}
        {/* <p>{file.contentType}</p> */}
        <p>{Math.round(file.size / 100000) / 10}mb</p>
        <p>Created {readableDate(new Date(file.timeCreated))}</p>
      </Container>
      <Button style={{ marginLeft: 10 }} onClick={() => download(file)}><DownloadOutlined /></Button>
    </Row>
  )
}

export default File

/* eslint-disable import/no-anonymous-default-export */
import { getFirestore, collection, CollectionReference, query, collectionGroup, where } from 'firebase/firestore'
import { Category, DiscoverCategory, Statistic } from 'together-types'
import { Course, Session, UserRecord, Video, CourseTemplate, Thread } from '../admin-types'
import { firebaseApp } from '../firebase/firebase'

export const db = getFirestore(firebaseApp)

export const courseSessionTemplates = (courseID: string): CollectionReference<Session> => {
  return collection(db, 'courseTemplates', courseID, 'templates') as CollectionReference<Session>
}

export default {
  get categories () { return collection(db, 'categories') as CollectionReference<Category> },
  get cardTypes () { return collection(db, 'cardTypes') as CollectionReference<Category> },
  get sessions () { return collection(db, 'sessions') as CollectionReference<Session> },
  get sessionArchive () { return collection(db, 'sessionArchive') as CollectionReference<Session> },
  get sessionTemplates () { return collection(db, 'sessionTemplates') as CollectionReference<Session> },
  get courses () { return collection(db, 'courses') as CollectionReference<Course> },
  get courseTemplates () { return collection(db, 'courseTemplates') as CollectionReference<CourseTemplate> },
  get users () { return collection(db, 'users') as CollectionReference<UserRecord> },
  get notificationTokens () { return query(collectionGroup(db, 'private'), where('type', '==', 'notifications')) as CollectionReference<{ token: string, type: 'notifications' }> },
  get discoverCategories () { return collection(db, 'discoverCategories') as CollectionReference<DiscoverCategory> },
  get videos () { return collection(db, 'videos') as CollectionReference<Video> },
  get statistics () { return collection(db, 'statistics') as CollectionReference<Statistic> },
  get threads () { return collection(db, 'ai-threads') as CollectionReference<Thread> }
}

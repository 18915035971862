import { v4 as uuid } from 'uuid'
import { CourseTemplate, CreateInput } from '../admin-types'
import { Timestamp } from 'firebase/firestore'

export const createEmptyCourseTemplate = (authoredBy: string): CreateInput<CourseTemplate> => {
  const id = uuid()

  const course: CreateInput<CourseTemplate> = {
    accentColor: '#ffffff',
    additionalInfo: [],
    authoredBy,
    color: '#ffffff',
    createdAt: Timestamp.now(),
    id,
    order: [],
    subtitle: '',
    title: '',
    visible: false
  }

  return course
}

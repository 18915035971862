import React, { useEffect, useMemo, useState } from 'react'
import useMousePosition from '../../hooks/useMousePosition'

export interface Position {
  x: number
  y: number
}

export interface ContextMenuProps {
  children: React.ReactNode
  menu: React.ReactNode
}

const ContextMenuContext = React.createContext<{
  menu: React.ReactNode
  setMenu: (menu: React.ReactNode) => void
}>({
      menu: null,
      setMenu: () => { }
    })

export const ContextMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [menu, setMenu] = useState<React.ReactNode | null>(null)
  const [position, setPosition] = useState<Position | null>(null)
  const { x, y } = useMousePosition()

  const handleWindowClick = (): void => {
    setMenu(null)
    setPosition(null)
  }

  useEffect(() => {
    if (menu != null) {
      setPosition({ x, y })
      window.setTimeout(() => {
        window.addEventListener('click', handleWindowClick)
      }, 1)
    } else {
      window.removeEventListener('click', handleWindowClick)
    }

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
    // eslint-disable-next-line
  }, [menu])

  return (
    <ContextMenuContext.Provider value={
      {
        menu,
        setMenu: (menu: React.ReactNode) => {
          setMenu(menu)
        }
      }
    }
    >
      {children}
      {menu != null && position != null && (
        <div style={{
          position: 'absolute',
          left: position.x,
          top: position.y,
          zIndex: 1000
        }}
        >
          {menu}
        </div>
      )}
    </ContextMenuContext.Provider>
  )
}

const ContextMenu: React.FC<ContextMenuProps> = ({ children, menu }) => {
  const { setMenu } = React.useContext(ContextMenuContext)

  const Clones = useMemo(() => {
    const childArray = React.Children.toArray(children)
    return childArray.map((child, index) => {
      return React.cloneElement(child as React.ReactElement, {
        onClick: () => setMenu(menu)
      })
    })
  }, [children, menu, setMenu])

  return (
    <>
      {Clones}
    </>
  )
}

export default ContextMenu

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`

  justify-content: center;
`

const ButtonRow = styled.div`
  border-radius: 0 0 20px 20px;
  border-top: 1px solid #ddd;
  gap: 1px;
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  flex: 1;

  >div:first-child {
    border-radius: 0 0 0 20px;
  }

  >div:last-child {
    border-radius: 0 0 20px 0;
  }
`

export const CardBottomButton = styled.div<{ disabled?: boolean }>`
  ${props => props.disabled === true ? '' : 'cursor: pointer;'}
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;

  :hover {
    ${props => props.disabled === true ? '' : 'background-color: #eee;'}
  }
`

export interface MenuProps {
  children: React.ReactNode
}

const CardBottomButtons: React.FC<MenuProps> = ({ children }) => {
  return (
    <Container>
      <ButtonRow>
        {children}
      </ButtonRow>
    </Container>
  )
}

export default CardBottomButtons

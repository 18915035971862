import React from 'react'
import styled from 'styled-components'
import Label from './Label'
import Button from './Button'
import Input from './Input'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
`

export interface ArrayInputProps {
  value: string[]
  onChange: (value: string[]) => void
  label?: string
  placeholder?: string
}

const ArrayInput: React.FC<ArrayInputProps> = ({ value, onChange, label, placeholder }) => {
  const remove = (index: number): void => {
    onChange(value.filter((_, i) => i !== index))
  }

  const add = (): void => {
    onChange([...value, ''])
  }

  return (
    <List>
      {label != null && <Label>{label}</Label>}

      {value.map((item, index) => (
        <Item key={index}>
          <Input placeholder={placeholder} style={{ flex: 1, marginRight: 10 }} value={item} onChange={(e) => onChange(value.map((v, i) => i === index ? e : v))} />
          <Button onClick={() => remove(index)}><DeleteOutlined /></Button>
        </Item>
      ))}

      <Button onClick={add}><PlusOutlined /></Button>
    </List>
  )
}

export default ArrayInput

import React from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import styled from 'styled-components'
import collections from '../lib/collections'
import { doc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

const Tag = styled.div`
  cursor: pointer;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px 15px;
  align-self: flex-start;
  justify-self: flex-start;
  font-weight: bold;
  flex-grow: 0;
`

export interface CourseTagProps {
  courseID: string
}

const CourseTag: React.FC<CourseTagProps> = ({ courseID }) => {
  const [course, , errorCourse] = useDocumentData(doc(collections.courseTemplates, courseID))
  const navigate = useNavigate()

  if (errorCourse != null) {
    console.error(errorCourse)
    return <Tag>Error loading course</Tag>
  }

  return (
    <Tag onClick={() => navigate(`/course-templates/${courseID}`)}>
      {course?.title}
    </Tag>
  )
}

export default CourseTag

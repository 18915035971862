import React from 'react'
import styled from 'styled-components'
import { Session } from '../../admin-types'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import collections from '../../lib/collections'
import ContextMenu from '../context-menu'
import Menu from '../context-menu/Menu'

function darkenHexColor (hexColor: string, factor: number): string {
  factor = Math.max(0, Math.min(1, factor))

  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  const darkenedR = Math.round(r * (1 - factor))
  const darkenedG = Math.round(g * (1 - factor))
  const darkenedB = Math.round(b * (1 - factor))

  const darkenedHexColor =
    '#' +
    (darkenedR.toString(16).padStart(2, '0') +
      darkenedG.toString(16).padStart(2, '0') +
      darkenedB.toString(16).padStart(2, '0'))

  return darkenedHexColor
}

const Container = styled.div`
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const ColoredBlur = styled.div<{ color: string }>`
  width: 40px;
  height: 40px;
  background-color: ${props => props.color};
  border-radius: 50%;
  box-shadow: 0 0 45px 45px ${props => props.color};
  opacity: 0.6;
  transform: translateY(-20px);
`

export interface CardHeaderProps {
  event: Session['agenda'][0]
  onCardType: (cardType: string) => void
}

const CardHeader: React.FC<CardHeaderProps> = ({ event, onCardType }) => {
  const [cardTypes] = useCollectionData(collections.cardTypes)
  let cardType = cardTypes?.find((cardType) => cardType.id === event.cardTypeID)

  if (cardType == null) {
    cardType = {
      id: 'select-type',
      title: 'Select card type',
      description: 'Select a card type',
      color: '#cccccc'
    }
  }

  return (
    <Container>
      <ContextMenu
        menu={<Menu
          options={cardTypes?.map(c => c.title) ?? []}
          onOption={(o) => {
            const cardType = cardTypes?.find(c => c.title === o)
            if (cardType != null) {
              onCardType(cardType.id)
            }
          }}
          value={cardType?.title ?? ''}
              />}
      >
        <div style={{
          color: darkenHexColor(cardType?.color ?? '#000000', 0.5),
          zIndex: 5,
          cursor: 'pointer'
        }}
        >
          {cardType?.title}
        </div>
      </ContextMenu>
      {cardType != null && <Mask><ColoredBlur color={cardType.color} /></Mask>}
    </Container>
  )
}

export default CardHeader

import { useCollection } from 'react-firebase-hooks/firestore'
import Card from '../layout/Card'
import collections from '../lib/collections'
import { DataGrid, GridCellEditCommitParams, GridColDef } from '@mui/x-data-grid'
import { ClipLoader } from 'react-spinners'
import { CategoryDot } from '../components/Category'
import Button from '../components/Button'
import { useState } from 'react'
import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore'
import { firebaseApp } from '../firebase/firebase'
import { v4 as uuid } from 'uuid'
import styled from 'styled-components'
import Title from '../components/Title'
import Input from '../components/Input'
import Label from '../components/Label'
import TextArea from '../components/TextArea'
import { Category } from 'together-types'
import ImageSelect from '../components/ImageSelect'
import ColorInput from '../components/ColorInput'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const generateEmptyCategory = (): Category => {
  return {
    id: uuid(),
    title: '',
    description: '',
    color: '#000000'
  }
}

const Categories: React.FC = () => {
  const [categoryDocs, loadingCategories, error] = useCollection(collections.categories)
  const [currentCategory, setCurrentCategory] = useState<Category>(generateEmptyCategory())
  const [editingExisting, setEditingExisting] = useState(false)

  const categories = categoryDocs?.docs.map((doc) => doc.data())
  const [loading, setLoading] = useState(false)

  const handleUpdateCurrentCategory = (changes: Partial<Category>): void => {
    setCurrentCategory({
      ...currentCategory,
      ...changes
    })
  }

  const handleGridCommit = (e: GridCellEditCommitParams): void => {
    // @ts-expect-error The .row property is not typed correctly
    const category = e.row as Category
    const newState = { ...category, [e.field]: e.value }
    updateCategory(newState)
  }

  const updateCategory = (category: Category): void => {
    setLoading(true)
    setDoc(doc(getFirestore(firebaseApp), 'categories', category.id), category).then(() => {
      setCurrentCategory(generateEmptyCategory())
      setEditingExisting(false)
    }).catch((error) => {
      console.error('Error adding document: ', error)
      alert(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const deleteCategory = (category: Category): void => {
    if (!window.confirm(`Are you sure you want to delete the category "${category.title}"?`)) return

    setLoading(true)
    deleteDoc(doc(getFirestore(firebaseApp), 'categories', category.id)).catch((error) => {
      console.error('Error adding document: ', error)
      alert(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, editable: true },
    {
      field: 'color',
      headerName: 'Color',
      width: 100,
      renderCell: (params) => {
        const color: string = params.value
        return (
          <CategoryDot color={color} />
        )
      }
    },
    { field: 'description', headerName: 'Description', flex: 2, editable: true },
    { field: 'id', headerName: 'ID', width: 200 },
    {
      field: '',
      headerName: 'Edit',
      width: 100,
      renderCell: (params) => {
        const category: Category = params.row as Category
        return (
          <Button onClick={() => {
            setCurrentCategory(category)
            setEditingExisting(true)
          }}
          >Edit
          </Button>
        )
      }
    }
  ]

  if (loadingCategories) {
    return <Card center><ClipLoader /></Card>
  }

  if (error != null || categories == null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <>
      <Card>
        <Row>
          <Column style={{ flex: 1, marginRight: 20 }}>
            <Title>{editingExisting ? 'Edit ' + currentCategory.title : 'Create new quality'}</Title>
            <Input label='Title' onChange={(text) => handleUpdateCurrentCategory({ title: text })} placeholder='Title...' value={currentCategory.title} />
            <TextArea label='Description' onChange={(text) => handleUpdateCurrentCategory({ description: text })} placeholder='Description...' value={currentCategory.description} />
            <ColorInput value={currentCategory.color} onChange={(c) => handleUpdateCurrentCategory({ color: c })} />
            <Label>Image {currentCategory?.icon?.id}</Label>
            {/* JSON .icon data */}
            <pre>{JSON.stringify(currentCategory?.icon, null, 2)}</pre>
            <ImageSelect image={currentCategory?.icon} onUploadComplete={(image) => setCurrentCategory({ ...currentCategory, icon: image })} />
          </Column>
        </Row>
        <Row style={{ justifyContent: 'flex-end', marginTop: 20 }}>
          {editingExisting && <Button onClick={() => deleteCategory(currentCategory)} style={{ marginRight: 10 }}>Delete</Button>}
          <Button
            style={{ marginRight: 10 }} onClick={() => {
              setEditingExisting(false)
              setCurrentCategory(generateEmptyCategory())
            }}
          >Cancel
          </Button>
          <Button disabled={currentCategory.title === '' || currentCategory.description === ''} onClick={() => updateCategory(currentCategory)}>Save</Button>
        </Row>
      </Card>
      <Card>
        <div style={{ width: '100%' }}>
          <DataGrid
            selectionModel={[
              currentCategory.id
            ]}
            loading={loading}
            autoHeight
            rows={categories}
            columns={columns}
            onCellEditCommit={handleGridCommit}
            disableSelectionOnClick
          />
        </div>
      </Card>
    </>
  )
}

export default Categories

import React, { useEffect } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import styled from 'styled-components'
import collections from '../../lib/collections'
import { doc } from 'firebase/firestore'
import VideoPlayer from '../VideoPlayer'
import { ClipLoader } from 'react-spinners'
import TranscodeStatusCatcher from './TranscodeStatusCatcher'

const Container = styled.div`
  display: flex;
  flex: 1;
  border-radius: 15px;
  overflow: hidden;
  background-color: #ccc;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  * {
    text-align: center;
  }
`

export interface VideoPreviewProps {
  videoID?: string
  onDuration?: (duration: number) => void
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ videoID, onDuration }) => {
  if (videoID == null || videoID === '') {
    return <Container><p>No video selected</p></Container>
  }

  return (
    <VideoLoader videoID={videoID} onDuration={onDuration} />
  )
}

const VideoLoader: React.FC<{ videoID: string, onDuration?: (duration: number) => void }> = ({ videoID, onDuration }) => {
  const [video] = useDocumentData(doc(collections.videos, videoID))

  useEffect(() => {
    if (video?.duration == null) return
    if (video.duration === 0) return
    onDuration?.(video.duration)
  }, [video?.duration, onDuration])

  if (video?.processed !== true) {
    return (
      <Container>
        {video != null && <TranscodeStatusCatcher video={video} />}
        <ClipLoader />
        <p>Processing video...</p>
      </Container>
    )
  }

  return (
    <Container>
      <VideoPlayer
        filepath={`videos/${videoID}/hd.mp4`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />

    </Container>
  )
}

export default VideoPreview

import React, { useMemo } from 'react'
import styled from 'styled-components'
import useColorScheme from '../constants/Colors'

const AlternativeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
`

const AlternativeText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
`

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${(props: { color: string }) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    background-color: ${(props: { color: string }) => props.color};
  }
`

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
  transition: 0.1s ease-in-out;

  transform: ${(props: { active: boolean }) => props.active ? 'scale(1)' : 'scale(0)'};
`

interface ItemProps {
  selected?: boolean
  onClick?: () => void
  value: string
  children: React.ReactNode
}

export const Alternative: React.FC<ItemProps> = ({ selected = false, children, onClick }) => {
  const colorScheme = useColorScheme()
  return (
    <AlternativeContainer onClick={onClick}>
      <Circle color={colorScheme.primary}><Dot active={selected} /></Circle>
      <AlternativeText>{children}</AlternativeText>
    </AlternativeContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export interface CheckBoxBase<T> {
  data: T[]
  keyExtractor: (item: T) => string
  renderItem: (item: T) => React.ReactNode
}

export interface CheckBoxPropsSingle<T> extends CheckBoxBase<T> {
  onChange: (value: T) => void
  value: T
}

export interface CheckBoxPropsMultiple<T> extends CheckBoxBase<T> {
  onChange: (value: T[]) => void
  value: T[]
}

export const RadioInput = <ItemT,>(props: CheckBoxPropsSingle<ItemT>): React.ReactElement => {
  const { data, keyExtractor, onChange, renderItem, value } = props

  const selectedKeys = useMemo(() => {
    return [keyExtractor(value)]
  }, [keyExtractor, value])

  const handleChange = (alternative: ItemT): void => {
    onChange(alternative)
  }

  return (
    <Container>
      {data.map((option) => {
        return (
          <Alternative
            key={keyExtractor(option)}
            selected={selectedKeys.includes(keyExtractor(option))}
            onClick={() => handleChange(option)}
            value={keyExtractor(option)}
          >
            {renderItem(option)}
          </Alternative>
        )
      })}
    </Container>
  )
}

export const CheckBoxInput = <ItemT,>(props: CheckBoxPropsMultiple<ItemT>): React.ReactElement => {
  const { data, keyExtractor, onChange, renderItem, value } = props

  const selectedKeys = useMemo(() => {
    return value.map(keyExtractor)
  }, [keyExtractor, value])

  const handleChange = (alternative: ItemT): void => {
    onChange(
      selectedKeys.includes(keyExtractor(alternative))
        ? value.filter((v) => keyExtractor(v) !== keyExtractor(alternative))
        : [...value, alternative])
  }

  return (
    <Container>
      {data.map((option) => {
        return (
          <Alternative
            key={keyExtractor(option)}
            selected={selectedKeys.includes(keyExtractor(option))}
            onClick={() => handleChange(option)}
            value={keyExtractor(option)}
          >
            {renderItem(option)}
          </Alternative>
        )
      })}
    </Container>

  )
}

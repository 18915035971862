import React, { useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import styled from 'styled-components'
import Rearrangeable from './Rearrangeable'
import CardThumbnail from './CardThumbnail'
import { ClipLoader } from 'react-spinners'
import FusePicker from './FusePicker'
import { query, where } from 'firebase/firestore'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`

export interface CourseTemplatePickerProps {
  value: string[]
  onChange: (value: string[]) => void
}

const CourseTemplatePicker: React.FC<CourseTemplatePickerProps> = ({ value, onChange }) => {
  const [courseTemplates, loading] = useCollectionData(query(collections.courseTemplates, where('visible', '==', true)))

  const fuseData = useMemo(() => {
    return courseTemplates?.map((course) => ({
      title: course.title,
      id: course.id
    }))
  }, [courseTemplates])

  return (
    <Container>
      {loading && <ClipLoader />}

      <Grid>
        {!loading && value.length > 0 && (
          <Rearrangeable onRearrange={onChange} keys={value}>
            {value.map((id) => {
              const course = courseTemplates?.find((doc) => doc.id === id)
              if (course == null) return null
              // const color = categories?.find((category) => category.id === session.categoryID)?.color
              return (
                <div key={id}>
                  <CardThumbnail
                    onRemove={() => {
                      onChange(value.filter((sessionID) => sessionID !== id))
                    }}
                    backgroundColor={course.color}
                  >
                    <p>
                      {course?.title}
                    </p>
                  </CardThumbnail>
                </div>
              )
            })}
          </Rearrangeable>
        )}
      </Grid>
      <FusePicker
        data={fuseData}
        onChange={onChange}
        value={value}
        placeholder='Search sessions'
      />
    </Container>
  )
}

export default CourseTemplatePicker

import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import React, { useEffect, useState } from 'react'
import collections from '../lib/collections'
import { onSnapshot } from 'firebase/firestore'
import { CheckCircleFilled, UploadOutlined } from '@ant-design/icons'
import { ClipLoader } from 'react-spinners'

const storage = getStorage()

type Status = 'waiting' | 'uploading' | 'loading-metadata' | 'complete'

interface useUploadVideoReturn {
  status: Status
  progress: number | null
}

export const useUploadVideo = (file: File | null, onVideoID: (val: string) => void): useUploadVideoReturn => {
  const [progress, setProgress] = useState<number | null>(null)
  const [status, setStatus] = useState<Status>('waiting')

  const fileName = file?.name

  useEffect(() => {
    // add a listener to the database to update the videosHasUpdated state
    const collection = collections.videos
    const unsubscribe = onSnapshot(collection, (change) => {
      if (status !== 'loading-metadata') return
      if (change.docs.length === 0) return
      console.log(change.docs.map(d => d.data()), 'change docs')
      const videoDoc = change.docs.find((doc) => doc.data().name === fileName)
      if (videoDoc == null) return
      setStatus('complete')
      onVideoID(videoDoc.data().id)
    })
    return () => unsubscribe()
  }, [fileName, status, onVideoID])

  const getDimensions = async (file: File): Promise<{ width: number, height: number }> => {
    return await new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        const { videoWidth: width, videoHeight: height } = video
        resolve({ width, height })
      }
      video.onerror = reject
      video.src = URL.createObjectURL(file)
    })
  }

  const uploadFile = (file: File, metadata: { width: number, height: number }): void => {
    const storageRef = ref(storage, `upload/${file.name}`)

    const task = uploadBytesResumable(storageRef, file, {
      customMetadata: {
        width: metadata.width.toString(),
        height: metadata.height.toString()
      }
    })

    task.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
      },
      (error) => {
        console.log(error)
      },
      () => {
        setProgress(null)
        setStatus('loading-metadata')
      }
    )
  }

  useEffect(() => {
    if (file == null) return
    setStatus('uploading')
    getDimensions(file).then((dim) => {
      uploadFile(file, dim)
    }).catch((err) => {
      console.error(err)
      window.alert('An error occured while uploading the video.')
    })
  }, [file])

  return {
    status,
    progress
  }
}

export interface UploadUIProps extends useUploadVideoReturn {
  onFileSelect: (file: File) => void
}

export const UploadUI: React.FC<UploadUIProps> = ({ status, progress, onFileSelect }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files == null) return
    onFileSelect(event.target.files[0])
  }

  const select = (): void => {
    if (inputRef.current == null) return
    inputRef.current.click()
  }

  switch (status) {
    case 'waiting':
      return (
        <>
          <UploadOutlined style={{ color: '#999' }} onClick={select} />
          <input ref={inputRef} style={{ display: 'none' }} type='file' accept='video/*' onChange={handleChange} />
        </>
      )
    case 'uploading':
      return (
        <p>{Math.round(progress ?? 0)}%</p>
      )
    case 'loading-metadata':
      return (
        <ClipLoader />
      )
    case 'complete':
      return (
        <CheckCircleFilled style={{ color: '#52c41a' }} />
      )
  }
}

import React, { useMemo, useState } from 'react'
import Card from '../layout/Card'
import Title from '../components/Title'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import collections from '../lib/collections'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Avatar from '../components/Avatar'
import { CheckCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { deleteDoc, doc, query, where } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase/firebase'
import Button from '../components/Button'
import { Course } from '../admin-types'
import { format } from 'date-fns'

const Courses: React.FC<{ myCourses?: boolean }> = ({ myCourses = false }) => {
  const [user] = useAuthState(auth)
  const coursesRef = myCourses ? query(collections.courses, where('authoredBy', '==', user?.uid ?? '')) : collections.courses
  const [courses, loadingCourses, errorCourses] = useCollectionData(coursesRef)
  const [loadingDelete, setLoadingDelete] = useState<string | null>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([{
    field: 'createdAt',
    sort: 'desc'
  }])

  const deleteCourse = (course: Course): void => {
    setLoadingDelete(course.id)
    deleteDoc(doc(collections.courses, course.id)).catch((error) => {
      console.error('Error deleting document: ', error)
      alert(error)
    }).finally(() => {
      setLoadingDelete(null)
    })
  }

  const columns: GridColDef[] = useMemo(() => [
    { field: 'title', headerName: 'Title', flex: 1 },
    {
      field: 'numberOfTemplates',
      headerName: 'Completion',
      flex: 1,
      renderCell: (params) => {
        const course = params.row as Course
        if (course.numberOfTemplates == null) return null
        return (
          <div>
            {course.numberOfCompletedTemplates} / {course.numberOfTemplates}
          </div>
        )
      }
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 140,
      valueGetter: (params) => {
        const seconds = params.value.seconds
        if (seconds == null) return null
        return seconds * 1000
      },
      renderCell: (params: any) => {
        if (params.value == null) return null
        const date = new Date(params.value)
        return format(date, 'dd / MM / yyyy HH:mm')
      }
    },
    {
      field: 'participants',
      headerName: 'Participants',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.value.map((participant: string) => (
              <Avatar key={participant} userID={participant} />
            ))}
          </>
        )
      }
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      width: 90,
      renderCell: (params) => {
        // const user = users?.find((user) => user.id === params.value)
        return (
          <Avatar userID={params.value} />
        )
      }
    },
    {
      field: 'authoredBy',
      headerName: 'Author',
      width: 90,
      renderCell: (params) => {
        // const user = users?.find((user) => user.id === params.value)
        return (
          <Avatar userID={params.value} />
        )
      }
    },
    {
      field: 'completed',
      headerName: 'Completed',
      width: 70,
      renderCell: (params) => {
        const visibility = params.value
        if (visibility === true) return <CheckCircleOutlined />
        return <></>
      }
    },
    {
      field: 'visible',
      headerName: 'Visibility',
      width: 70,
      renderCell: (params) => {
        const visibility = params.value
        if (visibility === true) return <EyeOutlined />
        return <EyeInvisibleOutlined />
      }
    },
    {
      field: '',
      headerName: 'Delete',
      width: 100,
      renderCell: (params) => {
        const course = params.row as Course
        return (
          <Button
            loading={loadingDelete === course.id} onClick={(e) => {
              e.stopPropagation()
              deleteCourse(course)
            }}
          >Delete
          </Button>
        )
      }
    }
  ], [loadingDelete])

  if (errorCourses != null) {
    return <Card center><div>An error occured, please try again later.</div></Card>
  }

  return (
    <Card style={{ gap: 10 }}>
      <Title>Courses</Title>
      <DataGrid
        autoHeight
        rows={courses ?? []}
        columns={columns}
        sortingOrder={['desc', 'asc']}
        onSortModelChange={setSortModel}
        sortModel={sortModel}
        loading={loadingCourses}
      />
    </Card>
  )
}

export default Courses

import React, { useEffect } from 'react'
import Select from 'react-select'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import OpenAI from 'openai'

const functions = getFunctions()

export interface ModelSelectorProps {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

const ModelSelector: React.FC<ModelSelectorProps> = ({ value, onChange, disabled }) => {
  const [loading, setLoading] = React.useState(true)
  const [models, setModels] = React.useState<OpenAI.Model[]>([])

  console.log(value)
  console.log(models, 'models')
  const getModels = async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const models = await httpsCallable(functions, 'getAiModels')()
    setModels(models.data as OpenAI.Model[])
    setLoading(false)
  }

  useEffect(() => {
    getModels().catch(e => {
      console.error(e)
      alert(e.message)
      setLoading(false)
    })
  }, [])

  const options: Array<{ label: string, value: string }> = models.map(model => {
    return { value: model.id, label: model.id }
  })
  const selected = { value, label: value }

  return (
    <Select
      isDisabled={disabled}
      isLoading={loading}
      options={
        options
      }
      onChange={(e) => {
        const val = e
        if (val == null) return
        onChange(val.value)
      }}
      value={selected}
    />
  )
}

export default ModelSelector

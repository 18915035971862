import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'
import { Color } from 'together-types'
import Label from './Label'

const ColorIndicator = styled.div`
  width: 60px;
  height: 26px;
  border-radius: 6px;
  background-color: ${({ color }) => color};
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  border: 1px solid #ccc;
`

export interface ColorInputProps {
  value: string
  onChange: (color: Color) => void
  label?: string
}

const ColorInput: React.FC<ColorInputProps> = ({ value, onChange, label = 'Color' }) => {
  const [showColorPicker, setShowColorPicker] = useState(false)

  useEffect(() => {
    if (!showColorPicker) return
    const listener = (): void => {
      setShowColorPicker(false)
    }
    window.addEventListener('click', listener)

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [showColorPicker])

  return (
    <>
      <Label>{label}</Label>
      <ColorIndicator
        color={value} onClick={(e) => {
          e.stopPropagation()
          setShowColorPicker(true)
        }}
      >
        {showColorPicker && <ChromePicker
          color={value}
          onChange={c => onChange(c.hex as Color)}
                            />}
      </ColorIndicator>
    </>
  )
}

export default ColorInput

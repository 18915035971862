import React, { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Input = styled.input`
  width: 50px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-align: center;
  color: #999;
  background-color: transparent;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  -moz-appearance: textfield;
`

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #999;
  margin: 0;
`

export interface TimeInputProps {
  value: number
  onChange: (value: number) => void
  locked?: boolean
}

const pad = (n: number): string => n.toString().padStart(2, '0')

const TimeInput: React.FC<TimeInputProps> = ({ value, onChange, locked }) => {
  const [input, setInput] = React.useState('')

  useEffect(() => {
    setInput(toString(value))
  }, [value])

  const parse = (str: string): number => {
    let [min, sec] = str.split(':')
    if (min == null || min === '') min = '0'
    if (sec == null || sec === '') sec = '0'
    return parseInt(min) * 60 + parseInt(sec)
  }

  const toString = (value: number): string => {
    const min = Math.floor(value / 60)
    const sec = value % 60
    return `${pad(min)}:${pad(sec)}`
  }

  const update = (): void => {
    if (input === '') return
    const newValue = parse(input)
    setInput(toString(newValue))
    onChange(newValue)
  }

  const onInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let newValue = e.target.value
    newValue = newValue.replace(/[^0-9:]/g, '')

    if (newValue.length === 3 && !newValue.includes(':')) {
      newValue = `${newValue.slice(0, 2)}:${newValue.slice(2)}`
    }

    setInput(newValue)
  }

  return (
    <Container>
      <Input
        placeholder='00:00'
        value={input}
        onChange={onInput}
        onBlur={update}
        disabled={locked}
      />
      <Subtitle>mm:ss</Subtitle>
    </Container>
  )
}

export default TimeInput

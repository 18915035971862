import OpenAI from 'openai'

const addReplySuggestionsFunctionDescription: OpenAI.FunctionDefinition = {
  name: 'addReplySuggestions',
  description: 'Adds reply suggestions to the message. These suggestions will be displayed to the user as quick replies.',
  parameters: {
    type: 'object',
    properties: {
      messageID: {
        type: 'string',
        description: 'The message ID to add the reply suggestions to.'
      },
      suggestions: {
        type: 'array',
        description: 'The suggestions to add.',
        items: {
          type: 'string',
          description: 'A suggestion.'
        }
      }
    },
    required: ['message', 'suggestions']
  }
}

const createSessionFunctionDescription: OpenAI.FunctionDefinition = {
  name: 'createSession',
  description: 'Creates a session.',
  parameters: {
    type: 'object',
    properties: {
      additionalInfo: {
        type: 'array',
        description: "Additional info about the session. It consist of a list of titles and descriptions. Example 'Who is this for?: This session is for everyone that likes trains.'",
        items: {
          type: 'object',
          properties: {
            title: {
              type: 'string',
              description: 'The title of the additional info.'
            },
            description: {
              type: 'string',
              description: 'The description of the additional info.'
            }
          }
        }
      },
      agenda: {
        type: 'array',
        description: 'The agenda of the session. It consist of a list of events. Each event will have a prompt and be displayed one after the other.',
        items: {
          type: 'object',
          properties: {
            cardTypeID: {
              type: 'string',
              description: 'The ID of the card type to use for the event. An example ID looks like this: `0cca67d4-92e0-49ea-ac88-7135179b2640`'
            },
            description: {
              type: 'string',
              description: 'The description of the event. This will be displayed to the user when the event is active.'
            },
            durationSeconds: {
              type: 'number',
              description: 'The duration of the event in seconds.'
            },
            durationType: {
              type: 'string',
              description: 'The duration type of the event. Can be `confirm` or `duration`.',
              enum: ['confirm', 'duration']
            },
            format: {
              type: 'string',
              description: 'The format of the event. Can be `one_at_the_time` or `all_at_once`.',
              enum: ['one_at_the_time', 'all_at_once']
            },
            title: {
              type: 'string',
              description: 'The title of the event. This will be displayed to the user when the event is active.'
            }
          }
        }
      },
      categoryID: {
        type: 'string',
        description: 'The ID of the category to create a session for. An example ID looks like this: `7905848e-7c24-43a7-a7ac-bdd9ca37297f`'
      },
      description: {
        type: 'string',
        description: 'The description of the session.'
      },
      intro: {
        type: 'array',
        items: {
          type: 'string',
          description: 'A intro message'
        },
        description: 'The intro of the session. It consist of a list of messages that will be displayed one after the other.'
      },
      maxNumberOfParticipants: {
        type: 'number',
        description: 'The max number of participants of the session.'
      },
      subtitle: {
        type: 'string',
        description: 'The subtitle of the session.'
      },
      title: {
        type: 'string',
        description: 'The title of the session.'
      }
    },
    required: ['additionalInfo', 'categoryID', 'description', 'intro', 'maxNumberOfParticipants', 'subtitle', 'title']
  }
}

export const aiFunctionDescriptions: OpenAI.FunctionDefinition[] = [
  addReplySuggestionsFunctionDescription,
  createSessionFunctionDescription
]

import React from 'react'
import styled from 'styled-components'
import useColorScheme, { ColorScheme } from '../constants/Colors'
import { useNavigate } from 'react-router-dom'

const StyledHeaderSpacer = styled.div`
  height: 1px;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.disabled};
  width: 80%;
  align-self: center;
  margin: 20px 0;
`

export const HeaderSpacer: React.FC = () => {
  const colorScheme = useColorScheme()
  return <StyledHeaderSpacer colorScheme={colorScheme} />
}

const Button = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
  color: ${props => props.active ? props.colorScheme.onPrimaryContainer : props.colorScheme.onBackgroundMuted};
  background-color: ${props => props.active ? props.colorScheme.primaryContainer : 'transparent'};

  svg {
    margin-right: 10px;
  }

  :hover {
    background-color: ${(props: { colorScheme: ColorScheme, active: boolean }) => props.colorScheme.primaryContainer};
    color: ${props => props.colorScheme.onPrimaryContainer};
  }
`

export interface HeaderButtonProps {
  children?: React.ReactNode
  active?: boolean
  url?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const HeaderButton: React.FC<HeaderButtonProps> = ({ children, url, active = false, onClick }) => {
  const navigate = useNavigate()
  const colorScheme = useColorScheme()

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (url == null && onClick == null) return
    if (url != null) navigate(url)
    if (onClick != null) onClick(e)
  }

  return (
    <Button onClick={handleClick} colorScheme={colorScheme} active={active}>
      {children}
    </Button>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props: { colorScheme: ColorScheme }) => props.colorScheme.background};
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

export interface HeaderProps {
  children?: React.ReactNode
  logo?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({ children, logo }) => {
  const colorScheme = useColorScheme()
  return (
    <Container colorScheme={colorScheme}>
      <LogoContainer>
        {logo}
      </LogoContainer>
      {children}
    </Container>
  )
}

export default Header

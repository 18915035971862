import React from 'react'
import CourseSelect from '../CourseSelect'
import Label from '../Label'
import { VStack } from 'react-stacked'
import Button from '../Button'
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import collections, { courseSessionTemplates } from '../../lib/collections'
import { v4 as uuid } from 'uuid'
import { Session } from '../../admin-types'
import { useNavigate } from 'react-router-dom'

export interface SessionToCourseSessionProps {
  sessionTemplateID: string
}

const SessionToCourseSession: React.FC<SessionToCourseSessionProps> = ({ sessionTemplateID }) => {
  const [selectedCourse, setSelectedCourse] = React.useState('')
  const navigate = useNavigate()

  const onMigrate = (): void => {
    const sure = window.confirm('Are you sure you want to migrate this session to a course session?')
    if (sure) {
      migrate().catch(e => {
        console.error(e)
        alert(e)
      })
    }
  }

  const migrate = async (): Promise<void> => {
    const sessionTemplateDoc = await getDoc(doc(collections.sessionTemplates, sessionTemplateID))
    if (!sessionTemplateDoc.exists()) {
      throw new Error('Session template does not exist')
    }
    const sessionTemplate = sessionTemplateDoc.data()
    if (sessionTemplate == null) {
      throw new Error('Session template is null')
    }
    const newID = uuid()
    const courseSessionTemplate: Session = {
      ...sessionTemplate,
      id: newID,
      templateID: newID,
      courseID: selectedCourse
    }
    console.log(selectedCourse)
    const ref = doc(courseSessionTemplates(selectedCourse), newID)
    await setDoc(ref, courseSessionTemplate)
    await deleteDoc(doc(collections.sessionTemplates, sessionTemplateID))
    navigate(`/course-templates/${selectedCourse}/template/${newID}`)
  }

  return (
    <VStack gap={10}>
      <Label>Migrate to course</Label>
      <CourseSelect value={selectedCourse} onChange={(c) => setSelectedCourse(c.id)} />
      <Button
        disabled={selectedCourse === ''}
        onClick={onMigrate}
      >
        Migrate to course session
      </Button>
    </VStack>
  )
}

export default SessionToCourseSession

import React from 'react'
import styled from 'styled-components'
import Input from './Input'
import { ClipLoader } from 'react-spinners'
import Fuse from 'fuse.js'
import { CheckCircleFilled } from '@ant-design/icons'
import useColorScheme from '../constants/Colors'

const fuseOptions = {
  keys: ['title']
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`

const Item = styled.div<{ primary: string }>`
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  transition: 0.1s ease-in-out;
  cursor: pointer;

  p {
    margin: 0;
  }

  :hover {
    background-color: ${props => props.primary};
  }
`

export interface FusePickerProps {
  data?: Array<{
    title: string
    id: string
  }>
  value?: string[]
  onChange: (val: string[]) => void
  placeholder?: string
}

const FusePicker: React.FC<FusePickerProps> = ({ data, value, onChange, placeholder = '' }) => {
  const colorScheme = useColorScheme()
  const fuse = new Fuse(data ?? [], fuseOptions)
  const [search, setSearch] = React.useState('')
  const filteredResults = search === '' ? data : fuse.search(search).map(result => result.item)

  return (
    <Container>
      <Input placeholder={placeholder} value={search} onChange={setSearch} />
      {data == null && <ClipLoader />}

      {search !== '' && filteredResults?.slice(0, 5).map((item) => (
        <Item
          key={item.id} onClick={() => {
            if (value?.includes(item.id) === true) {
              onChange(value.filter(p => p !== item.id))
            } else {
              onChange([...(value ?? []), item.id])
            }
          }} primary={colorScheme.surfaceVariant}
        >
          {value?.includes(item.id) === true && <CheckCircleFilled style={{ color: colorScheme.primary }} />}
          <p>{item.title}</p>

        </Item>
      ))}

    </Container>
  )
}

export default FusePicker

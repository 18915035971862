import { v4 as uuid } from 'uuid'
import { CreateInput, Session } from '../admin-types'
import { Timestamp } from 'firebase/firestore'

export const calculateSessionDuration = (session: Session): number => {
  return session.agenda.map(a => a.durationSeconds).reduce((a, b) => a + b, 0) * session.maxNumberOfParticipants
}

export const createEmptySession = (userID: string, authoredBy: string | undefined, categoryID: string, isTemplate: boolean): CreateInput<Session> => {
  const id = uuid()
  const session: CreateInput<Session> = {
    acceptedBy: [],
    agenda: [],
    archived: false,
    authoredBy,
    categoryID,
    createdAt: Timestamp.now(),
    createdBy: userID,
    currentEvent: 'waiting',
    description: '',
    id,
    intro: [],
    location: '',
    maxNumberOfParticipants: 2,
    participants: [],
    rejectedBy: [],
    startsAt: new Date().toISOString(),
    subtitle: '',
    templateID: isTemplate ? id : '',
    title: '',
    visible: false
  }

  return session
}

import React from 'react'
import styled from 'styled-components'
import { CloseCircleOutlined } from '@ant-design/icons'

export const Thumbnail = styled.div<{ backgroundColor?: string }>`
  display: flex;
  width: 100px;
  height: 200px;
  border-radius: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor ?? 'white'};
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
`

const Button = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`

export interface SessionThumbnailProps {
  children: React.ReactNode
  onRemove?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  style?: React.CSSProperties
  backgroundColor?: string
}

const CardThumbnail: React.FC<SessionThumbnailProps> = ({ onRemove, children, style, backgroundColor }) => {
  return (
    <Thumbnail backgroundColor={backgroundColor} style={style}>
      {children}
      {onRemove != null && (
        <Button>
          <CloseCircleOutlined
            style={{ color: '#000' }} onClick={e => {
              if (onRemove != null) onRemove(e)
            }}
          />
        </Button>
      )}
    </Thumbnail>
  )
}

export default CardThumbnail

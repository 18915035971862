import React, { useEffect } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useParams } from 'react-router-dom'
import collections from '../lib/collections'
import { doc } from 'firebase/firestore'
import Card from '../layout/Card'
import Loading from './Loading'
import Title, { SubTitle } from '../components/Title'
import Label from '../components/Label'
import CountDown from '../components/CountDown'
import Avatar from '../components/Avatar'
import { UserRecord } from '../admin-types'
import { EventWithSpeaker } from 'together-types'

const UserLoader: React.FC<{ event: EventWithSpeaker, onEvent: (user: UserRecord | null) => void }> = ({ event, onEvent }) => {
  const [user] = useDocumentData(doc(collections.users, event?.speaker))

  useEffect(() => {
    if (event.format === 'all_at_once') {
      onEvent(null)
    } else {
      if (user != null) {
        onEvent(user)
      }
    }
  }, [user, event.format, onEvent])

  return (
    <></>
  )
}

const Session: React.FC = () => {
  const { id } = useParams()
  const [session, loadingSession, errorSession] = useDocumentData(doc(collections.sessions, id))
  const [currentEventUser, setCurrentEventUser] = React.useState<UserRecord | null>(null)

  if (errorSession != null) {
    alert('Error loading session')
  }

  if (loadingSession || session == null) {
    return (
      <Card>
        <Loading />
      </Card>
    )
  }

  const currentEventID = session.currentEvent === 'finished' || session.currentEvent === 'waiting'
    ? null
    : session?.currentEvent.id

  const currentEventData = session.events?.find(e => e.id === currentEventID)

  return (
    <Card>
      <Title>{session.title}</Title>
      <SubTitle>{session.description}</SubTitle>

      <Label>Current event</Label>
      {currentEventData != null && <UserLoader event={currentEventData} onEvent={setCurrentEventUser} />}
      {currentEventData != null && currentEventUser != null && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Avatar user={currentEventUser} />
          <p>{currentEventUser.firstName} {currentEventUser.lastName}</p>
        </div>
      )}
      <pre>{JSON.stringify(session.currentEvent, null, 2)}</pre>

      <Label>RAW</Label>
      <pre>{JSON.stringify(session, null, 2)}</pre>

      {typeof session.currentEvent === 'object' && typeof currentEventData === 'object' && (
        <CountDown start={session.currentEvent.startedAt.seconds * 1000} duration={currentEventData?.durationSeconds} />
      )}
    </Card>
  )
}

export default Session

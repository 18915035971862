import React from 'react'
import Button from './Button'
import { DocumentReference, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import collections from '../lib/collections'
import { v4 as uuid } from 'uuid'
import { Session } from '../admin-types'
import { removeUndefinedKeys } from '../utils/removeUndefinedKeys'
import { useNavigate } from 'react-router-dom'

export interface DuplicateCourseTemplateButtonProps {
  courseTemplateID: string
  sessionTemplateID: string
}

const DuplicateCourseTemplateButton: React.FC<DuplicateCourseTemplateButtonProps> = ({ courseTemplateID, sessionTemplateID }) => {
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  const onMigrate = (): void => {
    const sure = window.confirm('Are you sure you want to migrate this session to a non-course template?')
    if (sure) {
      migrate().catch(e => {
        console.error(e)
        alert(e)
      })
    }
  }

  const migrate = async (): Promise<void> => {
    setLoading(true)
    const courseSessionRef = doc(collections.courseTemplates, courseTemplateID, 'templates', sessionTemplateID) as DocumentReference<Session>
    const newID = uuid()
    const newRef = doc(collections.sessionTemplates, newID)

    const courseSessionDoc = await getDoc(courseSessionRef)
    if (!courseSessionDoc.exists()) throw new Error('Session template does not exist')
    const courseSession = courseSessionDoc.data()
    courseSession.id = newID
    courseSession.templateID = newID
    courseSession.courseID = undefined
    await setDoc(newRef, removeUndefinedKeys(courseSession))
    await deleteDoc(courseSessionRef)
    setLoading(false)
    navigate(`/session-templates/${newID}`)
  }

  return (
    <Button onClick={onMigrate} loading={loading}>Migrate to regular session</Button>
  )
}

export default DuplicateCourseTemplateButton

import Card from '../layout/Card'
import { SubTitle } from '../components/Title'
import Button from '../components/Button'
import { ArrowDownOutlined, ArrowUpOutlined, BookOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons'
import { DiscoverCategory } from 'together-types'
import { v4 as uuid } from 'uuid'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import collections from '../lib/collections'
import { doc, orderBy, query, setDoc, updateDoc } from 'firebase/firestore'
import Loading from './Loading'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { HStack } from 'react-stacked'

const createNewDiscoverCategory = (priority: number): DiscoverCategory => {
  return {
    contentType: 'sessions',
    id: uuid(),
    priority,
    sessions: [],
    thumbnailType: 'default',
    title: '',
    type: 'curated',
    visible: false
  }
}

const Discover: React.FC = () => {
  const [discoverCategories] = useCollectionData(query(collections.discoverCategories, orderBy('priority')))
  const navigate = useNavigate()

  if (discoverCategories == null) {
    return (
      <Card>
        <Loading />
      </Card>
    )
  }

  const addNewDiscoverCategory = (): void => {
    const newDiscoverCategory = createNewDiscoverCategory(discoverCategories.length)
    setDoc(doc(collections.discoverCategories, newDiscoverCategory.id), newDiscoverCategory).catch((error) => {
      console.error('Error writing document: ', error)
    })
  }

  const setReorderedPosts = (order: string[]): void => {
    order.forEach((item, index) => {
      updateDoc(doc(collections.discoverCategories, item), {
        priority: index
      }).catch((error) => {
        console.error('Error writing document: ', error)
      })
    })
  }

  const handleOrderButtonPress = (id: string, action: 'increase' | 'decrease'): void => {
    const currentOrder = discoverCategories.map((category) => category.id)
    const index = currentOrder.findIndex((orderID) => orderID === id)
    const newOrder = [...currentOrder]
    switch (action) {
      case 'increase':
        if (index === 0) return
        // swap with previous
        newOrder[index] = currentOrder[index - 1]
        newOrder[index - 1] = currentOrder[index]
        setReorderedPosts(newOrder)
        break
      case 'decrease':
        if (index === currentOrder.length - 1) return
        // swap with next
        newOrder[index] = currentOrder[index + 1]
        newOrder[index + 1] = currentOrder[index]
        setReorderedPosts(newOrder)
        break
    }
  }

  const columns: GridColumns<DiscoverCategory> = [
    { field: 'title', headerName: 'Title', flex: 1 },
    {
      field: 'sessions',
      headerName: 'Items',
      width: 80,
      renderCell: (params) => {
        const category = params.row

        switch (category.contentType) {
          case 'sessions':
            return category.sessions?.length ?? 0
          case 'profiles':
            return category.profiles?.length ?? 0
          case 'courses':
            return category.courses?.length ?? 0
          default:
            return '-'
        }
      }
    },

    {
      field: 'contentType',
      headerName: 'Content Type',
      width: 100,
      renderCell: (params) => {
        const contentType = params.value
        if (contentType === 'sessions') return <BookOutlined />
        return <UserOutlined />
      }
    },
    {
      field: 'visible',
      headerName: 'Visibility',
      width: 70,
      renderCell: (params) => {
        const visibility = params.value
        if (visibility === true) return <EyeOutlined />
        return <EyeInvisibleOutlined />
      }
    },
    {
      field: 'order',
      headerName: 'Order',
      width: 140,
      renderCell: ({ row }) => {
        const index = discoverCategories.findIndex((orderID) => orderID.id === row.id)
        return (
          <HStack gap={10}>
            <Button
              disabled={index === 0}
              onClick={(e) => {
                e.stopPropagation()
                handleOrderButtonPress(row.id, 'increase')
              }}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              disabled={index === discoverCategories.length - 1}
              onClick={(e) => {
                e.stopPropagation()
                handleOrderButtonPress(row.id, 'decrease')
              }}
            >
              <ArrowDownOutlined />
            </Button>
          </HStack>
        )
      }
    },
    {
      field: '',
      headerName: 'Edit',
      width: 90,
      renderCell: (params) => {
        const discoverCategory = params.row
        return (
          <Button onClick={() => navigate(`/discover/${discoverCategory.id}`)}>Edit</Button>
        )
      }
    }
    // { field: 'priority', headerName: 'Priority', width: 200 }
  ]

  return (
    <Card style={{ gap: 10 }}>
      <HStack>
        <SubTitle style={{ display: 'flex', flex: 1, alignItems: 'center' }}>Discover</SubTitle>
        <Button onClick={addNewDiscoverCategory}><PlusOutlined /></Button>
      </HStack>

      <DataGrid
        rows={discoverCategories}
        columns={columns}
        autoHeight
      />
    </Card>
  )
}

export default Discover

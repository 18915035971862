import Header, { HeaderButton, HeaderSpacer } from './layout/Header'
import Workspace from './layout/Workspace'
import {
  Routes,
  Route,
  useLocation,
  Link
} from 'react-router-dom'
import Sessions from './pages/Sessions'
import Session from './pages/Session'
import Users from './pages/Users'
import SessionsTemplates from './pages/SessionTemplates'
import styled from 'styled-components'
import { LogoutOutlined, TeamOutlined, VideoCameraOutlined, AppstoreOutlined, NotificationOutlined, FireOutlined, RiseOutlined, CameraOutlined, IdcardOutlined, ContainerOutlined, BookOutlined, DeploymentUnitOutlined, RobotOutlined, FolderFilled } from '@ant-design/icons'
import Logo from './components/Logo'
import { auth } from './firebase/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import Login from './pages/Login'
import { signOut } from 'firebase/auth'
import useIsAdmin from './hooks/useIsAdmin'
import Loading from './pages/Loading'
import Categories from './pages/Categories'
import Videos from './pages/Videos'
import Notifications from './pages/Notifications'
import Discover from './pages/Discover'
import SessionArchive from './pages/SessionArchive'
import Statistics from './pages/Statistics'
import Images from './pages/Images'
import User from './pages/User'
import CardTypes from './pages/CardTypes'
import { ContextMenuProvider } from './components/context-menu'
import SessionTemplate from './pages/SessionTemplate'
import Courses from './pages/Courses'
import CourseTemplate from './pages/CourseTemplate'
import useUser from './hooks/useUser'
import CourseTemplates from './pages/CourseTemplates'
import DiscoverEntry from './pages/DiscoverEntry'
import AiDashboard from './pages/AiDashboard'
import AiThread from './pages/AiThread'
import AiAssistant from './pages/AiAssistant'
import AiFiles from './pages/AiFiles'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 100vh;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const App: React.FC = () => {
  const location = useLocation()
  const [authUser, loading] = useAuthState(auth)
  const isAdmin = useIsAdmin()
  const { user, loading: loadingUser } = useUser()

  if (loading) {
    return <Loading />
  }

  if (authUser == null) {
    return <Login />
  }

  if (loadingUser) {
    return <Loading />
  }

  if (user?.role === 'creator' && !isAdmin) {
    return (
      <ContextMenuProvider>
        <Row>
          <Header logo={<Logo width={100} />}>
            <HeaderButton url='/session-templates' active={location.pathname.includes('session-templates') || location.pathname === '/'}><BookOutlined />My sessions templates</HeaderButton>
            <HeaderButton url='/course-templates' active={location.pathname.includes('course-templates')}><DeploymentUnitOutlined />My courses templates</HeaderButton>

            <HeaderSpacer />
            <HeaderButton onClick={(): void => { signOut(auth).catch(alert) }}><LogoutOutlined />Sign out
            </HeaderButton>
          </Header>
          <Workspace>
            <Routes>
              <Route path='/' element={<SessionsTemplates myTemplates />} />
              <Route path='/session-templates' element={<SessionsTemplates myTemplates />} />
              <Route path='/session-templates/:id' element={<SessionTemplate />} />
              <Route path='/course-templates' element={<CourseTemplates myCourses />} />
              <Route path='/course-templates/:id' element={<CourseTemplate />} />
              <Route path='/course-templates/:courseID/template/:id' element={<SessionTemplate />} />
              <Route path='/users/:id' element={<User />} />
              <Route path='*' element={<Center><h1>404</h1><p>The specified URL was not found</p></Center>} />
            </Routes>
          </Workspace>
        </Row>
      </ContextMenuProvider>
    )
  }

  if (!isAdmin) {
    return <div>Not authorized. <Link to='/' onClick={(): void => { signOut(auth).catch(alert) }}>Back to login</Link></div>
  }

  return (
    <ContextMenuProvider>
      <Row>
        <Header logo={<Logo width={100} />}>
          <HeaderButton url='/' active={location.pathname === '/'}><RiseOutlined />Statistics</HeaderButton>
          <HeaderButton url='/sessions' active={location.pathname.includes('sessions')}><VideoCameraOutlined />Sessions</HeaderButton>
          <HeaderButton url='/session-archive' active={location.pathname.includes('archive')}><ContainerOutlined />Session archive</HeaderButton>
          <HeaderButton url='/session-templates' active={location.pathname.includes('session-templates')}><BookOutlined />Sessions templates</HeaderButton>
          <HeaderButton url='/courses' active={location.pathname.includes('courses')}><DeploymentUnitOutlined />Courses</HeaderButton>
          <HeaderButton url='/course-templates' active={location.pathname.includes('course-templates')}><DeploymentUnitOutlined />Course Templates</HeaderButton>
          <HeaderButton url='/users' active={location.pathname.includes('users')}><TeamOutlined />Users</HeaderButton>
          <HeaderButton url='/qualities' active={location.pathname.includes('qualities')}><AppstoreOutlined />Qualities</HeaderButton>
          <HeaderButton url='/card-types' active={location.pathname.includes('card-types')}><IdcardOutlined />Card types</HeaderButton>
          <HeaderButton url='/discover' active={location.pathname.includes('discover')}><FireOutlined />Discover</HeaderButton>
          <HeaderButton url='/images' active={location.pathname.includes('images')}><CameraOutlined />Images</HeaderButton>
          <HeaderButton url='/videos' active={location.pathname.includes('videos')}><VideoCameraOutlined />Videos</HeaderButton>
          <HeaderButton url='/notifications' active={location.pathname.includes('notifications')}><NotificationOutlined />Notifications</HeaderButton>
          <HeaderButton url='/ai' active={location.pathname.includes('ai') && location.pathname !== '/ai-files'}><RobotOutlined />AI</HeaderButton>
          <HeaderButton url='/ai-files' active={location.pathname.includes('ai-files')}><FolderFilled />AI files</HeaderButton>
          <HeaderSpacer />
          <HeaderButton onClick={(): void => { signOut(auth).catch(alert) }}><LogoutOutlined />Sign out
          </HeaderButton>
        </Header>
        <Workspace>
          <Routes>
            <Route path='/' element={<Statistics />} />
            <Route path='/sessions' element={<Sessions />} />
            <Route path='/sessions/:id' element={<Session />} />
            <Route path='/session-archive' element={<SessionArchive />} />
            <Route path='/session-templates' element={<SessionsTemplates />} />
            <Route path='/session-templates/:id' element={<SessionTemplate />} />
            <Route path='/courses' element={<Courses />} />
            <Route path='/course-templates' element={<CourseTemplates />} />
            <Route path='/course-templates/:id' element={<CourseTemplate />} />
            <Route path='/course-templates/:courseID/template/:id' element={<SessionTemplate />} />
            <Route path='/users' element={<Users />} />
            <Route path='/users/:id' element={<User />} />
            <Route path='/qualities' element={<Categories />} />
            <Route path='/card-types' element={<CardTypes />} />
            <Route path='/discover' element={<Discover />} />
            <Route path='/discover/:id' element={<DiscoverEntry />} />
            <Route path='/images' element={<Images />} />
            <Route path='/videos' element={<Videos />} />
            <Route path='/notifications' element={<Notifications />} />
            <Route path='/ai' element={<AiDashboard />} />
            <Route path='/ai-files/' element={<AiFiles />} />
            <Route path='/ai-thread/:threadID' element={<AiThread />} />
            <Route path='/ai-assistant/:assistantID' element={<AiAssistant />} />
            <Route path='*' element={<Center><h1>404</h1><p>The specified URL was not found</p></Center>} />
          </Routes>
        </Workspace>
      </Row>
    </ContextMenuProvider>
  )
}

export default App

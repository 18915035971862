import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(0deg, rgb(234, 234, 234) 0%, rgb(248, 248, 248) 74%);
  width: 400px;
  position: relative;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
`

const LogoContainer = styled.div`
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 20%;
  overflow: hidden;
`

const Title = styled.h3`
  font-size: 1rem;
  margin: 0;
`

const Message = styled.p`
  font-size: 0.9rem;
  margin: 0;
`

const Time = styled.p`
  font-size: 0.8rem;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #888;
`

export interface NotificationPreviewProps {
  title: string
  message: string
}

const NotificationPreview: React.FC<NotificationPreviewProps> = ({ title, message }) => {
  return (
    <Container>
      <Row>
        <LogoContainer>
          <Logo width={64} style={{ alignSelf: 'center' }} />
        </LogoContainer>
        <Column>
          <Title>{title}</Title>
          <Message>{message}</Message>
        </Column>
      </Row>
      <Time>Now</Time>
    </Container>
  )
}

export default NotificationPreview

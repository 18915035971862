import React, { useMemo } from 'react'
import { CourseTemplate } from '../admin-types'
import useIsAdmin from '../hooks/useIsAdmin'
import collections from '../lib/collections'
import { query, where } from 'firebase/firestore'
import { auth } from '../firebase/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Select from 'react-select'

export interface CourseSelectProps {
  value: string
  onChange: (course: CourseTemplate) => void
}

const CourseSelect: React.FC<CourseSelectProps> = ({ value, onChange }) => {
  const admin = useIsAdmin()
  const userID = auth.currentUser?.uid ?? ''
  const ref = admin
    ? collections.courseTemplates
    : query(collections.courseTemplates, where('authoredBy', '==', userID))
  // TODO in the future when courses are very large, we will need algolia or something
  const [courses, loading, error] = useCollectionData(ref)

  const options = useMemo(() => {
    const options: Array<{ value: string, label: string }> = courses?.map((course) => ({ value: course.id, label: course.title })) ?? []
    return [{
      value: '',
      label: 'Select a course'
    }, ...options]
  }, [courses])

  if (error != null) {
    return <div>Error loading courses</div>
  }

  return (
    <>
      <Select
        value={options.find((option) => option.value === value)}
        onChange={(option) => {
          if (option == null) {
            return
          }
          onChange(courses?.find((doc) => doc.id === option.value) as CourseTemplate)
        }}
        options={options}
        isLoading={loading}
      />
    </>
  )
}

export default CourseSelect

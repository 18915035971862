import React from 'react'
import styled from 'styled-components'
import { Category as CategoryType } from 'together-types'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CategoryDot = styled.div`
  width: 13px;
  height: 13px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: ${(props: { color: string }) => props.color};
`

export interface CategoryProps {
  category: CategoryType
}

const Category: React.FC<CategoryProps> = ({ category }) => {
  return (
    <Container>
      <CategoryDot color={category.color} />
      <p>{category.title}</p>
    </Container>
  )
}

export default Category

import React, { useEffect } from 'react'
import Select from 'react-select'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import OpenAI from 'openai'

const functions = getFunctions()

export interface FilesSelectorProps {
  value: string[]
  onChange: (value: string[]) => void
  disabled?: boolean
}

const FilesSelector: React.FC<FilesSelectorProps> = ({ value, onChange, disabled }) => {
  const [loading, setLoading] = React.useState(true)
  const [files, setFiles] = React.useState<OpenAI.Files.FileObject[]>([])

  const getFiles = async (): Promise<void> => {
    if (window.location.hostname.includes('localhost')) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
    const files = await httpsCallable(functions, 'getAiFiles')()
    setFiles(files.data as OpenAI.Files.FileObject[])
    setLoading(false)
  }

  useEffect(() => {
    getFiles().catch(e => {
      console.error(e)
      alert(e.message)
      setLoading(false)
    })
  }, [])

  const options: Array<{ label: string, value: string }> = files.map(file => ({ value: file.id, label: file.filename }))
  const selected = options.filter(option => value.includes(option.value))

  return (
    <Select
      isDisabled={disabled}
      isMulti
      isLoading={loading}
      options={
        options
      }
      onChange={(e) => {
        const val = e.map((option) => option.value)
        onChange(val)
      }}
      value={selected}
    />
  )
}

export default FilesSelector
